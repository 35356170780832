<template>
  <div class="AnchorInfoCard">
    <el-skeleton
      class="chatListSkeleton"
      :loading="dataLoading"
      animated
      :count="1"
    >
      <template slot="template">
        <AnchorInfoCardSkeleton />
      </template>
      <template>
        <!-- 开播时长 -->
        <div
          class="liveDuration"
          v-if="
            streamer?.live && streamer?.live.live_duration && isShowLiveDuration
          "
        >
          <div class="information">
            <div class="state">
              <div class="icon"></div>
              <div class="text">LIVE</div>
            </div>
            <div class="duration" v-if="streamer?.startTime">
              <!-- {{ $t("alreadyPrompt") }} {{ streamer?.startTime }} -->
            </div>
          </div>
          <div class="button" @click="toLiveRoom(streamer?.live)">
            <div class="text">{{ $t("watch") }}</div>
            <div class="icon"></div>
          </div>
        </div>

        <!-- 主播信息 -->
        <div class="Anchor" v-if="streamer">
          <div class="top">
            <div class="left" @click="toAnchorIntroduction(streamer?.username)">
              <!-- 头像 -->
              <div
                class="avatar"
                :style="{
                  backgroundImage:
                    'url(' + imageBaseUrl + streamer?.avatar + ')',
                }"
              ></div>
              <div class="AnchorM">
                <!-- 昵称 -->
                <div class="anchorName">{{ streamer?.name }}</div>
                <!-- id/username -->
                <div class="anchorId">ID: {{ streamer?.username }}</div>
              </div>
            </div>
            <div class="subscription">
              <!-- 订阅按钮 -->
              <div
                class="button"
                :class="{ subscribed: streamer?.followed }"
                @click="clickFollowed(streamer?.followed, streamer?.username)"
              >
                <div
                  class="icon"
                  :style="{
                    backgroundImage:
                      'url(' +
                      (streamer?.followed
                        ? followedIcon.loveRedIcon
                        : followedIcon.loveWhiteIcon) +
                      ')',
                  }"
                ></div>
                <div class="text">
                  {{ streamer?.followed ? $t("following") : $t("follow") }}
                </div>
              </div>

              <!-- 订阅人数 -->
              <div class="followerNumber">
                {{ $t("follow") }}: {{ streamer?.follower_count }}
              </div>
            </div>
          </div>
        </div>

        <!-- 直播赛程 -->
        <div
          class="anchorMatchStatus"
          v-if="streamer?.schedules && streamer?.schedules.length !== 0"
        >
          <div class="Mtitle">{{ $t("streamerSchedule") }}</div>
          <div
            class="competitionsList"
            ref="competitionsList"
            v-if="streamer?.schedules?.length !== 0"
          >
            <MatchCardList :competitions="streamer?.schedules"></MatchCardList>
          </div>
          <NoData v-if="streamer?.schedules?.length === 0 && !dataLoading">{{
            $t("noDataPrompt")
          }}</NoData>
        </div>

        <div class="noDataWrapper" v-show="!streamer">
          <NoData v-show="!dataLoading"></NoData>
          <WaitToastArea v-show="dataLoading"></WaitToastArea>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus";
// import { mapActions } from "vuex";
import { followedIcon } from "@/utils/commonIcons";
import { anchorQuery } from "@/services/api/Live";
import { followStreamer, unFollowStreamer } from "@/services/api/Home";
import MatchCardList from "@/components/MatchCardList/Index.vue";
import WaitToastArea from "@/components/WaitToastArea/Index";
import NoData from "@/components/NoData/Index.vue";
import AnchorInfoCardSkeleton from "@/components/AnchorInfoCard/AnchorInfoCardSkeleton.vue";
import toast from "@/utils/toast.js";
import { checkClientTokenRole } from "@/utils/authCookie";
export default {
  name: "AnchorInfoCard",
  props: {
    // 主播用户名 用于查询主播信息
    streamerUsername: {
      type: String,
      require: true,
    },

    // 是否展示直播时长栏 默认展示
    isShowLiveDuration: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MatchCardList,
    NoData,
    WaitToastArea,
    AnchorInfoCardSkeleton,
  },
  data() {
    return {
      streamer: null,
      watiToast: null, // 加载taost实例
      dataLoading: true, //toast是否在加载状态
      imageBaseUrl: "", // 图片基础路径
    };
  },
  computed: {
    /**
     * 定义引入的subscribedIcon
     * @return {Object} 订阅状态图标
     */
    followedIcon() {
      return followedIcon;
    },
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
  },
  async mounted() {
    this.streamerUsername && (await this.loadStreamerDetail());
    // this.watchWsStreamerStatus();
  },
  watch: {
    streamerUsername: {
      handler: async function (newVal) {
        if (newVal) {
          await this.loadStreamerDetail();
        }
      },
    },
  },
  beforeDestroy() {
    // this.removeWatchWs();
  },
  methods: {
    // ...mapActions("globalWS", [
    //   "handleGlobalMessge",
    //   "removeHandleGlobalMessge",
    // ]),

    /**
     * 加载主播详细信息包括主播赛程
     */
    async loadStreamerDetail() {
      try {
        // this.initWaitToast();
        this.dataLoading = true;
        // this.watiToast = toast.initWaitToast();

        const res = await anchorQuery({
          streamer_username: this.streamerUsername,
          country_id: localStorage.getItem("country_id"),
          language_id: localStorage.getItem("language_id"),
        });
        if (res?.statusCode === 200) {
          // console.log("获取主播详细信息包括主播赛程", res.data);

          if (res.data.live && res.data.live.live_duration) {
            this.streamer = {
              ...res.data,
              startTime: this.getFormatDuration(res.data.live.live_duration),
            };
          } else {
            this.streamer = res.data;
          }
          this.schedules = this.streamer?.schedules;
          // this.clearWaitToast();
          // toast.clearWaitToast(this.watiToast);
          this.dataLoading = false;
        } else {
          console.error(res);
          // toast.clearWaitToast(this.watiToast);
          this.dataLoading = false;
        }
      } catch (error) {
        // toast.clearWaitToast(this.watiToast);
        this.dataLoading = false;
        console.error(error);
      }
    },

    /**
     * 点击了主播的关注状态
     * @param { boolean } followed 是否已关注
     * @param { string } username 主播用户名
     */
    clickFollowed(followed, username) {
      //   console.log("点击了主播的关注状态", followed, username);
      if (followed) {
        // 原状态已关注 操作是取消关注 弹框再次确认
        console.log(123)
        this.$dialog
          .confirm({
            title: this.$t("unfollowPrompt"),
            theme: "round-button",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            confirmButtonColor: "#2A283D",
            cancelButtonColor: "#6418C3",
            className: "confirmLeft",
            closeOnClickOverlay: true,
            getContainer: "#app",
          })
          .then(() => {
            // on confirm
            // console.log("确定");
            EventBus.$emit('changeFollowed',1);
            this.changeFollowed(followed, username);
          })
          .catch(() => {
            // on cancel
            // console.log("取消");
          });
      } else {
        EventBus.$emit('changeFollowed',2);
        // 原状态未关注 操作是进行关注 直接调用接口
        this.changeFollowed(followed, username);
      }
      return;
    },

    /**
     * 更改服务端该主播的关注状态
     * @param { boolean } followed 是否已关注
     * @param { string } username 主播用户名
     */
    async changeFollowed(followed, username) {
      if (checkClientTokenRole() === "visitor") {
        this.$dialog
          .confirm({
            title: this.$t("logAndSign"),
            theme: "round-button",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            confirmButtonColor: "#2A283D", // 灰色
            cancelButtonColor: "#6418C3", // 紫色
            closeOnClickOverlay: true,
            getContainer: ".AnchorInfoCard",
          })
          .then(() => {
            sessionStorage.setItem("preLoginRoute", this.$route.fullPath);
            // on confirm
            this.$router.push("/LogInRegister");
          })
          .catch(() => {
            // on cancel
            // console.log("取消");
          });
        return;
      }

      try {
        // 展示加载toast
        // this.initWaitToast();
        this.dataLoading = true;
        this.watiToast = toast.initWaitToast();

        // 原状态已关注 操作是取消关注
        if (followed) {
          // 发起请求 更改云端数据
          const res = await unFollowStreamer(username);
          //   console.log("取消关注是否成功", res.statusCode);
          this.clearWaitToast(); // 清除加载toast
          // toast.clearWaitToast(this.watiToast)

          if (res.statusCode === 204) {
            // 响应成功的处理逻辑
            // ...
            this.loadStreamerDetail();
          } else {
            // 接口发生错误
            // this.$vantToast({
            //   type: "fail",
            //   // FIXME:网络错误注释
            //   // message: this.$t("InternetErrorPrompt"),
            // });
            console.log("fail:网络错误！");
          }
        }

        // 原状态未关注 操作是进行关注
        if (!followed) {
          // 发起请求 更改云端数据
          const res = await followStreamer(username);
          //   console.log("关注是否成功", res.statusCode);
          this.clearWaitToast(); // 清除加载toast
          if (res.statusCode === 204) {
            // 响应成功的处理逻辑
            // ...
            // TODO: 不重新加载 直接更改 体验更好
            this.loadStreamerDetail();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 跳转路由 直播间
     */
    toLiveRoom(live) {
      this.$router.push({
        name: "LiveRoom",
        query: {
          liveId: live.live_id,
          competitionId: live.competition_id,
        },
      });
    },

    /**
     * 跳转路由 主播详情页面
     */
    toAnchorIntroduction(username) {
      this.$router.push({
        name: "AnchorIntroduction",
        query: {
          id: username,
        },
      });
    },
    /**
     * 清除Toast实例
     */
    clearWaitToast() {
      this.watiToast.clear();
      this.dataLoading = false;
    },

    /**
     * 获取格式化后的直播时长
     */
    getFormatDuration(liveDuration) {
      const totalMinutes = Math.floor(liveDuration / 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (totalMinutes < 60) {
        return `${totalMinutes} ${this.$t("mins")}`;
      } else if (totalMinutes >= 60 && hours < 1) {
        return `${minutes} ${this.$t("mins")}`;
      } else {
        return `${hours} ${this.$t("hours")}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>

<style lang="scss">
@import "@/styles/ConfirmDialog.vant.scss";
</style>
