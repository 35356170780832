<template>
  
  <div class="animationCom">
    <iframe
      id="animate"
      :src="url"
      frameborder="0"
      scrolling="no"
      width="100%"
      height="100%"
      @load="handleLoad"
      :allowfullscreen="true"
    ></iframe>
    <div class="waitArea"><WaitToastArea v-show="isLoading"></WaitToastArea></div>
  </div>
</template>

<script>
import WaitToastArea from "@/components/WaitToastArea/Index";

export default {
  name: "AnimationCom",
  props: {
    url: {
      type: String
    }
  },
  components: {
    WaitToastArea
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    handleLoad() {
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.animationCom {
  pointer-events: none;
  height: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .waitArea{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
