/* eslint-disable */
import { useSocket } from '@/utils/websocket';
const state = {
  globalWSObj: {},
  callbackObj: {},
};
const mutations = {
  SOCKET_ONOPEN(state, obj) {
    state.globalWSObj = obj;
  },
  SOCKET_ONCLOSE(state) {
    state.globalWSObj = {};
  },
  SOCKET_ONMESSAGECALLBACK(state, { type, callback, id }) {
    if (Array.isArray(type)) {
      type.map((item) => {
        state.callbackObj[item] = {
          ...state.callbackObj[item],
          [id]: callback,
        };
      });
    } else {
      state.callbackObj[type] = {
        ...state.callbackObj[type],
        [id]: callback,
      };
    }
  },
};
const actions = {
  // 创建实时通讯websocket
  createGlobalWS({ commit }, { url, options = {} }) {
    const wsObj = useSocket({ url }, { ...options });
    commit('SOCKET_ONOPEN', wsObj);
  },
  // 断开实时通讯websocket
  disconnectGlobalWS({ commit }, {code, reason}) {
    state.globalWSObj?.closeSocket(code,reason);
    commit('SOCKET_ONCLOSE');
  },
  // 发送实时通讯消息
  sendGlobalMessage(context, message) {
    if (state.globalWSObj.readyState !== 1) {
      // 延迟发送
      setTimeout(() => {
        state.globalWSObj.sendMessage(message);
      }, 500);
    } else {
      state.globalWSObj.sendMessage(message);
    }
  },
  
  /**
   * 接收实时通讯消息
   * @param { Array } type 消息类型数组
   * @param { Function } callback 业务逻辑函数
   * @param { String } id 自定义的唯一标识
   */
  handleGlobalMessge({ commit }, { type, callback, id }) {
    // 允许多个type绑定同一个callback
    // 单个type时，入参type为string，多个type时，入参type为array
    commit('SOCKET_ONMESSAGECALLBACK', { type, callback, id });
    const subscribeCallback =  function() {
      state.globalWSObj?.subscribe?.('message', (result) => {
        try {
          const { type } = result;
          if (state.callbackObj?.[type]) {
            Object.keys(state.callbackObj[type]).map((key) => {
              state.callbackObj[type][key](result);
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    };
    if (state.globalWSObj.readyState === 1) {
      subscribeCallback();
    } else {
      state.globalWSObj?.subscribe?.('open', () => {
        subscribeCallback();
      });
    }
  },
  // 移除处理实时通讯消息事件
  removeHandleGlobalMessge({ commit }, { type, id }) {
    // 允许多个type解绑同一个callback
    // 单个type时，入参type为string，多个type时，入参type为array
    const obj = { ...state.callbackObj };
    if (Array.isArray(type)) {
      type.map((item) => {
        if (obj?.[item]) {
          delete obj[item][id];
        }
      });
    } else {
      if (obj?.[type]) {
        delete obj[type][id];
      }
    }
    state.callbackObj = obj;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
