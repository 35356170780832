<template>
  <div class="ChatListItemSkeleton">
    <!-- 头像 -->
    <div class="chat-avatar">
      <el-skeleton-item variant="circle" />
    </div>
    <!-- 文本信息 -->
    <div class="chat-text">
      <div class="chat-text-up">
        <div class="chat-sender">
          <el-skeleton-item variant="h3" />
        </div>
        <!-- 消息时间 -->
        <div class="chat-time">
          <el-skeleton-item variant="text" />
        </div>
      </div>
      <div class="chat-text-down">
        <el-skeleton-item variant="text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatListItemSkeleton",
};
</script>

<style lang="scss" scoped>
.ChatListItemSkeleton {
  height: 80px;
  background-color: var(--theme-box-1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  &:last-child {
    .chat-text {
      border-bottom: none;
    }
  }

  .chat-avatar {
    flex: 0 0 50px;
    height: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;

    .el-skeleton__circle {
      width: 50px;
      height: 50px;
    }
  }
  .chat-text {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding: 18px 0 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-line);

    .chat-text-up {
      height: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chat-sender {
        height: 22px;
        width: 50%;

        .el-skeleton__h3 {
          height: 100%;
        }
      }

      .chat-time {
        height: 17px;
        width: 30%;
      }
    }

    .chat-text-down {
      height: 17px;
      width: 80%;

      .el-skeleton__text {
        height: 100%;
      }
    }
  }
}
</style>
