/**
 * @file 马来西亚地区 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "Perjanjian Pengguna",
  privacyPolicy: "Dasar Privasi",
  termsOfService: "Syarat Perkhidmatan",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "Tak Siar",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "Sudah mula siaran",
  watch: "Tengok",
  follow: "Ikut",
  following: "Diikuti",
  subscribe: "Langgan",
  streamerSchedule: "Jadual penyiar",
  noDataPrompt: "Tiada data buat masa ini",
  unfollowPrompt: "Adakah anda pasti mahu batalkan mengikuti?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  hours: "jam",
  mins: "minit",
};

const AnchorInformation = {
  recommendedLiveStreams: "Siaran Langsung Disyorkan",
  noDataPrompt: "Tiada data buat masa ini",
};

const BasketballScoreIndex = {
  details: "Butiran",
  over: "Selesai",
  pointsDifference: "Perbezaan",
  totalScore: "Jumlah",
  live: "LIVE",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const BasketballSmallBig = {
  details: "Butiran",
  over: "Selesai",
  pointsDifference: "Perbezaan",
  totalScore: "Jumlah",
  live: "LIVE",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
  overIndex: "O",
  underIndex: "U",
};

const BindPhoneDialog = {
  bindPhonePrompt:
    "Demi keselamatan akaun, sila ikat nombor telefon untuk perkhidmatan lebih baik",
  goBind: "Pergi ke mengikat",
};

const BottomNavigationBar = {
  home: "Rumah",
  matches: "Peraduan",
  recommend: "Tips",
  chat: "Sembang",
  personal: "Peribadi",
  discovery: "Temui",
  money: "buat duit",
};

const ChatInterface = {
  noCsPrompt: "Tidak dapat bersembang pada masa ini",
  uploadRulePrompt: "Sila muat naik gambar dalam format jpg, jpeg, png",
  uploadSizePrompt: "Saiz imej tidak boleh melebihi 10M",
  sendError: "Gagal untuk dihantar",
  picSendError: "Penghantaran gambar gagal",
  InternetErrorPrompt: "Ralat Rangkaian",
  bannedSendPrompt: "Anda telah dilarang bercakap",
};

const ChatList = {
  noNewPrompt: "Tiada mesej buat masa ini",
  logInPrompt: "Sila log masuk untuk melihat kandungan",
  logIn: "Log masuk",
  group: "kumpulan",
};

const ChatListItem = {
  withdrawMessage: "menarik balik mesej",
  bannedPrompt: "dilarang bercakap",
  unBanPrompt: "telah dibatalkan",
  joinGroupPrompt: "Telah sertai kumpulan",
  leftGroupPrompt: "Telah keluar dari kumpulan",
  picture: "Gambar",
  link: "Pautan",
  emoji: "Emoji",
  addedFriendPrompt: "telah ditambah sebagai rakan anda",
};

const CompetitionList = {
  logInPrompt: "Sila log masuk untuk melihat kandungan",
  logIn: "Log masuk",
  today: "Hari ini",
  Monday: "Isnin",
  Tuesday: "Selasa",
  Wednesday: "Rabu",
  Thursday: "Khamis",
  Friday: "Jumaat",
  Saturday: "Sabtu",
  Sunday: "Ahad",
  noGames: "Tiada permainan lagi",
  toCollapse: "Klik untuk runtuh",
  toExpand: "Klik untuk mengembangkan",
};

const CompetitionListItem = {
  details: "Butiran",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const CompetitionMode = {
  matchMode: "Mod pertandingan",
  confirm: "Sahkan",
  cancel: "Batalkan",
};

const EmojiCom = {
  logAndSign: "Log masuk/Daftar sekarang",
  yes: "Ya",
  no: "Tidak",
};

const ExponentCom = {
  company: "Syarikat",
  initial: "Permulaan",
  ongoing: "Langsung",
  preMatch: "Sebelum perlawanan",
  noDataPrompt: "Tiada data buat masa ini",
  handicap: "Beri bola",
  WinDrawLose: "Menang, Kalah, Seri",
  totalGoals: "Jumlah Gol",
  cornerKick: "CK",
  pleaseSelect: "Sila pilih",
  confirm: "Sahkan",
  cancel: "Batalkan",
  FH: "HT",
  FT: "FT",
  pinnedPrompt: "Ditampal ke 'Chat Siaran'",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "Ikut",
  following: "Diikuti",
  chat: "Sembang",
  noDataPrompt: "Tiada data buat masa ini",
  unfollowPrompt: "Adakah anda pasti mahu batalkan mengikuti?",
  yes: "Ya",
  no: "Tidak",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const FootballCornerIndex = {
  details: "Butiran",
  over: "Selesai",
  FH: "HT",
  cornerKick: "CK",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const FootballScoreIndex = {
  details: "Butiran",
  over: "Selesai",
  FH: "HT",
  cornerKick: "CK",
  live: "LIVE",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const FootballScoreItem = {
  details: "Butiran",
  over: "Selesai",
  FH: "HT",
  cornerKick: "CK",
  live: "LIVE",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const GoalToast = {
  goal: "Gol",
};

const LiveRecommend = {
  noDataPrompt: "Tiada data buat masa ini",
  recoverLosses: "Pemulihan Kerugian",
  winningBetSlip: "Slip Pertaruhan Menang",
};

const LiveRecommendList = {
  victoryOrDefeat: "Kemenangan atau kekalahan",
};

const MatchCardList = {
  details: "Butiran",
};

const MatchStatus = {
  noDataPrompt: "Tiada data buat masa ini",
};

const MoreStreams = {
  switchLines: "Tukar talian",
  cancel: "Batalkan",
  currentStreamer: "Penyiar semasa",
  live: "LIVE",
  offLine: "Tak Siar",
  followersCount: "pengikut",
  otherStreamersPrompt:
    "Perlawanan semasa masih ada penyiar lain yang sedang menyiarkan",
  watch: "Tengok",
};

const NewMatchStatus = {
  team: "Team",
  halfTimeScore: "Skor separuh masa",
  shotOnTarget: "Tembakan mengena sasaran",
  missedShot: "Tembakan lari sasaran",
  attack: "Serangan",
  dangerousAttack: "Serangan berbahaya",
  number3PointGoals: "Gol lapangan 3 mata",
  number2PointGoals: "Gol lapangan 2 mata",
  penaltyKickGoals: "Lemparan bebas berhasil",
  freeThrowPercentage: "Peratusan lemparan bebas%",
  numberOfPausesRemaining: "Bilangan waktu rehat yang tersisa",
  numberOfFouls: "Bilangan kesalahan",
  totalNumberOfPauses: "Jumlah waktu rehat",
  threePointShot: "Jumlah lemparan tiga mata",
  twoPointShot: "Jumlah lemparan dua mata",
  shoot: "Tembakan",
  penalty: "Lemparan bebas",
  firstHalf: "Primera mitad",
  secondHalf: "Segunda mitad",
  redCard: "Kad Merah",
  yellowCard: "Kad Kuning",
};

const NoData = {
  noDataPrompt: "Tiada data buat masa ini",
};

const NoticeMessage = {
  addedFriendPrompt: "telah ditambah sebagai rakan anda",
  withdrawMessage: "menarik balik mesej",
  withdrawMessageByAdmin: "telah ditarik balik oleh pentadbir",
  joinGroupPrompt: "Telah sertai kumpulan",
  leftGroupPrompt: "Telah keluar dari kumpulan",
  removeGroupPrompt: "Dikeluarkan dari kumpulan oleh pengurus",
  bannedPrompt: "dilarang bercakap",
  unBanPrompt: "telah dibatalkan",
};

const OddsStatisticsList = {
  initial: "Permulaan",
  ongoing: "Langsung",
  preMatch: "Sebelum perlawanan",
  HW: "KPR",
  Dr: "Seri",
  AW: "KPT",
  max: "Nilai tertinggi",
  min: "Nilai minimum",
  average: "Nilai average",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "Tiada data buat masa ini",
};

const RecommendCompetitions = {
  today: "Hari ini",
  winsInARow: "Menang Berturut",
  noLivePrompt: "Tiada siaran langsung lagi",
};

const RecommendStreamerList = {
  noDataPrompt: "Tiada data buat masa ini",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "Hit minggu ini",
  victoryOrDefeat: "Kemenangan atau kekalahan",
  check: "Semak",
  chat: "Sembang",
  quarterlyProfit: "Keuntungan Suku Tahunan",
};

const RecommendStreamers = {
  recommendedByExperts: "Disyorkan oleh pakar",
  winsInARow: "Menang Berturut",
  changeBatch: "Memperbaharui",
  follow: "Ikut",
  following: "Diikuti",
  unFollowing: "Batal Ikuti",
  noDataPrompt: "Tiada data buat masa ini",
  logAndSign: "Log masuk/Daftar sekarang",
  yes: "Ya",
  no: "Tidak",
  InternetErrorPrompt: "Ralat Rangkaian",
  unfollowPrompt: "Adakah anda pasti mahu batalkan mengikuti?",
};

const RecordChart = {
  record: "Rekod",
  allWaysToPlay: "Semua cara untuk bermain",
  fromFarToNear: "Dari jauh ke dekat",
  lastTenGames: "10 perlawanan terakhir:",
  longestConsecutiveHit: "Pukulan paling lama berturut-turut",
  hitRate: "Kadar pukulan",
  currentPropmt: "perlawanan",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "Sila pilih negara/kawasan dan bahasa anda dengan teliti. Pilihan ini akan mempengaruhi penggunaan fungsi berikutnya dan tidak dapat diubah kemudian",
  getUserInfoErrorPrompt: "Gagal mendapatkan maklumat pengguna",
  getAreaAndLanguageErrorPrompt: "Gagal mendapatkan bahasa serantau",
};

const ScheduleCom = {
  startTime: "Masa mula",
};

const SchemeCard = {
  planList: "方案列表",
  release: "發布",
  draw: "D",
  Win: "W",
  halfWin: "1/2W",
  lost: "L",
  halfLost: "1/2L",
  all: "全部",
  guaranteedCompensation: "包賠",
  free: "免費",
  toll: "收費",
  victoryOrDefeat: "勝負",
};

const SquareCom = {
  watchPrompt: "orang sedang menonton",
  startedPrompt: "minit yang lalu, memulakan siaran",
  chat: "Sembang",
  sendMessageNeedLoginPrompt: "Sila log masuk untuk menghantar mesej",
  assistant: "Pentadbir",
  audience: "Pengguna",
  newMessage: "Mesej",
  confirmSend: "Hantar",
  cancelSend: "Batalkan",
};

const StatisticalChart = {
  hitRate: "Kadar pukulan",
};

const SwitchLines = {
  switchLines: "Tukar talian",
  cancel: "Batalkan",
  currentStreamer: "Penyiar semasa",
  live: "LIVE",
  offLine: "Tak Siar",
  followersCount: "pengikut",
  otherStreamersPrompt:
    "Perlawanan semasa masih ada penyiar lain yang sedang menyiarkan",
  watch: "Tengok",
  LINE: "Baris",
  VIP: "VIP",
  OFFICIAL: "rasmi",
};

const VideoCom = {
  videoErrorPrompt: "isyarat tidak terlalu baik. Sila cuba lagi kemudian",
  clickRetryPrompt: "muat semula",
};

const WelcomeCard = {
  join: "bergabung",
};

//utils
const tools = {
  yesterday: "Semalam",
  addedFriendPrompt: "telah ditambah sebagai rakan anda",
  withdrawMessage: "menarik balik mesej",
  joinGroupPrompt: "Telah sertai kumpulan",
  leftGroupPrompt: "Telah keluar dari kumpulan",
  removeGroupPrompt: "Dikeluarkan dari kumpulan oleh pengurus",
  bannedPrompt: "dilarang bercakap",
  unBanPrompt: "telah dibatalkan",
};

//页面
const AddressBook = {
  search: "Cari",
  addFriend: "Tambah kawan",
  myGroupChat: "Sembang kumpulan saya",
  friends: "Kawan",
  delete: "Padam",
  noFriendsPrompt: "Tiada kawan buat masa ini",
  nameAndIdSearch: "Masukkan nama, ID",
  searchResults: "Hasil carian",
  notFoundPrompt: "Tidak dapat mencari pengguna",
  add: "Tambah",
  deleteFriendPrompt: "Adakah anda pasti mahu padam kawan?",
  yes: "Ya",
  no: "Tidak",
  addFriendSuccessPrompt: "Tambah rakan berjaya",
  deleteFriendSuccessPrompt: "Padam rakan berjaya",
  InputSearchPrompt: "Sila masukkan kandungan",
};

const GroupChat = {
  groupChat: "Sembang kumpulan",
  groupSearch: "Masukkan kumpulan nama",
  noGroupPrompt: "Tiada sembang kumpulan buat masa ini",
  chat: "Sembang",
  InputSearchPrompt: "Sila masukkan kandungan",
};

const GroupAdministrator = {
  administrator: "Pentadbir",
  groupOwner: "Pemilik",
};

const GroupAnnouncement = {
  groupAnnouncement: "Pengumuman kumpulan",
  groupOwner: "Pemilik",
  administrator: "Pentadbir",
};

const GroupDetail = {
  groupDetail: "Butiran kumpulan",
  groupMembers: "Ahli kumpulan",
  groupName: "Nama kumpulan",
  groupDescription: "Penerangan kumpulan",
  groupOwner: "Pemilik",
  administrator: "Pentadbir",
  administratorList: "Senarai pentadbir",
  groupAnnouncement: "Pengumuman kumpulan",
  groupFiles: "Fail kumpulan",
  exitGroup: "Keluar kumpulan",
  exitGroupPrompt: "Adakah anda pasti mahu keluar dari kumpulan?",
  yes: "Ya",
  no: "Tidak",
  leftGroupSuccessPrompt: "Berjaya keluar dari kumpulan",
};

const GroupFile = {
  groupFiles: "Fail kumpulan",
  fileSearch: "Masukkan nama fail",
  today: "Hari ini",
  multimedia: "Media berbilang",
  file: "Fail",
  noFilesPrompt: "Tiada fail buat masa ini",
};

const GroupMembers = {
  groupMembers: "Ahli kumpulan",
  nameAndIdSearch: "Masukkan nama, ID",
};

const Chat = {
  chat: "Sembang",
  nameAndIdSearch: "Masukkan nama, ID",
  contacts: "Hubungi",
  InputSearchPrompt: "Sila masukkan kandungan",
  deleteContactPrompt: "Adakah anda pasti untuk menghapuskan sembang",
};

const Competition = {
  all: "Semua",
  ongoing: "Langsung",
  popular: "Popular",
  liveCompetition: "Penyiar",
  schedule: "Dijadualkan",
  results: "Tamat",
  subscribed: "Telah langgan",
  goal: "Gol",
  today: "Hari ini",
  Monday: "Isnin",
  Tuesday: "Selasa",
  Wednesday: "Rabu",
  Thursday: "Khamis",
  Friday: "Jumaat",
  Saturday: "Sabtu",
  Sunday: "Ahad",
};

const CompetitionDetails = {
  subscribe: "Langgan",
  subscribed: "Telah langgan",
  matchStreamerPrompt: "Penyiar perlawanan langsung",
  live: "LIVE",
  noDataPrompt: "Tiada data buat masa ini",
  noStreamersPrompt:
    "Tiada penyiar yang menyiarkan perlawanan ini buat masa ini",
  alreadyStreamerPrompt:
    "perlawanan sedang disiarkan oleh peneraju Adakah anda mahu menontonnya?",
  yes: "Ya",
  no: "Tidak",
  liveStream: "Siaran langsung",
  matchStatus: "Keadaan perlawanan",
  odds: "Odds",
  unSubscribePrompt: "Adakah anda ingin membatalkan langganan?",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
};

const OddsDetails = {
  company: "Syarikat",
  handicap: "Beri bola",
  WinDrawLose: "Menang, Kalah, Seri",
  totalGoals: "Jumlah Gol",
  cornerKick: "CK",
  HT: "Tuan rumah",
  Trd: "Trend",
  AT: "Pelawat",
  Tm: "Masa",
  Sc: "Skor",
  HW: "KPR",
  Dr: "Seri",
  AW: "KPT",
  RR: "KDP",
  Ov: "Lebih",
  Un: "Bawah",
};

const Home = {
  logIn: "Log masuk",
  signUp: "Daftar",
  popularMatches: "Perlawanan popular",
  more: "Lebih banyak",
  noDataPrompt: "Tiada data buat masa ini",
  currentlyLive: "Sedang Siaran Langsung",
  popularStreamers: "Penyiar popular",
  thinkYouLike: "Awak juga mungkin menyukai",
  loadMore: "Tambah lagi",
  noMoreDataPrompt: "Tiada lagi data lagi",
  addDeskPrompt: "Tambah ke desktop",
  clikToolPrompt: "Klik pada palang alat di bawah",
  andSeletcPrompt: "Dan pilih",
  addToScreenPrompt:
    "Kami ingin menambah pada skrin utama anda untuk memberikan perkhidmatan yang lebih baik",
    addToScreen: "Skrin Utama",
};

const MoreLives = {
  noDataPrompt: "Tiada data buat masa ini",
};

const MoreStreamers = {
  streamer: "Penyiar",
  streamerSearch: "Masukkan nama penyiar, ID",
  all: "Semua",
  notFollowing: "Belum diikuti",
  following: "Diikuti",
  live: "LIVE",
  offLine: "Tak Siar",
  followersCount: "pengikut",
  unfollowPrompt: "Adakah anda pasti mahu batalkan mengikuti?",
  yes: "Ya",
  no: "Tidak",
  logAndSign: "Log masuk/Daftar sekarang",
  InternetErrorPrompt: "Ralat Rangkaian",
  InputSearchPrompt: "Sila masukkan kandungan",
  dataRequestErrorPrompt: "Pemilihan data gagal",
};

const LiveRoom = {
  switchLines: "Tukar talian",
  streamChat: "Chat Siaran",
  streamerPrivateChat: "Sembang penyiar",
  chat: "Sembang",
  odds: "Odds",
  streamerInformation: "Maklumat penyiar",
  matchStatus: "Keadaan perlawanan",
};

const AnchorIntroduction = {
  streamerInformation: "Maklumat penyiar",
  photosWall: "Dinding gambar",
  seeMore: "Lebih banyak",
  streamerPlan: "Petua Streamer",
};

const AnchorPhoto = {
  photosWall: "Dinding gambar",
  noPhotosPrompt: "Tiada gambar lagi buat masa ini",
};

const LogInRegister = {
  logIn: "Log masuk",
  signUp: "Daftar",
  getAreaAndLanguageErrorPrompt: "Gagal mendapatkan bahasa serantau",
  quickRegisterFrequentPrompt: "Permintaan sering, sila cuba lagi kemudian",
};

const LoginFn = {
  phone: "Nombor telefon",
  email: "Emel",
  logInPassword: "Kata laluan log masuk",
  rememberPassword: "Ingat kata laluan",
  forgotPassword: "Lupa kata laluan anda",
  logIn: "Log masuk",
  logInAgreement: "Log masuk bermakna anda bersetuju dengan",
  userAgreement: "Perjanjian Pengguna",
  and: "dan",
  privacyPolicy: "Dasar Privasi",
  passwordRulePrompt:
    "Kata laluan mesti terdiri daripada huruf besar dan huruf kecil serta nombor dan mestilah lebih daripada 8 aksara",
  passwordEmptyPrompt: "Kata laluan tidak boleh kosong",
  emailRulePrompt: "Sila masukkan format alamat emel anda yang betul",
  emailEmptyPrompt: "Emel tidak boleh kosong",
  phoneRulePrompt:
    "Sila masukkan nombor telefon bimbit anda dalam format yang betul",
  phoneEmptyPrompt: "Nombor telefon bimbit tidak boleh kosong",
  phoneNum8: "Sila masukkan nombor telefon bimbit 8 digit anda",
  phoneNum9To10: "Sila masukkan nombor telefon bimbit 9 hingga 10 digit",
  phoneNum9: "Sila masukkan nombor telefon bimbit 9 digit anda",
  enterErrorPrompt: "Ralat input atau keabnormalan rangkaian",
  loginNoAgreePrompt:
    "Anda perlu bersetuju dengan perjanjian pengguna dan dasar Privasi untuk log masuk",
  accountAndPassword: "Akaun",
  account: "Akaun",
  accountEmptyPrompt: "Akaun tidak boleh kosong",
  accountOrPasswordError: "Nama pengguna atau kata laluan salah",
};

const Register = {
  phone: "Nombor telefon",
  email: "Emel",
  code: "Kod",
  getCode: "Dapatkan",
  logInPassword: "Kata laluan log masuk",
  CS: "KP",
  signUp: "Daftar",
  signUpAgreement: "Dengan mendaftar, anda bersetuju dengan",
  userAgreement: "Perjanjian Pengguna",
  and: "dan",
  privacyPolicy: "Dasar Privasi",
  contactCSPrompt:
    "Menghadapi masalah semasa pendaftaran? Hubungi KP untuk bantuan",
  contactCS: "Hubungi KP",
  passwordRulePrompt:
    "Kata laluan mesti terdiri daripada huruf besar dan huruf kecil serta nombor dan mestilah lebih daripada 8 aksara",
  passwordEmptyPrompt: "Kata laluan tidak boleh kosong",
  emailRulePrompt: "Sila masukkan format alamat emel anda yang betul",
  emailEmptyPrompt: "Emel tidak boleh kosong",
  phoneRulePrompt:
    "Sila masukkan nombor telefon bimbit anda dalam format yang betul",
  phoneEmptyPrompt: "Nombor telefon bimbit tidak boleh kosong",
  codeRulePrompt: "Kod pengesahan ialah 6 digit",
  codeEmptyPrompt: "Kod pengesahan mesti diisi",
  phoneNum8: "Sila masukkan nombor telefon bimbit 8 digit anda",
  phoneNum9To10: "Sila masukkan nombor telefon bimbit 9 hingga 10 digit",
  phoneNum9: "Sila masukkan nombor telefon bimbit 9 digit anda",
  phoneCodeSendSuccessPrompt:
    "Kod pengesahan berjaya dihantar, sila semak mesej teks pada telefon mudah alih anda",
  emailCodeSendSuccessPrompt:
    "Kod pengesahan berjaya dihantar, sila semak emel anda",
  phoneCodeSendErrorPrompt:
    "Kod pengesahan gagal dihantar, sila semak sama ada nombor telefon mudah alih tersedia",
  emailCodeSendErrorPrompt:
    "Kod pengesahan gagal dihantar, sila semak sama ada emel anda tersedia",
  codeSendToMuchPrompt:
    "Dihantar terlalu banyak kali pada masa yang sama, sila cuba lagi kemudian",
  phoneAlreadySignupPrompt:
    "Nombor telefon bimbit ini telah didaftarkan, sila log masuk terus",
  emailAlreadySignupPrompt:
    "Alamat emel ini telah didaftarkan, sila log masuk terus",
  signUpErrorPrompt:
    "Pendaftaran gagal, sila semak sama ada maklumat itu betul",
  enterErrorPrompt: "Ralat input atau keabnormalan rangkaian",
  noAgreePrompt:
    "Anda perlu bersetuju dengan perjanjian pengguna dan dasar Privasi untuk mendaftar",
  regionAndLanguagePrompt:
    "Sila pilih kawasan/bahasa sebenar anda, jika tidak ia akan menjejaskan fungsi",
  accountAndPassword: "Akaun",
  quickSignup: "Pendaftaran satu klik",
  accountEmptyPrompt: "Akaun tidak boleh kosong",
  accountRulePrompt:
    "Nombor akaun mestilah terdiri daripada 8-16 huruf dan nombor",
  accountAlreadySignupPrompt:
    "Akaun ini telah didaftarkan, sila log masuk terus",
  tryAgain: "cuba lagi",
};

const PersonalCenter = {
  logAndSign: "Log masuk/Daftar sekarang",
  logAndSignPrompt: "Buka segera fitur eksklusif dan manfaat istimewa",
  logOut: "Log keluar",
  myFollow: "Pengikutan",
  mySubscription: "Langganan",
  accountSecurity: "Keselamatan akaun",
  feedback: "Maklum Balas",
  deviceInformation: "Peranti",
  aboutUs: "Tentang kita",
  coin: "Syiling",
  moreServices: "Lebih banyak perkhidmatan",
  following: "Diikuti",
  subscribed: "Telah langgan",
  browser: "Pelayar",
  user: "Pengguna",
  profile: "Profil",
  nickname: "nama gelaran",
  save: "simpan",
  change: "Ubah",
  nicknamePrompt: "Sila masukkan gelaran anda",
  avatar: "Avatar",
  changeSuccessPrompt: "Perubahan berjaya",
  ballAnimation: "Animasi tujuan",
  ballVoice: "Kesan bunyi sasaran",
  setting: "Setting",
};

const IntegralSystem = {
  Coin: "Koin emas",
  goldCoinTask: "Tugas koin emas",
  signInPrompt: "Log masuk secara berterusan dan terima ganjaran secara berterusan",
  toComplete: "Selesai",
  toShare: "kongsi",
  shareTasks: "berkongsi tugas",
  exclusiveLink: "pautan eksklusif",
  copyLink: "Salin pautan",
  highestWin: "kemenangan tertinggi",
  day1: "Hari 1",
  day2: "Hari ke-2",
  day3: "hari ke-3",
  day4: "Hari ke 4",
  day5: "Hari ke 5",
  day6: "Hari ke 6",
  day7: "Hari ke-7",
  invitePrompt: "Surprise! Daftar dan muat turun aplikasi kami sekarang untuk menikmati faedah eksklusif bagi pengguna baru! Strim langsung perlawanan secara percuma, analisis data masa nyata, dan ramalan daripada pengulas profesional—semuanya di hujung jari anda. Jangan tunggu lagi, klik pautan untuk sertai sekarang!"
};

const Money = {
  activeCenter: "Pusat aktiviti",
  missionCenter: "Pusat misi",
};

const ActivityCenter = {
  toParticipate: "mengambil bahagian",
  sportActivity: "aktiviti sukan",
};

const ActivityDetails = {
  eventDetails: "Butiran Acara",
  dailyBetting: "Pertaruhan Harian",
  matchTime: "Masa perlawanan",
  popularForEvent: "Pilihan popular untuk acara ini",
  clickToParticipate: "Klik untuk menyertai sekarang",
  activityRules: "Peraturan Aktiviti",
  clickToContactParticipate: "Klik untuk menyertai sekarang",
  activityObj: "objek aktif",
  activityPlatform: "Platform acara",
  activityDuration: "Tempoh aktiviti",
  activityDemand: "Keperluan aktiviti",
  activityTime: "Masa aktiviti",
};

const AboutUs = {
  aboutUs: "Tentang kita",
  platformIntroduction: "Perkenalan Platform",
  privacyPolicy: "Dasar Privasi",
  userAgreement: "Perjanjian Pengguna",
};

const DeviceInfo = {
  deviceInformation: "Peranti",
  currentTime: "Masa Semasa",
  port: "Port Log Masuk",
  ip: "IP Log Masuk",
  version: "Version Semasa",
  ipAndPort: "Maklumat Log Masuk",
};

const FeedBack = {
  feedback: "Maklum Balas",
  feedbackType: "Jenis Maklum Balas",
  productSuggestion: "Cadangan Produk",
  functionalFault: "Kerosakan Fungsi",
  interfaceSuggestion: "Cadangan Antaramuka",
  accountIssue: "Masalah Akaun",
  operationExperience: "Pengalaman Operasi",
  otherFeedback: "Maklum Balas Lain",
  problemDescription: "Penerangan Masalah",
  submit: "Hantar",
  addPhoto: "Tambah Foto",
  uploadPrompt: "Maaf, hanya 3 gambar boleh dimuat naik",
  uploadRulePrompt: "Sila muat naik gambar dalam format jpg, jpeg, png",
  uploadSizePrompt: "Saiz imej tidak boleh melebihi 10M",
  chooseFeedback: "Sila pilih jenis maklum balas",
  inputQuestionPrompt: "Sila masukkan soalan",
  submitSuccessPrompt: "Dihantar dengan jayanya",
};

const MySubscription = {
  myFollow: "Pengikutan",
  mySubscription: "Langganan",
  streamer: "Penyiar",
  matches: "Peraduan",
};

const PrivacyAgreement = {
  privacyPolicy: "Dasar Privasi",
};

const TermsofService = {
  termsOfService: "Syarat Perkhidmatan",
};

const UserAgreement = {
  userAgreement: "Perjanjian Pengguna",
};

const AccountSecurity = {
  accountSecurity: "Keselamatan akaun",
  phone: "Nombor telefon",
  email: "Emel",
  bind: "Ikat",
  notBound: "Belum diikat",
  change: "Ubah",
  logInPassword: "Kata laluan log masuk",
  accountChangePassword:
    "Sila ikat telefon bimbit/alamat e-mel anda terlebih dahulu",
};

const BindEmail = {
  bindEmail: "Ikat e-mel",
  email: "Emel",
  code: "Kod",
  getCode: "Dapatkan",
  confirm: "Sahkan",
  emailRulePrompt: "Sila masukkan format alamat emel anda yang betul",
  emailEmptyPrompt: "Emel tidak boleh kosong",
  codeRulePrompt: "Kod pengesahan ialah 6 digit",
  codeEmptyPrompt: "Kod pengesahan mesti diisi",
  changeSuccessPrompt: "Perubahan berjaya",
  changeErrorPrompt: "Perubahan gagal",
  bindSuccessPrompt: "Pengikatan berjaya",
  bindErrorPrompt: "Pengikatan gagal",
  emailCodeSendSuccessPrompt:
    "Kod pengesahan berjaya dihantar, sila semak emel anda",
  emailCodeSendErrorPrompt:
    "Kod pengesahan gagal dihantar, sila semak sama ada emel anda tersedia",
  codeSendToMuchPrompt:
    "Dihantar terlalu banyak kali pada masa yang sama, sila cuba lagi kemudian",
  emailAlreadySignupPrompt:
    "Alamat emel ini telah didaftarkan, sila log masuk terus",
  tryAgain: "cuba lagi",
};

const BindPhone = {
  bindPhone: "Ikat telefon bimbit",
  phone: "Nombor telefon",
  code: "Kod",
  getCode: "Dapatkan",
  confirm: "Sahkan",
  cancel: "Batalkan",
  phoneRulePrompt:
    "Sila masukkan nombor telefon bimbit anda dalam format yang betul",
  phoneEmptyPrompt: "Nombor telefon bimbit tidak boleh kosong",
  codeRulePrompt: "Kod pengesahan ialah 6 digit",
  codeEmptyPrompt: "Kod pengesahan mesti diisi",
  changeSuccessPrompt: "Perubahan berjaya",
  changeErrorPrompt: "Perubahan gagal",
  bindSuccessPrompt: "Pengikatan berjaya",
  bindErrorPrompt: "Pengikatan gagal",
  phoneNum8: "Sila masukkan nombor telefon bimbit 8 digit anda",
  phoneNum9To10: "Sila masukkan nombor telefon bimbit 9 hingga 10 digit",
  phoneNum9: "Sila masukkan nombor telefon bimbit 9 digit anda",
  phoneCodeSendSuccessPrompt:
    "Kod pengesahan berjaya dihantar, sila semak mesej teks pada telefon mudah alih anda",
  phoneCodeSendErrorPrompt:
    "Kod pengesahan gagal dihantar, sila semak sama ada nombor telefon mudah alih tersedia",
  codeSendToMuchPrompt:
    "Dihantar terlalu banyak kali pada masa yang sama, sila cuba lagi kemudian",
  phoneAlreadySignupPrompt:
    "Nombor telefon bimbit ini telah didaftarkan, sila log masuk terus",
  checkSignup: "Sila semak sama ada nombor itu didaftarkan",
  tryAgain: "cuba lagi",
};

const ChangePassword = {
  resetPassword: "Tetapkan semula kata laluan",
  phone: "Nombor telefon",
  email: "Emel",
  code: "Kod",
  getCode: "Dapatkan",
  logInPassword: "Kata laluan log masuk",
  confirm: "Sahkan",
  cancel: "Batalkan",
  passwordRulePrompt:
    "Kata laluan mesti terdiri daripada huruf besar dan huruf kecil serta nombor dan mestilah lebih daripada 8 aksara",
  passwordEmptyPrompt: "Kata laluan tidak boleh kosong",
  emailRulePrompt: "Sila masukkan format alamat emel anda yang betul",
  emailEmptyPrompt: "Emel tidak boleh kosong",
  phoneRulePrompt:
    "Sila masukkan nombor telefon bimbit anda dalam format yang betul",
  phoneEmptyPrompt: "Nombor telefon bimbit tidak boleh kosong",
  codeRulePrompt: "Kod pengesahan ialah 6 digit",
  codeEmptyPrompt: "Kod pengesahan mesti diisi",
  phoneNum8: "Sila masukkan nombor telefon bimbit 8 digit anda",
  phoneNum9To10: "Sila masukkan nombor telefon bimbit 9 hingga 10 digit",
  phoneNum9: "Sila masukkan nombor telefon bimbit 9 digit anda",
  resetSuccess: "Penetapan semula kata laluan berjaya",
  resetError:
    "Tetapan semula kata laluan gagal, sila semak sama ada maklumat itu betul",
  checkSignup: "Sila semak sama ada nombor itu didaftarkan",
  phoneCodeSendSuccessPrompt:
    "Kod pengesahan berjaya dihantar, sila semak mesej teks pada telefon mudah alih anda",
  codeSendToMuchPrompt:
    "Dihantar terlalu banyak kali pada masa yang sama, sila cuba lagi kemudian",
  phoneAlreadySignupPrompt:
    "Nombor telefon bimbit ini telah didaftarkan, sila log masuk terus",
  phoneCodeSendErrorPrompt:
    "Kod pengesahan gagal dihantar, sila semak sama ada nombor telefon mudah alih tersedia",
  emailCodeSendSuccessPrompt:
    "Kod pengesahan berjaya dihantar, sila semak emel anda",
  emailCodeSendErrorPrompt:
    "Kod pengesahan gagal dihantar, sila semak sama ada emel anda tersedia",
  emailAlreadySignupPrompt:
    "Alamat emel ini telah didaftarkan, sila log masuk terus",
  bindPhoneOrEmail:
    "Sila ikat nombor telefon bimbit atau alamat e-mel anda terlebih dahulu",
  tryAgain: "cuba lagi",
};

const QuickRegister = {
  downloadScreenshot: "Tải xuống ảnh chụp màn hình",
  saveAccountAndPassword: "Sila pastikan akaun dan kata laluan anda selamat",
  suggestSave:
    "Anda disyorkan untuk mengambil tangkapan skrin dan menyimpannya atau menyalinnya ke memo.",
  account: "Akaun",
  password: "Kata laluan",
  copyAccountAndPassword: "Salin kata laluan akaun",
  prompt: "Petunjuk",
  sureSave: "Adakah anda telah menyimpan kata laluan akaun anda??",
  confirm: "Sahkan",
  cancel: "Batalkan",
  copySuccess: "Berjaya disalin",
  copyFailure: "Salinan gagal",
  manualRegister:
    "Bilangan kali telah mencapai had atas, sila daftar secara manual",
};

const Recommend = {
  recommend: "Mengesyorkan",
  InternetErrorPrompt: "Ralat Rangkaian",
  hitOfTheWeek: "Hit minggu ini",
  victoryOrDefeat: "Kemenangan atau kekalahan",
  check: "Semak",
  lastTenGames: "10 perlawanan terakhir:",
  recommendedByExperts: "Disyorkan oleh pakar",
  changeBatch: "Memperbaharui",
  follow: "Ikut",
  following: "Diikuti",
  unFollowing: "Batal Ikuti",
  all: "Semua",
  guaranteedCompensation: "Pampasan terjamin",
  free: "Percuma",
  toll: "TOL",
  HOT: "Penjualan panas",
  HIT: "Hit",
  STREAK: "Pukulan terus",
  WEEK_HIT: "Kadar pukulan minggu",
  MONTH_HIT: "Kadar pukulan bulanan",
  QUARTER_HIT: "Kadar pukulan musim",
  RECENT_10_HIT: "Hampir sepuluh kadar pukulan",
};

const RecommendDetail = {
  homePage: "Laman utama",
  chat: "Sembang",
  follow: "Ikut",
  following: "Diikuti",
  unFollowing: "Batal Ikuti",
  followersCount: "pengikut",
  planCount: "Rancangan",
  viewCount: "Penonton",
  statistics: "Perangkaan",
  plan: "Rancang",
};

const SchemeDetails = {
  planDetails: "Butiran Rancangan",
  release: "lepaskan",
  chat: "Sembang",
  follow: "Ikut",
  following: "Diikuti",
  unFollowing: "Batal Ikuti",
  details: "Butiran",
  analyze: "Menganalisis",
  title: "Tajuk",
  privateChatStreamerJoinsGroup:
    "Mesej Peribadi kepada Hos untuk Sertai Kumpulan",
  upcoming: "Akan",
  othersPlan: "Petua Lain",
};
const MatchInfo = {
  homeTag: "Pasukan Rumah",
};

const LeagueSelection = {
  matchSorting: "Isih padanan",
  byTime: "pada masa",
  byLeague: "secara liga",
  confirm: "Sahkan",
  cancel: "Batalkan",
};

const RecommendPublish = {
  enterTeam: "Nhập đội",
  planRelease: "Keluaran rancangan",
  predictionTitle:
    "Tajuk (kelihatan kepada semua pengguna, sila jangan dedahkan hasil ramalan)",
  titleEmptyPrompt: "Tajuk dan kandungan analisis tidak boleh kosong",
  pleaseSelectAtLeastOneGameToPredictResults:
    "Sila pilih sekurang-kurangnya satu permainan untuk meramal hasil",
};

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "Bola sepak",
  basketball: "Bola keranjang",
  mixed: "Terpadu",
  setLogInPassword: "Tetapkan kata laluan log masuk",
  OCS: "KPDT",
  OCSPrompt: "Disediakan untuk anda",
  phoneInCorrectPrompt: "Nombor telefon salah",
  codeInCorrectPrompt: "Kod salah",
  emailInCorrectPrompt: "Emel salah",
  passwordPrompt: "Sila masukkan kombinasi 8-16 aksara Inggeris dan nombor",
  newPassword: "Masukkan kata laluan baru",
  resetPasswordPrompt: "Adakah anda pasti mahu menetapkan semula kata laluan?",
  startChatPrompt: "Anda boleh mula sembang sekarang",
  send: "Hantar",
  noFollowPrompt: "Tiada pengikut buat masa ini",
  feedbackPrompt:
    "Sila tinggalkan komen berharga anda dan kami akan bekerja keras untuk menambah baik!",
  bindPhoneByOtherPrompt:
    "Nombor mudah alih ini telah diikat oleh pengguna lain",
  bindEmailByOtherPrompt: "Alamat e-mel ini telah diikat oleh pengguna lain",
  super_manager: "Pentadbir super",
  score: "Skor",
  scoreIndex: "Indeks Skor",
  cornerKickIndex: "Sepakan sudut",
  close: "Tutup",
  midfield: "HT",
  overtime: "Lebih masa",
  penaltyKick: "Sepakan penalti",
  endOfFirstQuarter: "Q1 berakhir",
  endOfSecondQuarter: "Q2 berakhir",
  endOfThirdQuarter: "Q3 berakhir",
  endOfFourthQuarter: "Q4 berakhir",
  pullUpToLoadMore: "Pemuatan tarik naik",
  pullDownToRefresh: "Tarik ke bawah untuk menyegarkan",
  releaseToRefreshImmediately: "Lepaskan untuk menyegarkan serta-merta",
  refreshPrompt: "Menyegarkan...",
  download: "Muat Turun",
  appDownload: "Muat Turun APP",
  brandName: "UFOScore",
  saveToDesktop: "Add To Home Screen",
  downloadTitle: "Muat Turun APP",
  normalDownload: "Muat Turun Biasa",
  scanningPrompt: "Imbas untuk memasang dan menerima diskaun besar eksklusif, dengan faedah mewah lain yang menanti anda!",
  downloadFailToWeb: "Klik di sini untuk menggunakan versi web",
  scanQrCode: "Imbas kod QR pada telefon bimbit",
  usingMobileDevices: "Mengguna peranti bimbit",
  installTutorial: "Klik untuk melihat tutorial pemasangan",
  whyNameDifferent:
    "Nama APP yang dipasang dan APP yang dimuat turun adalah tidak konsisten, sila muat turun, pasang dan gunakan!",
  clickDownload: "Klik untuk memuat turun",
  installTutorialPrompt: "Panduan Pemasangan",
  brandNativeName: "APP asli UFOScore",
  uploadingPrompt: "memuat naik...",
  bHandicap: "Handicap",
  different: "different",
  total: "total",
  off: "Akan",
  video: "Video",
  animation: "Animasi",
  install: "Pasang",
  startDownloading: "Mula muat turun",
  cancelGoal: "Gol dibatalkan",
  appUpdatePrompt: "The APP has a new version, please update it",
};
