<template>
  <div
    class="wait-toast-area"
    :style="{
      backgroundImage: `url(${iconUrl})`,
    }"
  >
  </div>
</template>

<script>
export default {
  name: "WaitToastArea",
  data() {
    return {
      iconUrl: require("@/assets/client-logos/nodata.webp"),
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotateAtion {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.wait-toast-area {
  // width: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  animation: rotateAtion 2s linear infinite;

}
</style>
