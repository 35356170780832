<template>
  <div class="liveRecommend">
    <div class="liveRecommendContent">
      <div class="tabAreaScroll" ref="tabAreaScroll">
        <div class="tabArea">
          <div
            class="tabItem"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ activeTab: activeTab === item.type }"
            @click="changeTab(item.type)"
          >
            {{ $t(item.title) }}
          </div>
        </div>
      </div>
      <div
        class="liveRecommandListArea"
        ref="liveRecommandListArea"
        @scroll="handleScroll"
        @touchmove="handleScroll"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <!-- <van-image
          class="pullDownIcon"
          :src="logoUrl"
          v-if="dataList.length"
        ></van-image> -->
        <!-- <RecommendStreamerList
          :tabList="tabList"
          :activeTab="activeTab"
          :dataList="dataList"
          @changeTab="changeTab"
          v-if="dataList?.length"
          :style="{ transform: 'translateY(' + y + 'px)' }"
        ></RecommendStreamerList> -->
        <RecommendStreamerList
          :tabList="tabList"
          :activeTab="activeTab"
          :dataList="dataList"
          @changeTab="changeTab"
          v-if="dataList?.length"
        ></RecommendStreamerList>
        <div class="noDataWrapper" v-if="!dataList?.length">
          <NoData v-show="!dataLoading"></NoData>
          <WaitToastArea v-show="dataLoading"></WaitToastArea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import WaitToastArea from "@/components/WaitToastArea/Index";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  getFootballAppreciation,
  getBasketballAppreciation,
} from "@/services/api/appreciation";
import RecommendStreamerList from "@/components/RecommendStreamerList/Index.vue";
import NoData from "@/components/NoData/Index.vue";
import toast from "@/utils/toast.js";
import { debounce } from "lodash";
export default {
  name: "LiveRecommend",
  props: {
    competitionId: {
      type: String,
    },
    userInfo: {
      type: Object,
    },
    sportTypeId: {
      type: Number,
    },
  },
  components: {
    RecommendStreamerList,
    NoData,
    WaitToastArea,
  },
  data() {
    return {
      tabList: [
        {
          title: "HOT",
          type: "HOT",
        },
        {
          title: "HIT",
          type: "HIT",
        },
        {
          title: "STREAK",
          type: "STREAK",
        },
      ],
      activeTab: "HOT",
      params: {
        page: 1,
        size: 50,
        pages: 1,
      },
      dataLoading: true, //toast是否在加载状态
      isTop: false,
      logoUrl: require("@/assets/client-logos/com-logo@3x.webp"),
      startY: 0,
      startScrollTop: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState({
      params: (state) => state.recommend.liveRecommendParams,
      dataList: (state) => state.recommend.liveRecommendData,
    }),
  },
  async mounted() {
    //获取荐球数据
    await this.getAppreciationData(true, true);
  },

  methods: {
    ...mapActions("recommend", ["getLiveRecommendsData"]),
    ...mapMutations("recommend", [
      "SET_LIVERECOMMEND_DATA",
      "SET_LIVERECOMMEND_PARAMS",
    ]),

    async pullingDownHandler() {
      await this.getAppreciationData(true);
    },
    /**
     * 上拉事件触发加载更多
     */
    async pullingUpHandler() {
      if (this.params.page + 1 > this.params.pages) {
        return;
      } else {
        await this.getAppreciationData(false);
      }
    },

    // 滚动事件
    handleScroll: debounce(function () {
      if (!this.$refs.liveRecommandListArea) {
        return;
      }
      // 判断手势是上拉还是下拉
      if (this.$refs.liveRecommandListArea.scrollTop === 0) {
        this.isTop = true;
        return;
      }
      if (
        Math.ceil(this.$refs.liveRecommandListArea.scrollTop) +
          this.$refs.liveRecommandListArea.clientHeight >=
        this.$refs.liveRecommandListArea.scrollHeight
      ) {
        this.pullingUpHandler();
        return;
      }
    }, 200),

    handleTouchStart(event) {
      if (!this.$refs.liveRecommandListArea) {
        return;
      }
      this.startY = event.touches[0].clientY;
      this.startScrollTop = this.$refs.liveRecommandListArea.scrollTop;
      if (this.startScrollTop === 0) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    },
    async handleTouchEnd(event) {
      const endY = event.changedTouches[0].clientY;
      if (this.isTop && endY > this.startY) {
        this.isTop = false;
        await this.pullingDownHandler();
      }
    },
    //获取荐球数据
    async getAppreciationData(isRefresh, isFirstRefresh = false) {
      this.dataLoading = true;
      const watiToast = this.dataList.length ? toast.initWaitToast() : null;
      try {
        if (isRefresh && !isFirstRefresh) this.y = 15;
        const res = await this.getLiveRecommendsData({
          isRefresh,
          streamer_category: this.activeTab,
          sportTypeId: this.sportTypeId,
          competitionId: this.competitionId,
        });
        const { statusCode } = res;
        toast.clearWaitToast(watiToast);
        this.dataLoading = false;
      } catch (error) {
        toast.clearWaitToast(watiToast);
        this.dataLoading = false;
        console.error(error);
      }
      setTimeout(() => {
        if (isRefresh) this.y = 0;
      }, 500);
    },

    // 切换tab
    changeTab(vlaue) {
      this.activeTab = vlaue;
      this.SET_LIVERECOMMEND_PARAMS({ data: { page: 1, size: 10, pages: 0 } });
      this.getAppreciationData(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
