import request from "@/services/axios/commonRequest";
// 荐球数据
// export function getAppreciation(params) {
//   return request({
//     url: "/appreciation/",
//     method: "get",
//     params: params,
//   });
// }
export function getAppreciation(params) {
  return request({
    url: "/tipsdata",
    method: "get",
    params: params,
  });
}

export function getAppreciationDetail(params) {
  return request({
    url: "/tipsdatadetail",
    method: "get",
    params: params,
  });
}

// 主页足球荐球数据
export function getHomeFootballAppreciation(params) {
  return request({
    url: "football-strategies/streamers",
    method: "get",
    params: params,
  });
}
// 主页篮球荐球数据
export function getHomeBasketballAppreciation(params) {
  return request({
    url: "basketball-strategies/streamers",
    method: "get",
    params: params,
  });
}

// 足球荐球数据
export function getFootballAppreciation(params) {
  return request({
    url: "/football-strategies",
    method: "get",
    params: params,
  });
}
// 足球荐球数据详情
export function getFootballAppreciationDetail(params) {
  return request({
    url: `/football-strategies/${params.strategy_id}`,
    method: "get",
    params: params,
  });
}
// 篮球荐球数据
export function getBasketballAppreciation(params) {
  return request({
    url: "/basketball-strategies",
    method: "get",
    params: params,
  });
}
// 篮球荐球数据详情
export function getBasketballAppreciationDetail(params) {
  return request({
    url: `/basketball-strategies/${params.strategy_id}`,
    method: "get",
    params: params,
  });
}

// 获取指定日期指定赛事的所有比赛-足球
export function getFootballMatchs(params) {
  return request({
    url: "/bet/football-matches",
    method: "get",
    params,
  });
}

// 获取指定日期指定赛事的所有比赛-篮球
export function getBasketballMatchs(params) {
  return request({
    url: "/bet/basketball-matches",
    method: "get",
    params,
  });
}

// 获取足球荐球选项
export function getFootballSelection(language_id) {
  return request({
    url: "/football-selection-types",
    method: "get",
    params: {
      language_id,
    },
  });
}

// 获取篮球荐球选项
export function getBasketballSelection(language_id) {
  return request({
    url: "/basketball-selections-types",
    method: "get",
    params: {
      language_id,
    },
  });
}

// 新增足球鉴球方案
export function addFootballStrategy(language_id, data) {
  return request({
    url: "/football-strategies/me",
    method: "post",
    params: {
      language_id,
    },
    data,
  });
}

// 新增篮球鉴球方案
export function addBasketballStrategy(language_id, data) {
  return request({
    url: "/basketball-strategies/me",
    method: "post",
    params: {
      language_id,
    },
    data,
  });
}

// 获取已发布的方案列表-足球
export function getFootballStrategies(params) {
  return request({
    url: "/football-strategies/me",
    method: "get",
    params,
  });
}

// 获取已发布的方案列表-篮球
export function getBasketballStrategies(params) {
  return request({
    url: "/basketball-strategies/me",
    method: "get",
    params,
  });
}

// 获取个人足球方案详情
export function getFootballMyAppreciationDetail(params) {
  return request({
    url: `/football-strategies/me/${params.strategy_id}`,
    method: "get",
    params: params,
  });
}
// 获取个人篮球方案详情
export function getBasketballMyAppreciationDetail(params) {
  return request({
    url: `/basketball-strategies/me/${params.strategy_id}`,
    method: "get",
    params: params,
  });
}

// 获取主播方案统计
export function getStreamerStatistics(params){
  return request({
    url: "/get-streamer-statistical-data",
    method: "get",
    params: params,
  });
}