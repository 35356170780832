import request from "@/services/axios/commonRequest";
import IMRequest from "@/services/axios/IMRequest";
// 获取关于我们的信息
export function getAboutUs(params) {
  return request({
    url: "/meta-datas",
    method: "get",
    params,
  });
}
// 获取设备信息
export function getDeviceInfo(params) {
  return request({
    url: "/device-information",
    method: "get",
    params,
  });
}
// 提交反馈
export function submitFeedback(data) {
  return request({
    url: "/feedbacks",
    method: "post",
    data,
  });
}
// 获取官方客服
export function getOfficialService(params) {
  return IMRequest({
    url: "/official-customer-services",
    method: "get",
    params,
  });
}
