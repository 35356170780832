import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import user from './modules/user';
import translateData from './modules/translateData'
import chatIM from './modules/chatIM';
import competitions from './modules/competitions';
import lives from './modules/lives';
import home from './modules/home';
import globalWS from './modules/globalWS';
import chatWS from './modules/chatWS';
import liveWS from './modules/liveWS';
import personalCenter from './modules/personalCenter';
import feedBack from './modules/feedBack';
import recommend from './modules/recommend';
import anchorIntroduction from './modules/anchorIntroduction';
import tasks from './modules/tasks';
import taskBeacon from './modules/taskBeacon';
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    translateData,
    chatIM,
    competitions,
    lives,
    home,
    globalWS,
    chatWS, 
    liveWS,
    personalCenter,
    feedBack,
    recommend,
    anchorIntroduction,
    tasks,
    taskBeacon
  },
  getters,
})
export default store