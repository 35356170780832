<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import { getCommunicationChannel } from "@/services/api/IM";
import { checkSystemVersion, checkAPKVersion } from "@/utils/tools";
import { languageObj } from "@/locales";
import { getToken, checkClientTokenRole } from "./utils/authCookie";
import { addClickListeners, watchRoute } from "@/utils/tools";
import { EventBus } from "@/utils/event-bus";
const TOKEN_KEY = "client_token";
const MANUAL_CLOSE = 3007;
export default {
  data() {
    return {
      timer: null,
      closeTimer: null,
    };
  },
  computed: {
    ...mapState({
      globalWSObj: (state) => state.globalWS.globalWSObj,
      chatWSObj: (state) => state.chatWS.chatWSObj,
    }),
  },
  async created() {
    await this.checkToken();
    
    // 监听 pathObj 的更新
    EventBus.$on("updatePathObj", (newPathObj) => {
      console.log("监听 pathObj 的更新");
      watchRoute(this, newPathObj);
    });
  },
  async mounted() {
    this.initEvent();
    if (localStorage.getItem("webClientDevice") === "Android") {
      await checkAPKVersion((data) => {
        this.checkAPK(data);
      });
    }
    await this.initStatus();
    await this.initApp();
    this.closePreLoad();
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", () => {});
    window.removeEventListener("resize", this.initSize); // 移除事件
  },
  methods: {
    ...mapActions("chatWS", [
      "createChatWS",
      "handleChatMessge",
      "disconnectChatWS",
    ]),
    ...mapActions("chatIM", [
      "addRecentMessage",
      "setCustomerMessageCount",
      "getTotalUnreadCount",
    ]),
    ...mapActions("tasks", ["getTaskList"]),
    async initTotalUnreadCount() {
      if (checkClientTokenRole() === "user") {
        try {
          await this.getTotalUnreadCount();
        } catch (e) {
          console.log(e);
        }
      }
    },
    /**
     * 获取safari的真实1vh 计算视口高度（viewport height）
     */
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    /**
     * 解决横竖屏切换时，页面的宽度问题
     */
    setMaxWidth() {
      const html = document.documentElement;
      const windowHeight = html.clientHeight;
      const windowWidth = html.clientWidth;
      // 检查事件是否是body和html，如果是就阻止，其他的：该元素或任何父元素是可滚动就不阻止
      //  判断是否为ipad/PC
      if (windowWidth > 500) {
        // 根据屏幕宽度与 375/812
        let width =
          (windowHeight * 375) / 812 >= 375 ? (windowHeight * 375) / 812 : 375;
        // 设置body的宽度
        document.body.style.width = width + "px";
        // 设置一个变量保存当前的宽度，用于绝对定位的元素
        document.documentElement.style.setProperty(
          "--maxWidthPx",
          `${width}px`
        );
        // if (window.navigator.standalone === true) {
        //   document.body.addEventListener(
        //     "touchmove",
        //     function (e) {
        //       let el = e?.target;
        //       while (el !== document.body && el !== document.documentElement) {
        //         if (el?.scrollHeight > el?.clientHeight) {
        //           return; // allow the event's default behavior
        //         }
        //         el = el?.parentNode;
        //       }
        //       e?.preventDefault(); // prevent the event's default behavior
        //     },
        //     { passive: false }
        //   );
        // }
      } else {
        document.documentElement.style.setProperty(
          "--maxWidthPx",
          `${windowWidth}px`
        );
      }
    },
    initSize() {
      this.setMaxWidth();
      this.setVh();
    },
    /**
     * 获取聊天websocket连接地址账号 密码
     */
    async initChatWS() {
      try {
        const res = await getCommunicationChannel();
        const { data, statusCode } = res;
        if (statusCode === 200) {
          const {
            link,
            heartbeat_interval: heartbeatInterval,
            ack_nbits: ackNbits,
            heartbeat_tolerance: heartbeatTolerance,
            max_reconnect_times: maxReconnectTimes,
            ack_interval: ackInterval,
          } = data || {};
          // 已登陆，连接聊天ws
          this.createChatWS({
            url: link,
            options: {
              heartbeatInterval,
              ackNbits,
              heartbeatTolerance,
              maxReconnectTimes,
              ackInterval,
              query: {
                country_id: localStorage.getItem("country_id"),
                language_id: localStorage.getItem("language_id"),
              },
            },
          });
          // 监听聊天ws消息
          this.handleChatMessge({
            type: [
              "chat_message_receive",
              "friend_add",
              "friend_recall",
              "group_recall",
              "group_increase",
              "group_decrease",
              "group_upload",
              "group_ban",
              "ocs_chat_message_receive",
            ],
            callback: (message) => {
              // console.log('chatList', message);

              const { data, type } = message;
              if (type === "ocs_chat_message_receive") {
                this.setCustomerMessageCount(data.unread_message_count);
              }
              this.addRecentMessage(message);
            },
            id: "chatList",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 关闭加载页
    closePreLoad() {
      const preLoad = document.querySelector(".html-preLoad");
      if (preLoad) preLoad.style.visibility = "hidden";
      document.removeEventListener("touchmove", preventTouchMove, {
        passive: false,
      });
    },
    // 初始化状态
    async initStatus() {
      //  初始进入页面
      if (checkClientTokenRole() !== "newVistor") {
        // 判断已登陆
        const language_id = localStorage.getItem("language_id");
        const languageAbbreviation = languageObj[language_id];
        const translations = require("@/locales/" +
          languageAbbreviation +
          ".js").default;
        // 更新翻译配置
        // 更新 VueI18n 实例的翻译配置
        this.$i18n.locale = languageAbbreviation;
        this.$i18n.setLocaleMessage("en", translations);
      } else {
        if (
          (!localStorage.getItem("language_id") &&
            !localStorage.getItem("country_id")) ||
          !getToken(TOKEN_KEY)
        ) {
          await this.guestLoginAction();
        }
      }
    },
    toDownload() {
      eval(
        `plus.runtime.openURL('${window.location.origin}/Download?apkUpdate=true')`
      );
    },
    cancelDownload() {
      this.isShowDialog = false;
    },
    // 检查APK壳版本
    checkAPK(data) {
      const { APKVersion, APKforceUpdate } = data;
      if (APKVersion !== process.env.VUE_APP_APK_VERSION) {
        if (APKforceUpdate) {
          this.$dialog
            .alert({
              title: this.$t("appUpdatePrompt"),
              theme: "round-button",
              confirmButtonText: this.$t("yes"),
              // cancelButtonText: this.$t("no"),
              confirmButtonColor: "#6418C3",
              // cancelButtonColor: "#6418C3",

              className: "APKUpdateButton",
              closeOnClickOverlay: false,
              getContainer: "#app",
            })
            .then(() => {
              this.toDownload();
            });
        } else {
          this.$dialog
            .confirm({
              title: this.$t("appUpdatePrompt"),
              theme: "round-button",
              confirmButtonText: this.$t("no"),
              cancelButtonText: this.$t("yes"),
              confirmButtonColor: "#2A283D",
              cancelButtonColor: "#6418C3",
              className: "confirmLeft",
              closeOnClickOverlay: false,
              getContainer: "#app",
            })
            .then(() => {})
            .catch(() => {
              this.toDownload();
            });
        }
      }
    },

    // 游客登录
    async guestLoginAction() {
      try {
        await this.$store.dispatch("user/guestLogin", (data) => {
          const { language_id } = data;
          const languageAbbreviation = languageObj[language_id];
          const translations = require("@/locales/" +
            languageAbbreviation +
            ".js").default;
          // 更新翻译配置
          // 更新 VueI18n 实例的翻译配置
          this.$i18n.locale = languageAbbreviation;
          this.$i18n.setLocaleMessage("en", translations);
        });
      } catch (e) {
        console.log(e);
      }
    },
    isPC() {
      const userAgentInfo = navigator.userAgent;
      const Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.includes(Agents[v])) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    async initApp() {
      await this.initChatWS();
      await this.initTotalUnreadCount();
      // await this.getTaskList({
      //   vm: this,
      //   language_id: Number(localStorage.getItem("language_id")),
      //   country_id: Number(localStorage.getItem("country_id")),
      // });
    },
    async checkToken() {
      if (getToken(TOKEN_KEY)) {
        await this.$store.dispatch("user/refreshAccessToken");
      } else {
        await this.guestLoginAction();
      }
    },
    // 全局禁用双击和双指放大事件
    disableZoom() {
      document.addEventListener("dblclick", (e) => {
        e.preventDefault();
      });
      document.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    },
    // 初始化事件
    initEvent() {
      // 全局错误捕获
      window.onerror = function (message, source, lineno, colno, error) {
        console.error(error);
        return true;
      };
      // 禁用双击和双指放大事件
      this.disableZoom();
      // 初始化尺寸大小
      this.initSize();
      window.addEventListener("resize", this.initSize);
      document.addEventListener("visibilitychange", async () => {
        // 请求json文件
        if (document.visibilityState === "hidden") {
          sessionStorage.setItem("clientHideTime", new Date());
          this.closeTimer = setTimeout(() => {
            if (Object.keys(this.chatWSObj).length !== 0) {
              this.disconnectChatWS({
                code: MANUAL_CLOSE,
                reason: "浏览器视图隐藏主动关闭",
              });
            }
          }, 2 * 60 * 1000);
        } else {
          if (localStorage.getItem("webClientDevice") === "Android") {
            await checkAPKVersion((data) => {
              this.checkAPK(data);
            });
          }
          await checkSystemVersion();
          if (this.closeTimer) clearTimeout(this.closeTimer);
          const hideTime = sessionStorage.getItem("clientHideTime");
          if (hideTime) {
            const time = new Date() - new Date(hideTime);
            if (time >= 2 * 60 * 1000) {
              // this.$router.go(0);
              if (Object.keys(this.chatWSObj).length !== 0) {
                this.disconnectChatWS({
                  code: MANUAL_CLOSE,
                  reason: "浏览器视图隐藏主动关闭",
                });
              }
              await this.checkToken();
              await this.initApp();
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
}
.APKUpdateButton {
  .van-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
@/assets/values/values
