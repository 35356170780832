<template>
  <!-- 统计列表 最高值/最低值/平均值 -->
  <div class="OddsStatisticsList">
    <!-- 侧边栏 -->
    <div class="sideBar">
      <div
        class="sideBarTitle"
        :class="{ 'bgc-1c1a32': index % 2 === 0 }"
        v-for="(title, index) in statisticsListSideBar"
        :key="index"
        @click="toOddsDetails"
      >
        {{ $t(title) }}
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <!-- 标题栏 -->
      <div class="contentTitleBar">
        <div
          class="title"
          v-for="(title, index) in winDrawLoseTitles"
          :key="index"
        >
          {{ $t(title) }}
        </div>
      </div>
      <!-- 指数内容 -->
      <div class="oddsContent">
        <!-- 统计项 -->
        <div
          class="oddsContentItem"
          :class="{ 'bgc-1c1a32': index % 2 === 0 }"
          v-for="(item, index) in list"
          :key="index"
          @click="toOddsDetails"
        >
          <!-- 初始 -->
          <div class="initial moment">
            <span class="label spanItem">{{ $t("initial") }}</span>
            <span class="oddsNum spanItem">{{
              sport_type_id === 1
                ? item?.orignMaster || "-"
                : item.orignGuest || "-"
            }}</span>

            <span class="oddsNum spanItem" v-if="sport_type_id === 1">{{
              item?.orignEquivalent || "-"
            }}</span>
            <span class="oddsNum spanItem">{{
              sport_type_id === 1
                ? item?.orignGuest || "-"
                : item?.orignMaster || "-"
            }}</span>
          </div>
          <!-- 赛前 -->
          <div class="immediately moment" v-show="columnsed === $t('preMatch')">
            <span class="label spanItem">{{ $t("preMatch") }}</span>
            <span class="oddsNum spanItem">{{
              sport_type_id === 1
                ? item.pregameMaster || "-"
                : item.pregameGuest || "-"
            }}</span>
            <span class="oddsNum spanItem" v-if="sport_type_id === 1">{{
              item.pregameEquivalent  || "-"
            }}</span>
            <span class="oddsNum spanItem">{{
              sport_type_id === 1
                ? item.pregameGuest || "-"
                : item.pregameMaster || "-"
            }}</span>
          </div>
          <!-- FIXME: 即时 -->

          <div class="immediately moment" v-show="columnsed === $t('ongoing')">
            <span class="label spanItem">{{ $t("ongoing") }}</span>
            <span class="oddsNum spanItem">{{
              sport_type_id === 1
                ? item.timelyMaster || "-"
                : item.timelyGuest || "-"
            }}</span>
            <span class="oddsNum spanItem" v-if="sport_type_id === 1">{{
              item.timelyEquivalent || "-"
            }}</span>
            <span class="oddsNum spanItem">{{
              sport_type_id === 1
                ? item.timelyGuest || "-"
                : item.timelyMaster || "-"
            }}</span>
          </div>

          <!-- <div class="preMatch moment" v-show="columnsed === $t('preMatch')">
            <span class="label spanItem">{{ $t("preMatch") }}</span>
            <span
              class="oddsNum spanItem"
              :class="{
                green:
                  getOddColor(item['initial'], item['preMatch'], iIndex) ===
                  'green',
                red:
                  getOddColor(item['initial'], item['preMatch'], iIndex) ===
                  'red',
              }"
              v-for="(odd, iIndex) in item['preMatch']"
              :key="iIndex"
              >{{ odd ? odd : "-" }}</span
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "OddsStatisticsList",
  // [即时/赛前]选择器-选中的选项
  props: {
    columnsed: {
      type: String,
    },
    winDrawLoseTitles: {
      type: Array,
      required: true,
    },
    // 体育id
    sport_type_id: {
      type: Number,
      require: true,
    },
    statisticsList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      statisticsListSideBar: ["max", "min", "average"], // 统计数据列表的侧边栏: [最高值, 最低值, 平均值]
      dataObj: {},
    };
  },
  computed: {
    list() {
      return this.statisticsList;
    },
  },
  mounted() {
    // console.log('statisticsList', this.list)
  },
  methods: {
    /**
     * 通知ExponentCom跳转指数详情
     */
    toOddsDetails() {
      this.$emit("toOddsDetails");
    },
  },
};
</script>

<style></style>
