<template>
  <div class="liveRoomVideo">
    <div class="video-container" ref="video">
      <!-- 视频 -->
      <div class="video-containers" @touchend="clickFormpParent">
        <!-- video.js -->
        <video
          id="myVideo"
          class="video-js"
          playsinline
          ref="videoElement"
        ></video>
        <div class="refresh-button" v-if="showRefreshButton">
          <div class="text">{{ $t("videoErrorPrompt") }}</div>
          <div class="button">
            <van-icon
              name="replay"
              class="icon"
              color="rgba(255, 255, 255, 0.6)"
            />
            <div @click.stop="clickRefresh">{{ $t("clickRetryPrompt") }}</div>
          </div>

          <div class="button">
            <van-icon
              name="bar-chart-o"
              class="icon"
              color="rgba(255, 255, 255, 0.6)"
            />
            <div @click.stop="clickMoreLine">{{ $t("switchLines") }}</div>
          </div>

        </div>

        <div class="loading-button" v-if="showLoading">
          <van-loading class="icon" color="rgba(255, 255, 255, 0.6)" />
        </div>

        <!-- 控件区域 -->
        <div class="control" v-show="false">
          <div class="controlButton">
            <!-- 播放 -->
            <div
              class="icon videoPlayStatus"
              :style="{
                backgroundImage:
                  'url(' +
                  (isPlaying ? iconVideoPlaying : iconVideoPause) +
                  ')',
              }"
              @click.stop="togglePlay"
            ></div>

            <div class="controlRight">
              <!-- 声音 -->
              <div class="volume-control">
                <div
                  ref="voice"
                  class="icon voice"
                  :style="{
                    backgroundImage:
                      'url(' +
                      (isMuted ? iconVideoMute : iconVideoVolume) +
                      ')',
                  }"
                  @click.stop="toggleVolumeSlider"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 清晰度选项弹窗 -->
      <div v-if="showOptions" class="quality-options">
        <div
          class="option"
          v-for="(item, key) in lineUrls"
          :key="key"
          @click.stop="changeQuality(item)"
        >
          <div>{{ item.clarity }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-flvjs-es6";
import "videojs-contrib-hls";
// import { getJudeRules, sendJudePoint } from "@/services/api/Video";
// 卡顿最大重试数是 3
const MAX_RETRY_TIMES = 3;
export default {
  name: "VideoCom",
  props: {
    currentVideo: Object,
    messages: Array,
    bangsStatus: Function,
    liveLines: Array,
  },
  data() {
    return {
      sliderVisible: false,
      showOptions: false, //清晰度开关
      videoKey: "",
      videoUrl: "",
      isPlaying: true, //播放开关
      isMuted: true, // 初始状态为静音
      player: null, // 初始化播放器实例
      nowVolume: 0,
      isFullScreen: true, //全屏开关
      isLoading: true, //视频加载
      // 迁移
      lineUrls: "",
      iconVideoPlaying: require("@/assets/icons/LiveRoom/icon-video-playing.svg"), // 视频播放图标
      iconVideoPause: require("@/assets/icons/LiveRoom/icon-video-pause.svg"), // 视频暂停图标
      iconVideoVolume: require("@/assets/icons/LiveRoom/icon-video-volume.svg"), // 音量图标
      iconVideoMute: require("@/assets/icons/LiveRoom/icon-video-mute.svg"), // 静音图标
      iconVideoFullScreen: require("@/assets/icons/LiveRoom/icon-video-fullScreen.svg"), // 全屏图标
      hideControlsTimeout: null,
      waitingTimer: null,
      loadFailCount: 0,
      showRefreshButton: false,
      showErrorButton: false,
      rulesList: [],
      videoError: require("@/assets/icons/Video/videoErroIcon.svg"),
      hasChangeLine: false,
      showLoading: false,
    };
  },

  watch: {
    currentVideo: {
      handler: async function (newVal) {
        this.initVideo(newVal);
        this.resetData();
      },
    },
  },

  async mounted() {
    // await this.getErrorRules();
    this.initVideo(this.currentVideo);
    this.initEventListener();
  },

  beforeDestroy() {
    if (this.waitingTimer) {
      clearTimeout(this.waitingTimer);
      this.waitingTimer = null;
    }
    // 销毁播放器实例
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    hideSpinner() {
      this.showLoading = false;
    },
    showSpinner() {
      this.showLoading = true;
    },
    resetData() {
      if (this.waitingTimer) {
        clearTimeout(this.waitingTimer);
        this.waitingTimer = null;
      }
      this.loadFailCount = 0;
      this.showRefreshButton = false;
    },
    async clickRefresh() {
      this.showRefreshButton = false;
      this.showSpinner();
      this?.player && this.player?.load();
    },

    //loading加载
    handleVideo() {
      this.isLoading = this.$refs.videoElement.paused;
    },
    updateVideoUrl(item) {
      this.videoUrl = item.url;
      this.player.src({
        src: this.videoUrl,
        type: "application/x-mpegURL",
      });
      this.player?.load(); // 重新加载视频
      this.handleVideo();
    },
    //更改清晰度
    changeQuality(item) {
      this.updateVideoUrl(item);
      this.showOptions = false;
    },
    showQualityOptions() {
      //清晰度弹窗
      this.showOptions = !this.showOptions;
    },
    // 打开个切换路线
    clickMoreLine(){
      this.$emit("showMoreStreams");
    },
    //播放开关
    togglePlay() {
      this.isPlaying = !this.isPlaying;
      const videoElement = document.querySelector("video");
      if (this.isPlaying) {
        videoElement.play().catch((err) => {
          console.log(err);
        });
      } else {
        videoElement.pause();
      }
    },
    //音量调节开关
    toggleVolumeSlider() {
      this.isMuted = !this.isMuted;
      if (this.isMuted) {
        this.player.volume(0); // 设置声音
        this.player.muted(true); // 取消静音
      } else {
        this.player.volume(1); // 设置为静音状态
        this.player.muted(false); // 静音
      }
    },
    // 控制父级刘海状态
    clickFormpParent() {
      this.$emit("bangsStatus");
    },
    getType(videoKey) {
      let videoUrl = videoKey;
      let videoType;
      let sourceType;
      videoType = videoUrl.split(".").pop().toLowerCase();
      if (videoType.indexOf("?") !== -1) {
        videoType = videoType.split("?")[0];
      }
      switch (videoType) {
        case "flv":
          sourceType = "video/x-flv";
          break;
        case "m3u8":
          sourceType = "application/x-mpegURL";
          break;
        case "mp4":
          sourceType = "video/mp4";
          break;
        default:
          console.error("Unsupported video format: " + videoType);
          sourceType = videoType;
          return;
      }

      return { sourceType, videoType };
    },
    initVideo(currentVideo) {
      try {
        const { line_urls } = currentVideo || {};
        this.lineUrls = line_urls;
        if (line_urls?.length) {
          this.videoKey = line_urls[line_urls?.length - 1].url;
        }
        // mock
        // this.videoKey = "https://yunqivedio.alicdn.com/2017yq/v2/0x0/96d79d3f5400514a6883869399708e11/96d79d3f5400514a6883869399708e11.m3u8";
        // this.videoKey =
        //   "https://live.kantai8.com/live/sd-1-3945926_ftNB2-720p-br.flv?auth_key=1708675438-0-0-515b31118a15607f6e8eddd681d7c16a";
        // 初始化 video.js 播放器
        const videoElement = this.$refs.videoElement;
        const { sourceType } = this.getType(this.videoKey);
        this.player = videojs(videoElement, {
          // 播放器的大小动态地适应父容器 同时保持视频内容的原始宽高比
          fluid: true,
          autoplay: "muted",
          controls: true,
          techOrder: ["html5", "flvjs"],
          flvjs: {
            mediaDataSource: {
              isLive: true,
              cors: false,
              withCredentials: false,
            },
          },
        });
        this.player.src({
          src: this.videoKey,
          type: sourceType,
        });
      } catch (error) {
        console.log(error);
      }
    },
    initEventListener() {
      // 删除错误显示组件
      this.player.on("loadeddata", () => {
        // 检测到视频数据加载完成 7秒后打开声音
        setTimeout(() => {
          if (this?.player) this.$refs.voice?.click();
        }, 1000 * 7);
      });

      this.player.on("error", () => {
        const error = this.player.error();
        // 隐藏错误提示
        if (
          error.code === 4 &&
          error.message ===
            "The media could not be loaded, either because the server or network failed or because the format is not supported."
        ) {
          // 自定义错误提示
          // this.player.errorDisplay.contentEl_.innerHTML = this.$t(
          //   "InternetErrorPrompt"
          // );
          this.player.errorDisplay.contentEl_.innerHTML = "";
        } else {
          this.player.errorDisplay.hide();
        }
        // 当视频加载失败时，增加加载失败计数器
        this.loadFailCount++;
        if (this.loadFailCount > MAX_RETRY_TIMES) {
          this.hideSpinner();
          this.showRefreshButton = true;
        }
        console.log("video player error", error.code, error.message);
      });
      this.player.on("playing", () => {
        this.hideSpinner();
        this.resetData();
      });
      this.player.on("waiting", () => {
        this.showSpinner();
        this.waitingTimer = setTimeout(() => {
          // 卡顿超过10秒，重新加载视频
          if (this.loadFailCount < MAX_RETRY_TIMES) {
            if (this.player) this?.player?.load();
            if (this.player) this.$refs.voice?.click();
          } else {
            this.hideSpinner();
            this.showRefreshButton = true;
          }
        }, 10 * 1000);
      });
      // 解决全屏切换时视频停止
      this.player.on("fullscreenchange", () => {
        console.log("fullscreenchange");
        if (this.player.isFullscreen()) {
          console.log("full");
        } else {
          // 在退出全屏时，定时器保证完全退出，重新加载视频
         setTimeout(() => {
          this.player.play().catch((err) => {
            console.log("err", err);
          });
         }, 500);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vjs-tech {
  height: auto;
}
.hide-spinner .vjs-loading-spinner {
  display: none !important;
}

@import "@/components/VideoCom/Index.scss";
</style>
