import request  from "@/services/axios/commonRequest";
import requestV2  from "@/services/axios/commonRequestV2";
import QS from "qs"; //数据序列化

// 登记新用户
export function userStatistics(params) {
    return requestV2({
        url: "/user_access_statistics",
        method: "post",
        params,
    });
}

// 获取个人信息
export function getUser(params) {
    return request({
        url: '/users/me',
        method: 'get',   
        params,
    })
}
// 修改个人消息
export function updateUser(data) {
    return request({
        url: '/users/me',
        method: 'put',
        data
    })
}
//语言切换
export function languageSwitch(data) {
    return request({
        url: '/translations/' + data,
        method: 'get',
    })
}

// 获取语言
export function languageGet() {
    return request({
        url: '/languages',
        method: 'get',
    })
}

//获取国家及对应语言信息
export function countryLanguages() {
    return request({
        url: '/country-languages',
        method: 'get',
    })
}

// 注册邮箱验证码
export function registerEmailCode(data) {
    return request({
        url: '/register-email-code',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        data: QS.stringify(data),

    })
}
// 注册手机验证码
export function registerPhoneCode(data) {
    return request({
        url: '/register-phone-code',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        data: QS.stringify(data),

    })
}
// 获取邮箱验证码
export function emailCode(data) {
    return request({
        url: '/email-code',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        data: QS.stringify(data),

    })
}
// 获取手机验证码
export function phoneCode(data) {
    return request({
        url: '/phone-code',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        data: QS.stringify(data),

    })
}

//登录
export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),
        // data: new URLSearchParams(data).toString()

    })
}
// 获取登录状态，token是否过期
export function getLoginStatus() {
    return request({
        url: "/login-status/me",
    })
}
// 更新token
export function refreshTokenAPI(data) {
    return request({
        url: '/refresh-token',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
},
        data: QS.stringify(data),
        // data: new URLSearchParams(data).toString()
    })
}
// 游客登陆
export function guestLogin(data) {
    return request({
        url: '/guest-login',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),
        // data: new URLSearchParams(data).toString()
    })
}
//注册
export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),

    })
}
// 快速注册
export function quickRegister(data) {
    return request({
        url: '/quick-register',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),

    })
}

// 修改密码
export function modify(data) {
    return request({
        url: '/users/password/modify',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),

    })
}
// 修改邮箱
export function emailModify(data) {
    return request({
        url: '/modify-email',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),
    })
}
// 修改手机
export function phoneModify(data) {
    return request({
        url: '/modify-phone',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),

    })
}
// 修改密码
export function modifyPassword(data) {
    return request({
        url: '/modify-password',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Use-Encryption': 1,
          },
        data: QS.stringify(data),

    })
}

// encrypt-test
// export function encryptTest(){
//     return request({
//         url: '/encrypt-test',
//         method: 'post',
//         data: {
//             foo: "bar"
//         }
//     })
// }