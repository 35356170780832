const state = {
  pageStatus: {
    activedTab: "streamerPlan", // 一级tabs选中tab
    planSportType: "FOOTBALL", // 方案的选中体育类型
  },
};

const mutations = {
  // 设置页面状态
  SET_ANCHORINTRODUCTION_STATUS(state, { activedTab, planSportType }) {
    if (activedTab !== undefined) {
      state.pageStatus.activedTab = activedTab;
    }
    if (planSportType !== undefined) {
      state.pageStatus.planSportType = planSportType;
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
