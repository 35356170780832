/**
 * @file 越南地区 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "thỏa thuận người dùng",
  privacyPolicy: "chính sách bảo mật",
  termsOfService: "điều khoản phục vụ",
};

const AnchorAvatarAndName = {
  live: "LIVE",
  offLine: "OFF",
};

const AnchorInfoCard = {
  live: "LIVE",
  alreadyPrompt: "đã phát sóng",
  watch: "đi quang sát",
  follow: "theo dõi",
  following: "Đã thích",
  subscribe: "đăng ký",
  streamerSchedule: "lịch đấu phát chính",
  noDataPrompt: "tạm thời chưa có dữ liệu",
  unfollowPrompt: "bạn có chắc chắn muốn hủy theo dõi không?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  hours: "giờ",
  mins: "phút",
};

const AnchorInformation = {
  recommendedLiveStreams: "đề xuất trực chiếu",
  noDataPrompt: "tạm thời chưa có dữ liệu",
};

const BasketballScoreIndex = {
  details: "chi tiết",
  over: "Hoàn thành",
  pointsDifference: "chênh lệch",
  totalScore: "tổng",
  live: "LIVE",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
};

const BasketballSmallBig = {
  details: "chi tiết",
  over: "Hoàn thành",
  pointsDifference: "chênh lệch",
  totalScore: "tổng",
  live: "LIVE",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
  overIndex: "O",
  underIndex: "U",
};

const BindPhoneDialog = {
  bindPhonePrompt:
    "nhằm đảm bảo an toàn cho tài khoản bạn; vui lòng liên kết số điện thoại để nhận dịch vụ tốt hơn",
  goBind: "liên kết",
};

const BottomNavigationBar = {
  home: "Trang chủ",
  matches: "thi đấu",
  recommend: "gợi ý",
  chat: "trò chuyện",
  personal: "cá nhân",
  discovery: "phát hiện",
  money: "tiền bạc",
};

const ChatInterface = {
  noCsPrompt: "tạm thời không thể gửi tin nhắn",
  uploadRulePrompt: "vui lòng tải lên hình ảnh ở định dạng jpg, jpeg, png",
  uploadSizePrompt: "kích thước hình ảnh không thể vượt quá 10M",
  sendError: "Gửi thất bại",
  picSendError: "Gửi ảnh không thành công",
  InternetErrorPrompt: "lỗi mạng",
  bannedSendPrompt: "Ngài đã cấm.",
};

const ChatList = {
  noNewPrompt: "tạm thời không có tin nhắn",
  logInPrompt: "đăng nhập sẽ thấy rõ nội dung; xin vui lòng đăng nhập",
  logIn: "đăng nhập",
  group: "Nhóm",
};

const ChatListItem = {
  withdrawMessage: "đã thu hồi 1 tin nhắn",
  bannedPrompt: "bị cấm nói",
  unBanPrompt: "đã được gỡ hạn chế",
  joinGroupPrompt: "đã gia nhập nhóm",
  leftGroupPrompt: "đã rời khỏi nhóm",
  picture: "những bức ảnh",
  link: "liên kết",
  emoji: "Emoji",
  addedFriendPrompt: "đã được kết bạn",
};

const CompetitionList = {
  logInPrompt: "đăng nhập sẽ thấy rõ nội dung; xin vui lòng đăng nhập",
  logIn: "đăng nhập",
  today: "hôm nay",
  Monday: "thứ hai",
  Tuesday: "thứ ba",
  Wednesday: "thứ tư",
  Thursday: "thứ năm",
  Friday: "thứ sáu",
  Saturday: "thứ bảy",
  Sunday: "chủ nhật",
  noGames: "Chưa có trò chơi nào",
  toCollapse: "Bấm để thu gọn",
  toExpand: "Bấm để mở rộng",
};

const CompetitionListItem = {
  details: "chi tiết",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
};

const CompetitionMode = {
  matchMode: "Chế độ thi đấu",
  confirm: "xác nhận",
  cancel: "hủy bỏ",
};

const EmojiCom = {
  logAndSign: "đăng nhập / đăng ký ngay",
  yes: "có",
  no: "không",
};

const ExponentCom = {
  company: "công ty",
  initial: "ban đầu",
  ongoing: "lập tức",
  preMatch: "trước trận đấu",
  noDataPrompt: "tạm thời chưa có dữ liệu",
  handicap: "chấp banh",
  WinDrawLose: "thắng, thua, hòa",
  totalGoals: "tổng số bàn thắng",
  cornerKick: "CK",
  pleaseSelect: "vui lòng chọn",
  confirm: "xác nhận",
  cancel: "hủy bỏ",
  FH: "HT",
  FT: "toàn trận",
  pinnedPrompt: "đã ghim trên nhóm chát trực tuyến",
};

const FollowedStreamers = {
  live: "LIVE",
  follow: "theo dõi",
  following: "Đã thích",
  chat: "trò chuyện",
  noDataPrompt: "tạm thời chưa có dữ liệu",
  unfollowPrompt: "bạn có chắc chắn muốn hủy theo dõi không?",
  yes: "có",
  no: "không",
  InternetErrorPrompt: "lỗi mạng",
};

const FootballCornerIndex = {
  details: "chi tiết",
  over: "Hoàn thành",
  FH: "HT",
  cornerKick: "CK",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
};

const FootballScoreIndex = {
  details: "chi tiết",
  over: "Hoàn thành",
  FH: "HT",
  cornerKick: "CK",
  live: "LIVE",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
};

const FootballScoreItem = {
  details: "chi tiết",
  over: "Hoàn thành",
  FH: "HT",
  cornerKick: "CK",
  live: "LIVE",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
};

const GoalToast = {
  goal: "ghi bàn",
};

const LiveRecommend = {
  noDataPrompt: "tạm thời chưa có dữ liệu",
  recoverLosses: "hồi phục từ thua lỗ",
  winningBetSlip: "phiếu cược thắng",
};

const LiveRecommendList = {
  victoryOrDefeat: "thắng thua",
};

const MatchCardList = {
  details: "chi tiết",
};

const MatchStatus = {
  noDataPrompt: "tạm thời chưa có dữ liệu",
};

const MoreStreams = {
  switchLines: "chuyển kênh",
  cancel: "hủy bỏ",
  currentStreamer: "hiện đang phát sóng",
  live: "LIVE",
  offLine: "OFF",
  followersCount: "người theo dõi",
  otherStreamersPrompt:
    "hiện tại ngoài trận đấu chính còn có các trận khác đang phát sóng",
  watch: "đi quang sát",
};

const NewMatchStatus = {
  team: "Team",
  halfTimeScore: "tỷ số hiệp một",
  shotOnTarget: "sút trúng đích",
  missedShot: "sút hỏng",
  attack: "tấn công",
  dangerousAttack: "tấn công nguy hiểm",
  number3PointGoals: "bàn thắng 3 điểm",
  number2PointGoals: "bàn thắng 2 điểm",
  penaltyKickGoals: "lần ném phạt thành công",
  freeThrowPercentage: "tỷ lệ ném phạt thành công%",
  numberOfPausesRemaining: "số lần tạm dừng còn lại",
  numberOfFouls: "số lần phạm lỗi",
  totalNumberOfPauses: "tổng số lần tạm dừng",
  threePointShot: "bàn thắng 3 điểm",
  twoPointShot: "bàn thắng 2 điểm",
  shoot: "cú sút",
  penalty: "lần ném phạt",
  firstHalf: "hiệp một",
  secondHalf: "hiệp hai",
  redCard: "Thẻ đỏ",
  yellowCard: "Thẻ vàng",
};

const NoData = {
  noDataPrompt: "tạm thời chưa có dữ liệu",
};

const NoticeMessage = {
  addedFriendPrompt: "đã được kết bạn",
  withdrawMessage: "đã thu hồi 1 tin nhắn",
  withdrawMessageByAdmin: "đã bị quảng trị viên thu hồi 1 tin nhắn",
  joinGroupPrompt: "đã gia nhập nhóm",
  leftGroupPrompt: "đã rời khỏi nhóm",
  removeGroupPrompt: "đã bị quảng trị viên loại khỏi nhóm",
  bannedPrompt: "bị cấm nói",
  unBanPrompt: "đã được gỡ hạn chế",
};

const OddsStatisticsList = {
  initial: "ban đầu",
  ongoing: "lập tức",
  preMatch: "trước trận đấu",
  HW: "chủ",
  Dr: "tỷ số hòa",
  AW: "khách",
  max: "hạn mức cao nhất",
  min: "hạn mức thấp nhất",
  average: "mức trung bình",
};

const RecommendCom = {
  live: "LIVE",
  noDataPrompt: "tạm thời chưa có dữ liệu",
};

const RecommendCompetitions = {
  today: "hôm nay",
  winsInARow: "thắng liên tiếp",
  noLivePrompt: "chưa có phát sóng trực tiếp",
};

const RecommendStreamerList = {
  noDataPrompt: "tạm thời chưa có dữ liệu",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "trong vòng 1 tuần",
  victoryOrDefeat: "thắng thua",
  check: "xem",
  chat: "trò chuyện",
  quarterlyProfit: "lợi nhuận hàng quý",
};

const RecommendStreamers = {
  recommendedByExperts: "các chuyên gia khuyên dùng",
  winsInARow: "thắng liên tiếp",
  changeBatch: "chuyển đổi",
  follow: "theo dõi",
  following: "Đã thích",
  unFollowing: "hủy",
  noDataPrompt: "tạm thời chưa có dữ liệu",
  logAndSign: "đăng nhập / đăng ký ngay",
  yes: "có",
  no: "không",
  InternetErrorPrompt: "lỗi mạng",
  unfollowPrompt: "bạn có chắc chắn muốn hủy theo dõi không?",
};

const RecordChart = {
  record: "thành tích",
  allWaysToPlay: "toàn bộ cách chơi",
  fromFarToNear: "từ xa đến gần",
  lastTenGames: "10 trận gần đây:",
  longestConsecutiveHit: "kết nối lâu nhất",
  hitRate: "tỷ lệ trúng",
  currentPropmt: "trận gần nhất",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "lưu ý khi điền thông tin quốc gia và ngôn ngữ cho tương ứng với bạn; thông tin này được gắn kết với tài khoản bạn mãi mãi và có thể sẽ ảnh hưởng đến những tính năng sau này của bạn",
  getUserInfoErrorPrompt: "không thẻ lấy được thông tin người dùng",
  getAreaAndLanguageErrorPrompt: "không thể tải được ngôn ngữ khu vực",
};

const ScheduleCom = {
  startTime: "thời gian bắt đầu",
};

const SchemeCard = {
  planList: "liệt kê",
  release: "thông báo",
  draw: "D",
  Win: "W",
  halfWin: "1/2W",
  lost: "L",
  halfLost: "1/2L",
  all: "toàn bộ",
  guaranteedCompensation: "hoàn vốn",
  free: "miễn phí",
  toll: "thu phí",
  victoryOrDefeat: "thắng thua",
};

const SquareCom = {
  watchPrompt: "khán giả đang theo dõi",
  startedPrompt: "phút trước vừa phát sóng",
  chat: "trò chuyện",
  sendMessageNeedLoginPrompt: "vui lòng gửi tin nhắn sau khi đăng nhập",
  assistant: "quảng trị viên",
  audience: "người dùng",
  newMessage: "thông điệp",
  confirmSend: "Xác nhận gửi",
  cancelSend: "Hủy gửi",
};

const StatisticalChart = {
  hitRate: "tỷ lệ trúng",
};

const SwitchLines = {
  switchLines: "chuyển kênh",
  cancel: "hủy bỏ",
  currentStreamer: "hiện đang phát sóng",
  live: "LIVE",
  offLine: "OFF",
  followersCount: "người theo dõi",
  otherStreamersPrompt:
    "hiện tại ngoài trận đấu chính còn có các trận khác đang phát sóng",
  watch: "đi quang sát",
  LINE: "Dòng",
  VIP: "VIP",
  OFFICIAL: "Công vụ",
};

const VideoCom = {
  videoErrorPrompt: "Tín hiệu không tốt lắm. Vui lòng thử lại sau.",
  clickRetryPrompt: "Tải lại",
};
const WelcomeCard = {
  join: "Tham gia",
};

//utils
const tools = {
  yesterday: "Hôm qua",
  addedFriendPrompt: "đã được kết bạn",
  withdrawMessage: "đã thu hồi 1 tin nhắn",
  joinGroupPrompt: "đã gia nhập nhóm",
  leftGroupPrompt: "đã rời khỏi nhóm",
  removeGroupPrompt: "đã bị quảng trị viên loại khỏi nhóm",
  bannedPrompt: "bị cấm nói",
  unBanPrompt: "đã được gỡ hạn chế",
};

//页面
const AddressBook = {
  search: "tìm kiém",
  addFriend: "thêm bạn bè",
  myGroupChat: "cuộc trò chuyện nhóm của tôi",
  friends: "bạn bè",
  delete: "xóa bỏ",
  noFriendsPrompt: "tạm thời không có bạn bè",
  nameAndIdSearch: "nhập tên tài khoản",
  searchResults: "kết quả tìm kiếm",
  notFoundPrompt: "không tìm thấy người dùng",
  add: "thêm",
  deleteFriendPrompt: "bạn có chắc chắn muốn xóa bạn không?",
  yes: "có",
  no: "không",
  addFriendSuccessPrompt: "Thêm bạn bè thành công",
  deleteFriendSuccessPrompt: "Xóa bạn bè thành công",
  InputSearchPrompt: "Vui lòng nhập nội dung",
};

const GroupChat = {
  groupChat: "nhóm chat",
  groupSearch: "nhập tên nhóm chat",
  noGroupPrompt: "tạm thời không có nhóm chat",
  chat: "trò chuyện",
  InputSearchPrompt: "Vui lòng nhập nội dung",
};

const GroupAdministrator = {
  administrator: "quản trị viên",
  groupOwner: "người sở hữu",
};

const GroupAnnouncement = {
  groupAnnouncement: "thông báo nhóm",
  groupOwner: "người sở hữu",
  administrator: "quản trị viên",
};

const GroupDetail = {
  groupDetail: "Chi tiết nhóm",
  groupMembers: "thành viên nhóm",
  groupName: "tên nhóm",
  groupDescription: "miêu tả nhóm",
  groupOwner: "người sở hữu",
  administrator: "quản trị viên",
  administratorList: "danh sách quảng trị viên",
  groupAnnouncement: "thông báo nhóm",
  groupFiles: "tệp nhóm",
  exitGroup: "rời khỏi nhóm",
  exitGroupPrompt: "bạn có chắc chắn muốn ròi khỏi nhóm?",
  yes: "có",
  no: "không",
  leftGroupSuccessPrompt: "rời khỏi nhóm thành công",
};

const GroupFile = {
  groupFiles: "tệp nhóm",
  fileSearch: "nhập tên tệp",
  today: "hôm nay",
  multimedia: "đa phương tiện",
  file: "tệp",
  noFilesPrompt: "tạm thời không có tệp",
};

const GroupMembers = {
  groupMembers: "thành viên nhóm",
  nameAndIdSearch: "nhập tên tài khoản",
};

const Chat = {
  chat: "trò chuyện",
  nameAndIdSearch: "nhập tên tài khoản",
  contacts: "danh bạ",
  InputSearchPrompt: "Vui lòng nhập nội dung",
  deleteContactPrompt: "Xác nhận xóa cuộc trò chuyện",
};

const Competition = {
  all: "toàn bộ",
  ongoing: "lập tức",
  popular: "kinh điển",
  liveCompetition: "phát sóng chính",
  schedule: "lịch đấu",
  results: "kết quả trận đấu",
  subscribed: "đăng đăng ký",
  goal: "ghi bàn",
  today: "hôm nay",
  Monday: "thứ hai",
  Tuesday: "thứ ba",
  Wednesday: "thứ tư",
  Thursday: "thứ năm",
  Friday: "thứ sáu",
  Saturday: "thứ bảy",
  Sunday: "chủ nhật",
};

const CompetitionDetails = {
  subscribe: "đăng ký",
  subscribed: "đăng đăng ký",
  matchStreamerPrompt: "trận phát sóng chính",
  live: "LIVE",
  noDataPrompt: "tạm thời chưa có dữ liệu",
  noStreamersPrompt: "giải đấu này tạm ko trận nào phát sóng",
  alreadyStreamerPrompt:
    "trận phát sóng chính đã bắt đầu; có muốn đi xem không?",
  yes: "có",
  no: "không",
  liveStream: "phát sóng trực tiếp",
  matchStatus: "tình hình trận đấu",
  odds: "mục lục",
  unSubscribePrompt: "có phải xác nhận hủy đăng ký?",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
};

const OddsDetails = {
  company: "công ty",
  handicap: "chấp banh",
  WinDrawLose: "thắng, thua, hòa",
  totalGoals: "tổng số bàn thắng",
  cornerKick: "CK",
  HT: "đội nhà",
  Trd: "xu thế",
  AT: "đội khách",
  Tm: "thời gian",
  Sc: "tỷ số",
  HW: "chủ",
  Dr: "tỷ số hòa",
  AW: "khách",
  RR: "tỷ lệ hoàn trả",
  Ov: "tài",
  Un: "xỉu",
};

const Home = {
  logIn: "đăng nhập",
  signUp: "đăng ký",
  popularMatches: "trận đấu kinh điển",
  more: "thêm",
  noDataPrompt: "tạm thời chưa có dữ liệu",
  currentlyLive: "đang phát sóng",
  popularStreamers: "phát sóng kinh điển",
  thinkYouLike: "tôi nghĩ bạn sẽ thích ",
  loadMore: "thêm nhiều hơn ",
  noMoreDataPrompt: "Chưa có thêm dữ liệu",
  addDeskPrompt: "Thêm vào màn hình nền",
  clikToolPrompt: "Nhấp vào thanh công cụ bên dưới",
  andSeletcPrompt: "Và chọn",
  addToScreenPrompt: "Chúng tôi muốn thêm vào màn hình chính của bạn để cung cấp dịch vụ tốt hơn",
  addToScreen: "Thêm vào MH chính",
};

const MoreLives = {
  noDataPrompt: "tạm thời chưa có dữ liệu",
};

const MoreStreamers = {
  streamer: "phát sóng chính",
  streamerSearch: "tên tài khoản máy chủ",
  all: "toàn bộ",
  notFollowing: "chưa theo dõi",
  following: "Đã thích",
  live: "LIVE",
  offLine: "OFF",
  followersCount: "người theo dõi",
  unfollowPrompt: "bạn có chắc chắn muốn hủy theo dõi không?",
  yes: "có",
  no: "không",
  logAndSign: "đăng nhập / đăng ký ngay",
  InternetErrorPrompt: "lỗi mạng",
  InputSearchPrompt: "Vui lòng nhập nội dung",
  dataRequestErrorPrompt: "Lỗi lấy dữ liệu",
};

const LiveRoom = {
  switchLines: "chuyển kênh",
  streamChat: "bộ phận trực tuyến",
  streamerPrivateChat: "trò truyện riêng với máy chủ",
  chat: "trò chuyện",
  odds: "mục lục",
  streamerInformation: "thông tin máy chủ",
  matchStatus: "tình hình trận đấu",
};

const AnchorIntroduction = {
  streamerInformation: "thông tin máy chủ",
  photosWall: "bức tường ảnh",
  seeMore: "xem nhiều hơn",
  streamerPlan: "đề xuất neo",
};

const AnchorPhoto = {
  photosWall: "bức tường ảnh",
  noPhotosPrompt: "tạm thời không có thên hình ảnh",
};

const LogInRegister = {
  logIn: "đăng nhập",
  signUp: "đăng ký",
  getAreaAndLanguageErrorPrompt: "không thể tải được ngôn ngữ khu vực",
  quickRegisterFrequentPrompt: "Yêu cầu thường xuyên, vui lòng thử lại sau",
};

const LoginFn = {
  phone: "số điện thoại",
  email: "hộp thư",
  logInPassword: "mật khẩu đăng nhập",
  rememberPassword: "lưu mật khẩu",
  forgotPassword: "quên mật khẩu",
  logIn: "đăng nhập",
  logInAgreement: "đăng nhập nghĩa là đồng ý",
  userAgreement: "thỏa thuận người dùng",
  and: "và",
  privacyPolicy: "chính sách bảo mật",
  passwordRulePrompt:
    "mật khẩu nhất thiết phải từ 8 ký tự trở lên gồm tổ hợp chữ cái in hoa; chữ thường và con số",
  passwordEmptyPrompt: "mật khẩu không được để trống",
  emailRulePrompt: "vui lòng nhập đúng định dạng hộp thư của bạn",
  emailEmptyPrompt: "địa chỉ hộp thư không được để trống",
  phoneRulePrompt: "vui lòng nhập đúng định dạng số điên thoại",
  phoneEmptyPrompt: "số điện thoại không được để trống",
  phoneNum8: "vui lòng nhập số điện thoại gồm 8 ký tự",
  phoneNum9To10: "vui lòng nhập số điện thoại gồm 9 - 10 ký tự",
  phoneNum9: "vui lòng nhập số điện thoại gồm 9 ký tự",
  enterErrorPrompt: "tên tài khoản hoăc mật khẩu không chính xác",
  loginNoAgreePrompt:
    "bạn cần đồng ý thỏa thuận người dùng và chính sách bảo mật mới được đăng nhập",
  accountAndPassword: "tài khoản",
  account: "tài khoản",
  accountEmptyPrompt: "tài khoản không được để trống",
  accountOrPasswordError: "tài khoản hoặc mật khẩu không đúng",
};

const Register = {
  phone: "số điện thoại",
  email: "hộp thư",
  code: "mã xác minh",
  getCode: "lấy mã",
  logInPassword: "mật khẩu đăng nhập",
  CS: "CSKH",
  signUp: "đăng ký",
  signUpAgreement: "đăng ký nghĩa là đồng ý",
  userAgreement: "thỏa thuận người dùng",
  and: "và",
  privacyPolicy: "chính sách bảo mật",
  contactCSPrompt:
    "đăng ký gặp vấn đề gì sao? bạn liên hệ CSKH để được giải quyết",
  contactCS: "liên hệ CSKH",
  passwordRulePrompt:
    "mật khẩu nhất thiết phải từ 8 ký tự trở lên gồm tổ hợp chữ cái in hoa; chữ thường và con số",
  passwordEmptyPrompt: "mật khẩu không được để trống",
  emailRulePrompt: "vui lòng nhập đúng định dạng hộp thư của bạn",
  emailEmptyPrompt: "địa chỉ hộp thư không được để trống",
  phoneRulePrompt: "vui lòng nhập đúng định dạng số điên thoại",
  phoneEmptyPrompt: "số điện thoại không được để trống",
  codeRulePrompt: "mã xác minh gồm 6 ký tự",
  codeEmptyPrompt: "mã xác minh không được để trống",
  phoneNum8: "vui lòng nhập số điện thoại gồm 8 ký tự",
  phoneNum9To10: "vui lòng nhập số điện thoại gồm 9 - 10 ký tự",
  phoneNum9: "vui lòng nhập số điện thoại gồm 9 ký tự",
  phoneCodeSendSuccessPrompt:
    "mã xác minh đã được gửi thành công, vui lòng kiểm tra tin nhắn trên điện thoại di động của bạn",
  emailCodeSendSuccessPrompt:
    "mã xác minh đã gửi thành công; vui lòng kiểm hộp thư hộp thư",
  phoneCodeSendErrorPrompt:
    "mã xác minh bị từ chối; vui lòng kiểm tra lại số điện thoại",
  emailCodeSendErrorPrompt:
    "mã xác minh bị từ chỗi; vui lòng kiểm tra lại thông tin hộp thư",
  codeSendToMuchPrompt: "đã gửi quá nhiều lần; vui lòng thử lại sau",
  phoneAlreadySignupPrompt:
    "số điện thoại đã đăng ký tài khoản; vui lòng đăng nhập trực tiếp",
  emailAlreadySignupPrompt:
    "địa chỉ hộp thư này đã đăng ký tài khoản; vui lòng đăng nhặp trực tiếp",
  signUpErrorPrompt: "đăng ký bị từ chối ; vui lòng kiểm tra lại thông tin",
  enterErrorPrompt: "tên tài khoản hoăc mật khẩu không chính xác",
  noAgreePrompt:
    "bạn cần đồng ý thỏa thuận người dùng và chính sách bảo mật mới được đăng ký",
  regionAndLanguagePrompt:
    "vui lòng chọn khu vực /ngôn ngữ thực tại của bạn; nếu không sẽ ảnh hưởng đến quá trình vận hành",
  accountAndPassword: "tài khoản",
  quickSignup: "Đăng ký bằng một cú nhấp chuột",
  accountEmptyPrompt: "Tài khoản không thể trống",
  accountRulePrompt: "Số tài khoản phải bao gồm 8-16 chữ cái và số",
  accountAlreadySignupPrompt:
    "Tài khoản này đã được đăng ký, vui lòng đăng nhập trực tiếp",
  tryAgain: "thử lại",
};

const PersonalCenter = {
  logAndSign: "đăng nhập / đăng ký ngay",
  logAndSignPrompt: "mở khóa ngay chức năng chuyên chế và ưu đãi đặc biệt",
  logOut: "đăng xuất",
  myFollow: "theo dõi của tôi",
  mySubscription: "theo dõi đăng ký",

  accountSecurity: "bảo mật tài khoản",
  feedback: "ý kiến phản hồi",
  deviceInformation: "thiết bị",
  aboutUs: "về chúng tôi",
  coin: "tiền điện tử",
  moreServices: "phục vụ nhiều hơn",
  following: "Đã thích",
  subscribed: "đăng đăng ký",
  browser: "Trình duyệt",
  user: "Người dùng",
  profile: "Thông tin",
  nickname: "Biệt danh",
  save: "Lưu",
  change: "thay đổi",
  nicknamePrompt: "Vui lòng nhập nickname",
  avatar: "Avatar",
  changeSuccessPrompt: "thay đổi thành công",
  ballAnimation: "Mục tiêu hoạt hình",
  ballVoice: "Hiệu ứng âm thanh mục tiêu",
  setting: "Thiết lập",
};

const IntegralSystem = {
  Coin: "Vàng",
  goldCoinTask: "Nhiệm vụ vàng",
  PointsDetails: "Chi tiết đồng tiền vàng",
  myCoins: "Tiền vàng của tôi",
  income: "Thu nhập",
  expenditure: "Chi phí",
  signInPrompt: "Đăng nhập liên tục nhận thưởng liên tục",
  toComplete: "hoàn thành",
  toShare: "chia sẻ",
  shareTasks: "chia sẻ nhiệm vụ",
  exclusiveLink: "Liên kết độc quyền",
  copyLink: "sao chép",
  highestWin: "chiến thắng cao nhất",
  day1: "1 ngày",
  day2: "Ngày 2",
  day3: "ngày thứ 3",
  day4: "Ngày 4",
  day5: "Ngày 5",
  day6: "Ngày 6",
  day7: "Ngày 7",
  invitePrompt: "Bất ngờ! Đăng ký và tải ứng dụng của chúng tôi ngay bây giờ để tận hưởng các ưu đãi độc quyền cho người dùng mới! Phát trực tiếp miễn phí các trận đấu, phân tích dữ liệu thời gian thực và dự đoán từ các bình luận viên chuyên nghiệp—tất cả đều trong tầm tay của bạn. Đừng chờ đợi nữa, nhấp vào liên kết để tham gia ngay bây giờ!"
};

const Money = {
  activeCenter: "Trung tâm hoạt động",
  missionCenter: "Trung tâm truyền giáo",
};

const ActivityCenter = {
  toParticipate: "tham gia",
  sportActivity: "hoạt động thể thao",
};

const ActivityDetails = {
  eventDetails: "chi tiết sự kiện",
  dailyBetting: "Daily Kalokalo",
  matchTime: "thời gian sự kiện",
  popularForEvent: "Các lựa chọn phổ biến cho sự kiện này",
  clickToParticipate: "Click để tham gia ngay",
  activityRules: "Quy tắc hoạt động",
  clickToContactParticipate: "Click để tham gia ngay",
  activityObj: "đối tượng hoạt động",
  activityPlatform: "Nền tảng sự kiện",
  activityDuration: "Thời lượng hoạt động",
  activityDemand: "Yêu cầu hoạt động",
  activityTime: "Thời gian hoạt động",
};

const AboutUs = {
  aboutUs: "về chúng tôi",
  platformIntroduction: "giới thiệu nền tảng",
  privacyPolicy: "chính sách bảo mật",
  userAgreement: "thỏa thuận người dùng",
};

const DeviceInfo = {
  deviceInformation: "thiết bị",
  currentTime: "thời gian hiện tại",
  port: "cổng đăng nhập",
  ip: "IP đăng nhập",
  version: "phiên bản hiện tại",
  ipAndPort: "đăng nhập thông tin",
};

const FeedBack = {
  feedback: "ý kiến phản hồi",
  feedbackType: "loại phản hồi",
  productSuggestion: "sản phẩm đề xuất",
  functionalFault: "lỗi chức năng",
  interfaceSuggestion: "đề xuất giao diện",
  accountIssue: "vấn đề tài khoản",
  operationExperience: "trải nghiêm thao tác",
  otherFeedback: "phản hồi khác",
  problemDescription: "mô tả vấn đề",
  submit: "gửi",
  addPhoto: "thêm ảnh",
  uploadPrompt: "xin lỗi; chỉ được tải lên 3 ảnh",
  uploadRulePrompt: "vui lòng tải lên hình ảnh ở định dạng jpg, jpeg, png",
  uploadSizePrompt: "kích thước hình ảnh không thể vượt quá 10M",
  chooseFeedback: "vui lòng chọn loại phản hồi",
  inputQuestionPrompt: "vui lòng nhập câu hỏi",
  submitSuccessPrompt: "gửi thành công",
};

const MySubscription = {
  myFollow: "theo dõi của tôi",
  mySubscription: "theo dõi đăng ký",
  streamer: "phát sóng chính",
  matches: "thi đấu",
};

const PrivacyAgreement = {
  privacyPolicy: "chính sách bảo mật",
};

const TermsofService = {
  termsOfService: "điều khoản phục vụ",
};

const UserAgreement = {
  userAgreement: "thỏa thuận người dùng",
};

const AccountSecurity = {
  accountSecurity: "bảo mật tài khoản",
  phone: "số điện thoại",
  email: "hộp thư",
  bind: "liên kết",
  notBound: "chưa liên kết",
  change: "thay đổi",
  logInPassword: "mật khẩu đăng nhập",
  accountChangePassword:
    "Vui lòng liên kết điện thoại/hộp thư di động của bạn trước",
};

const BindEmail = {
  bindEmail: "liên kết địa chỉ hộp thư",
  email: "hộp thư",
  code: "mã xác minh",
  getCode: "lấy mã",
  confirm: "xác nhận",
  emailRulePrompt: "vui lòng nhập đúng định dạng hộp thư của bạn",
  emailEmptyPrompt: "địa chỉ hộp thư không được để trống",
  codeRulePrompt: "mã xác minh gồm 6 ký tự",
  codeEmptyPrompt: "mã xác minh không được để trống",
  changeSuccessPrompt: "thay đổi thành công",
  changeErrorPrompt: "thay đổi không thành công",
  bindSuccessPrompt: "Ràng buộc thành công",
  bindErrorPrompt: "Ràng buộc không thành công",
  emailCodeSendSuccessPrompt:
    "mã xác minh đã gửi thành công; vui lòng kiểm hộp thư hộp thư",
  emailCodeSendErrorPrompt:
    "mã xác minh bị từ chỗi; vui lòng kiểm tra lại thông tin hộp thư",
  codeSendToMuchPrompt: "đã gửi quá nhiều lần; vui lòng thử lại sau",
  emailAlreadySignupPrompt:
    "địa chỉ hộp thư này đã đăng ký tài khoản; vui lòng đăng nhặp trực tiếp",
  tryAgain: "thử lại",
};

const BindPhone = {
  bindPhone: "liên kêt điện thoại",
  phone: "số điện thoại",
  code: "mã xác minh",
  getCode: "lấy mã",
  confirm: "xác nhận",
  cancel: "hủy bỏ",
  phoneRulePrompt: "vui lòng nhập đúng định dạng số điên thoại",
  phoneEmptyPrompt: "số điện thoại không được để trống",
  codeRulePrompt: "mã xác minh gồm 6 ký tự",
  codeEmptyPrompt: "mã xác minh không được để trống",
  changeSuccessPrompt: "thay đổi thành công",
  changeErrorPrompt: "thay đổi không thành công",
  bindSuccessPrompt: "Ràng buộc thành công",
  bindErrorPrompt: "Ràng buộc không thành công",
  phoneNum8: "vui lòng nhập số điện thoại gồm 8 ký tự",
  phoneNum9To10: "vui lòng nhập số điện thoại gồm 9 - 10 ký tự",
  phoneNum9: "vui lòng nhập số điện thoại gồm 9 ký tự",
  phoneCodeSendSuccessPrompt:
    "mã xác minh đã được gửi thành công, vui lòng kiểm tra tin nhắn trên điện thoại di động của bạn",
  phoneCodeSendErrorPrompt:
    "mã xác minh bị từ chối; vui lòng kiểm tra lại số điện thoại",
  codeSendToMuchPrompt: "đã gửi quá nhiều lần; vui lòng thử lại sau",
  phoneAlreadySignupPrompt:
    "số điện thoại đã đăng ký tài khoản; vui lòng đăng nhập trực tiếp",
  checkSignup: "vui lòng kiểm tra số điện thoại đã được đăng ký chưa",
  tryAgain: "thử lại",
};

const ChangePassword = {
  resetPassword: "đặt lại mật khẩu",
  phone: "số điện thoại",
  email: "hộp thư",
  code: "mã xác minh",
  getCode: "lấy mã",
  logInPassword: "mật khẩu đăng nhập",
  confirm: "xác nhận",
  cancel: "hủy bỏ",
  passwordRulePrompt:
    "mật khẩu nhất thiết phải từ 8 ký tự trở lên gồm tổ hợp chữ cái in hoa; chữ thường và con số",
  passwordEmptyPrompt: "mật khẩu không được để trống",
  emailRulePrompt: "vui lòng nhập đúng định dạng hộp thư của bạn",
  emailEmptyPrompt: "địa chỉ hộp thư không được để trống",
  phoneRulePrompt: "vui lòng nhập đúng định dạng số điên thoại",
  phoneEmptyPrompt: "số điện thoại không được để trống",
  codeRulePrompt: "mã xác minh gồm 6 ký tự",
  codeEmptyPrompt: "mã xác minh không được để trống",
  phoneNum8: "vui lòng nhập số điện thoại gồm 8 ký tự",
  phoneNum9To10: "vui lòng nhập số điện thoại gồm 9 - 10 ký tự",
  phoneNum9: "vui lòng nhập số điện thoại gồm 9 ký tự",
  resetSuccess: "đặt lại mặt khẩu thành công",
  resetError: "mật khẩu đặt lại thất bại; vui lòng kiểm tra lại thông tin",
  checkSignup: "vui lòng kiểm tra số điện thoại đã được đăng ký chưa",
  phoneCodeSendSuccessPrompt:
    "mã xác minh đã được gửi thành công, vui lòng kiểm tra tin nhắn trên điện thoại di động của bạn",
  codeSendToMuchPrompt: "đã gửi quá nhiều lần; vui lòng thử lại sau",
  phoneAlreadySignupPrompt:
    "số điện thoại đã đăng ký tài khoản; vui lòng đăng nhập trực tiếp",
  phoneCodeSendErrorPrompt:
    "mã xác minh bị từ chối; vui lòng kiểm tra lại số điện thoại",
  emailCodeSendSuccessPrompt:
    "mã xác minh đã gửi thành công; vui lòng kiểm hộp thư hộp thư",
  emailCodeSendErrorPrompt:
    "mã xác minh bị từ chỗi; vui lòng kiểm tra lại thông tin hộp thư",
  emailAlreadySignupPrompt:
    "địa chỉ hộp thư này đã đăng ký tài khoản; vui lòng đăng nhặp trực tiếp",
  bindPhoneOrEmail:
    "Vui lòng liên kết số điện thoại di động hoặc địa chỉ email của bạn trước",
  tryAgain: "thử lại",
};

const QuickRegister = {
  downloadScreenshot: "tải ảnh",
  saveAccountAndPassword: "vui lòng bảo mật thông tin tài khoản và mật khẩu",
  suggestSave:
    "Bạn nên chụp ảnh màn hình hoặc sao chép lưu lại trên nhật ký điện thoại",
  account: "tài khoản",
  password: "mật khẩu",
  copyAccountAndPassword: "sao chép mật khẩu tài khoản",
  prompt: "gợi ý",
  sureSave: "Bạn đã lưu lại mật khẩu tài khoản chưa?",
  confirm: "xác nhận",
  cancel: "hủy bỏ",
  copySuccess: "sao chép thành công",
  copyFailure: "sao chép thất bại",
  manualRegister: "Số lần đã đạt giới hạn trên, vui lòng đăng ký thủ công",
};

const Recommend = {
  recommend: "gợi ý",
  InternetErrorPrompt: "lỗi mạng",
  hitOfTheWeek: "trong vòng 1 tuần",
  victoryOrDefeat: "thắng thua",
  check: "xem",
  lastTenGames: "10 trận gần đây:",
  recommendedByExperts: "các chuyên gia khuyên dùng",
  changeBatch: "chuyển đổi",
  follow: "theo dõi",
  following: "Đã thích",
  unFollowing: "hủy",
  all: "toàn bộ",
  guaranteedCompensation: "hoàn vốn",
  free: "miễn phí",
  toll: "thu phí",
  HOT: "Nóng bán",
  HIT: "Trúng rồi.",
  STREAK: "Liên tục hit",
  WEEK_HIT: "Tuần Hits",
  MONTH_HIT: "Lượt truy cập tháng",
  QUARTER_HIT: "Mùa Hits",
  RECENT_10_HIT: "Gần 10 Hits",
};

const RecommendDetail = {
  homePage: "trang chính",
  chat: "trò chuyện",
  follow: "theo dõi",
  following: "Đã thích",
  unFollowing: "hủy",
  followersCount: "người theo dõi",
  planCount: "phương án",
  viewCount: "xem",
  statistics: "thống kê",
  plan: "phương án",
};

const SchemeDetails = {
  planDetails: "chi tiết phương án",
  release: "thông báo",
  chat: "trò chuyện",
  follow: "theo dõi",
  following: "Đã thích",
  unFollowing: "hủy",
  details: "chi tiết",
  analyze: "phân tích",
  title: "tiêu đề",
  privateChatStreamerJoinsGroup:
    "nhắn tin riêng cho phát sóng chính để tham gia nhóm",
  upcoming: "off",
  othersPlan: "lời khuyên khác",
};
const MatchInfo = {
  homeTag: "Đội nhà",
};

const LeagueSelection = {
  matchSorting: "Sắp xếp trận đấu",
  byTime: "theo thời gian",
  byLeague: "theo giải đấu",
  confirm: "xác nhận",
  cancel: "hủy bỏ",
};

const RecommendPublish = {
  enterTeam: "Nhập đội",
  planRelease: "Kế hoạch phát hành",
  predictionTitle:
    "Tiêu đề (tất cả người dùng đều có thể nhìn thấy, vui lòng không tiết lộ kết quả dự đoán)",
    titleEmptyPrompt: "Tiêu đề và nội dung phân tích không được để trống",
    pleaseSelectAtLeastOneGameToPredictResults:
      "Vui lòng chọn ít nhất một trò chơi để dự đoán kết quả",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "bóng đá",
  basketball: "bóng rổ",
  mixed: "tổng hợp",
  setLogInPassword: "cài đặt mật khẩu đăng nhập",
  OCS: "CSKH trực tuyến",
  OCSPrompt: "phục vụ bạn",
  phoneInCorrectPrompt: "số điện thoại không đúng",
  codeInCorrectPrompt: "mã xác minh không đúng",
  emailInCorrectPrompt: "hộp thư không đúng",
  passwordPrompt:
    "vui lòng nhập 8-16ký tự  kết hợp chữ cái tiếng anh và con số",
  newPassword: "nhập mật khẩu mới",
  resetPasswordPrompt: "có phải xác nhận đặt lại mật khẩu mới không?",
  startChatPrompt: "bây giờ có thể bắt đầu trò truyện",
  send: "gửi",
  noFollowPrompt: "tạm thời không có lượt theo dõi",
  feedbackPrompt:
    "xin để lại sự góp ý đáng quý của bạn; chúng tôi sẽ cố gắng cập nhật và cải thiện！",
  bindPhoneByOtherPrompt:
    "số điện thoại này đã được liên kết bởi tài khoản khác",
  bindEmailByOtherPrompt:
    "địa chỉ hộp thư này đã được liên kết bởi tài khoản khác",
  super_manager: "quảng trị viên cấp cao",
  score: "tỷ số",
  scoreIndex: "tỷ lệ kèo",
  cornerKickIndex: "phạt góc",
  close: "đóng",
  midfield: "HT",
  overtime: "bù giờ",
  penaltyKick: "phạt đền",
  endOfFirstQuarter: "Q1 kết thúc",
  endOfSecondQuarter: "Q2 kết thúc",
  endOfThirdQuarter: "Q3 kết thúc",
  endOfFourthQuarter: "Q4 kết thúc",
  pullUpToLoadMore: "kéo lên để tải thêm",
  pullDownToRefresh: "kéo xuống để làm mới",
  releaseToRefreshImmediately: "Thả ra để làm mới ngay lập tức",
  refreshPrompt: "Làm mới...",
  download: "tải xuống",
  appDownload: "tải xuống APP",
  brandName: "UFOScore",
  saveToDesktop: "thêm vào màn hình chính",
  downloadTitle: "tải xuống APP",
  normalDownload: "Tải xuống bình thường",
  scanningPrompt: "Quét mã để cài đặt và nhận ưu đãi lớn độc quyền, cùng nhiều phúc lợi sang trọng khác đang chờ bạn!",
  downloadFailToWeb: "phiên bản máy tính",
  scanQrCode: "Quét mã QR của điện thoại",
  usingMobileDevices: "Sử dụng thiết bị di động",
  installTutorial: "Xem hướng dẫn cài đặt",
  whyNameDifferent:
    "Tên của APP đã cài đặt và APP đã tải xuống không nhất quán, vui lòng tải xuống, cài đặt và sử dụng!",
  clickDownload: "tải xuống",
  installTutorialPrompt: "Hướng dẫn cài đặt",
  brandNativeName: "ỨNG DỤNG gốc UFOScore",
  uploadingPrompt: "đang tải lên...",
  bHandicap: "kèo chấp",
  different: "different",
  total: "total",
  off: "off",
  video: "băng hình",
  animation: "hoạt hình",
  install: "cài đặt",
  startDownloading: "bắt đầu tải về",
  cancelGoal: "bàn thắng bị hủy",
  appUpdatePrompt: "App có phiên bản mới, vui lòng update",
};
