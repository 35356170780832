<template>
  <!-- 直播间的荐球列表 -->
  <div class="liveRecommendList">
    <div class="listItem" v-for="(item, index) in dataList" :key="index">
      <div
        class="itemHeader"
        @click="toStreamerRecommendDetail(item.streamer.username)"
      >
        <div class="streamerInfo">
          <!-- 头像 -->
          <div class="avatar">
            <img :src="imageBaseUrl + item.streamer.avatar" alt="" />
          </div>
          <div class="info">
            <!-- 主播名称 -->
            <div class="text">{{ item.streamer.name }}</div>
            <!-- 主播标签 -->
            <div class="tags">
              <!-- <div
                class="tagItem"
                v-for="(tag, index) in item.select"
                :key="index"
              >
                {{ tag }}
              </div> -->
            </div>
          </div>
        </div>
        <!-- 命中率 -->
        <div class="hitRate">
          <div class="data">
            <span class="value">{{ item.hit_rate }}</span
            ><span class="symbol">%</span>
          </div>
          <div class="text">{{ $t("hitOfTheWeek") }}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="title" @click="toSchemeDetails(item)">
          {{ item.analyze_title }}
        </div>
        <!-- 数据栏 -->
        <div class="winCount" @click="toSchemeDetails(item)">
          <div class="left">
            <!-- 购买人数 -->
            <div class="buyCount">
              <!-- <span class="count">{{ item.buy_count }}</span> -->
              <!-- <span class="count">111</span> -->
              <!-- <span class="viweIcon"></span> -->
            </div>
            <!-- 近10场 -->
            <!-- <div class="text">{{ $t("lastTenGames") }}</div> -->
            <div
              v-for="(value, index) in item?.ten_hit"
              :key="index"
              class="countItem"
            >
              <div class="win" v-if="value === 'win'">W</div>
              <div class="defeat" v-else>D</div>
            </div>
          </div>
          <div class="right">
            <!-- 聊天按钮 -->
            <!-- <div class="chatBtn" @click.stop="toChat(item.streamer.id)"> -->
            <div class="chatBtn" @click.stop="toChat(item)">
              <div class="chatIcon"></div>
              <div class="text">{{ $t("chat") }}</div>
            </div>
          </div>
        </div>
        <!-- 底部栏 -->
        <!-- <div class="bottomArea">
          <div class="leftArea">
            <div class="playType">{{ $t("victoryOrDefeat") }}</div>
          </div>
          <div class="rightArea"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveRecommendList",
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    userInfo: {
      type: Object,
    }
  },
  data() {
    return {
      target: {
        category: "private",
        target_id: null,
        name: null,
        avatar: null,
        imageBaseUrl: "",
      },
    };
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
  },
  methods: {
    /**
     * 跳转路由-聊天
     * @param { String } targetUsername streamer.id 好友用户名/id
     * @param { String } targetName streamer.name 好友昵称
     * @param { String } targetAvatar streamer.avatar 好友头像
     */
    toChat(item) {
      console.log(item);
      this.target.target_id = item.streamer.username;
      this.target.name = item.streamer.name;
      this.target.avatar = item.streamer.avatar;
      sessionStorage.setItem("liveRoomTabActivity", "recommend");
      // this.$router.push({
      //   name: "chatInterface",
      //   query: {
      //     category: "private",
      //     targetId: streamer.id,
      //     targetName: streamer.name,
      //     targetAvatar: streamer.streamer,
      //   },
      // });
      // 发射事件给LiveRecommend组件
      // console.log("过程1");
      // console.log(this.target);
      // console.log("过程1");
      this.$emit("toChat", this.target);
      // this.$emit("toChat", item);
    },

    /**
     * 跳转路由-方案详情
     */
    toSchemeDetails(data) {
      sessionStorage.setItem("liveRoomTabActivity", "recommend");
      const {tip_id, tip_type, competition} = data;
      this.$router.push({
        name: "SchemeDetails",
        query: {
          tip_id: tip_id,
          tip_type: tip_type,
          sport_type_id: competition.sport_type_id,
          competition_id: competition.id,
          username: this.userInfo?.username || "",
        },
      });
    },

    /**
     * 跳转路由-主播方案详情
     */
    toStreamerRecommendDetail(username) {
      sessionStorage.setItem("liveRoomTabActivity", "recommend");
      // this.$router.push({
      //   name: "RecommendDetail",
      //   query: {},
      // });
      this.$router.push({
        name: "AnchorIntroduction",
        query: {
          id: username,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
