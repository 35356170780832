import request from "@/services/axios/IMRequest";
import commonRequest from "@/services/axios/commonRequest";
import fileRequest from "@/services/axios/fileRequest";
import liveChatRequest from "@/services/axios/liveChatRequest";
// 重构后的接口
// 获取最近的消息
export function getRecentMessages(params) {
  return request({
    url: "/recent-contacts",
    method: "get",
    params,
  });
}
// 获取历史消息
export function getHistoryMessages(params) {
  return request({
    url: "/history-messages",
    method: "get",
    params,
  });
}
// 主播私聊以及私聊
export function sent(data) {
  return request({
    url: "/send-message",
    method: "post",
    data,
  });
}
// 获取未读消息总数量
export function getTotalUnreadCount(params) {
  return request({
    url: "/total-unread-message-count",
    method: "get",
    params,
  });
}
// 清除未读消息
export function clearUnread(data) {
  return request({
    url: "/clear-unread-message-count",
    method: "post",
    data,
  });
}
// 获取内容推送频道
export function getContentChannel(params) {
  return commonRequest({
    url: "/content-channel",
    method: "get",
    params,
  });
}

// 获取通讯推送频道
export function getCommunicationChannel(params) {
  return request({
    url: "/im-channel",
    method: "get",
    // im channel 超时时间设置为30s
    timeout: 30000,
    params,
  });
}

// 获取直播间广场推送频道
export function getLiveChatChannel(params) {
  return liveChatRequest({
    url: "/live-chat-channel/" + params.live_id,
    method: "get",
    params,
  });
}

// 上传图片
export function uploadImage(data) {
  const directory = process.env.VUE_APP_FILE_IM_BUCKET;
  return fileRequest({
    url: `/${directory}`,
    method: "post",
    data,
  });
}
// 上传头像
export function uploadAvatar(data) {
  const directory = process.env.VUE_APP_FILE_BUCKET;
  return fileRequest({
    url: `/${directory}`,
    method: "post",
    data,
  });
}
// 私聊欢迎语
export function getPrivateWelcome(username, params) {
  return commonRequest({
    url: "/private-chat-welcome-message/" + username,
    method: "get",
    params
    
  });
}
// 隐藏联系人对话
export function hideRecentContact(data) {
  return request({
    url: "/hide-contact",
    method: "post",
    data,
  });
}
// 获取AI助手信息
export function getAIInfo(params) {
  return request({
    url: `/gpt/get_ai_official_customer_service/${params.language_id}`,
    method: "get",
    params,
  });
}
// AI助手聊天
export function aiChat(data) {
  return request({
    url: "/gpt/chat",
    method: "post",
    data,
    timeout: 30000,
  });
}