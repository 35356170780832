<template>
  <div class="AnchorInformation" ref="anchorInformationScrollWrapper">
    <!-- 主播信息 区域 -->
    <div class="AnchorInformations">
      <!-- 主播信息卡片 个人简介and赛程计划表 -->
      <AnchorInfoCard
        :streamerUsername="streamerUsernameData"
        :isShowLiveDuration="false"
      ></AnchorInfoCard>

      <!-- 推荐 区域 -->
      <div class="recommend">
        <LiveRecommendList
          v-if="dataList?.length > 0"
          :dataList="dataList"
          @toChat="toChat"
        ></LiveRecommendList>
      </div>

      <!-- 推荐直播间 区域 -->
      <div class="lives">
        <!-- 标题栏 -->
        <div class="titleNav">
          <div class="title">
            <div class="Mtitle">{{ $t("recommendedLiveStreams") }}</div>
          </div>
        </div>
        <el-skeleton :loading="dataLoading" animated :count="2">
          <template slot="template">
            <LiveCardSkeleton />
          </template>
          <template>
            <!-- 直播间列表 -->
            <div class="livesList" v-show="lives?.length">
              <div
                class="column"
                v-for="(column, index) in doubleColumnLive"
                :key="index"
              >
                <!-- 直播卡片组件 -->
                <LiveCard
                  v-for="(live, iIndex) in column"
                  :key="iIndex"
                  :live="live"
                  @refreshParent="clickLiveCard"
                ></LiveCard>
              </div>
            </div>
            <div class="noDataWrapper" v-if="!lives?.length">
              <NoData v-show="!dataLoading">{{ $t("noDataPrompt") }}</NoData>
              <WaitToastArea v-show="dataLoading"></WaitToastArea>
            </div>
          </template>
        </el-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import { getLives } from "@/services/api/Home";
import LiveCard from "@/components/LiveCard/Index.vue";
import WaitToastArea from "@/components/WaitToastArea/Index";
import NoData from "@/components/NoData/Index.vue";
import AnchorInfoCard from "@/components/AnchorInfoCard/Index.vue";
import LiveCardSkeleton from "@/components/LiveCard/LiveCardSkeleton.vue";
import LiveRecommendList from "@/components/LiveRecommendList/Index.vue";
import { getAppreciation } from "@/services/api/appreciation";
// import toast from "@/utils/toast.js";
import dayjs from "dayjs";
export default {
  components: {
    AnchorInfoCard,
    LiveCard,
    NoData,
    LiveRecommendList,
    WaitToastArea,
    LiveCardSkeleton,
  },
  props: {
    streamerUsername: String,
    liveId: {
      type: Number,
    },
  },
  computed: {
    // 由于瀑布流布局的"正在直播"数据
    doubleColumnLive() {
      if (this.lives) {
        let doubleRowArr = [[], []];
        this.lives.forEach((element, index) => {
          if (index % 2 === 0) {
            doubleRowArr[0].push(element); // 偶数索引元素放进第二个小数组
          } else {
            doubleRowArr[1].push(element); // 奇数索引元素放进第一个小数组
          }
        });
        return doubleRowArr;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      /**
       * 同路由路径跳转时 需要修复streamerUsername值
       * 但props的值不能直接修改
       */
      streamerUsernameData: null,
      lives: [],
      dataList: [],
      dataLoading: true, //toast是否在加载状态
    };
  },
  created() {
    if (!this.streamerUsernameData) {
      this.streamerUsernameData = this.streamerUsername;
    }
  },
  watch: {
    liveId: {
      handler: async function (newVal) {
        if (newVal) {
          await this.getHotLives();
        }
      },
    },
    streamerUsername: {
      handler: function (newVal) {
        if (newVal) {
          this.streamerUsernameData = newVal;
        }
      },
    },
  },
  mounted() {
    this.getHotLives();
    // this.getAppreciationData();
  },
  methods: {
    //  获取推荐直播间
    async getHotLives() {
      const params = {
        country_id: localStorage.getItem("country_id"),
        language_id: localStorage.getItem("language_id"),
        page: 1,
        size: 20,
        current_live_room_id: this.liveId,
      };
      try {
        const res = await getLives({
          ...params,
        });
        const { data, statusCode, statusText } = res;
        if (statusCode === 200) {
          const { items } = data;
          this.lives = items;
          // this.watchWs();
        } else {
          console.error(statusText);
        }
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;
        console.error(error);
      }
    },

    // 获取推荐列表
    async getAppreciationData() {
      // const watiToast = toast.initWaitToast();
      try {
        // 展示加载toast
        // const watiToast = this.$vantToast({
        //   type: "loading",
        //   overlay: true,
        //   forbidClick: true,
        //   duration: 3000,
        // });
        let res = await getAppreciation({
          streamer_id: this.streamerUsernameData,
        });
        const { statusCode, data } = res;
        if (statusCode === 201) {
          // watiToast.clear();
          const { result } = data;
          const list = [...result];
          // 排序
          list.sort((a, b) => {
            return dayjs(b.create_at) - dayjs(a.create_at);
          });
          this.dataList = list;
        }
      } catch (error) {
        console.error(error);
      }
      // console.log(this.dataList);
    },

    /**
     *  来自子组件LiveCard 转发至父组件LiveRoom
     *  由LiveRoom更新路由参数并刷新自身
     */
    clickLiveCard({ competition_id, live_id, sport_type_id, source_id }) {
      this.$emit("refreshGrandParent", {
        competition_id,
        live_id,
        sport_type_id,
        source_id,
      });
    },

    /**
     * 监听ws 直播热度/观看人数的更新推送
     */
    // watchWsViewCount() {
    //   this.handleGlobalMessge({
    //     type: ["view_count"],
    //     callback: (message) => {
    //       const { data } = message;
    //       this.updataViewCount(data);
    //     },
    //     id: "AnchorInformationViewCount",
    //   });
    // },

    /**
     * 监听ws 直播间 直播状态的更新推送
     */
    // watchWsLiveStatus() {
    //   this.handleGlobalMessge({
    //     type: ["live_status"],
    //     callback: (message) => {
    //       const { data } = message;
    //       this.updataLiveStatus(data);
    //     },
    //     id: "AnchorInformationLiveStatus",
    //   });
    // },

    /**
     * 监听ws 赛事比分的更新推送
     */
    // watchWsLivingScores() {
    //   this.handleGlobalMessge({
    //     type: ["football_scores", "basketball_scores"],
    //     callback: (message) => {
    //       const { data } = message;
    //       this.updataLivingScores(data);
    //     },
    //     id: "AnchorInformationLivingScores",
    //   });
    // },

    /**
     * 更新 正在直播 赛事比分
     */
    updataLivingScores(data) {
      const targetIndexObj = this.lives.find(
        (item) => item.competition_id === data.competition_id
      );
      if (targetIndexObj) {
        const { home_scores, away_scores } = data;
        // console.group(`${competition_id}`);
        // home_scores 不为null且不是空数组
        if (home_scores && home_scores.length !== 0) {
          // console.log("home_scores", home_scores);
          targetIndexObj.match[0].score = home_scores[0];
        }
        // away_scores 不为null且不是空数组
        if (away_scores && away_scores.length !== 0) {
          // console.log("away_scores", away_scores);
          targetIndexObj.match[1].score = away_scores[0];
        }
        // console.groupEnd();
      }
    },

    /**
     * 更新 直播间 直播状态
     */
    updataLiveStatus(data) {
      const targetIndex = this.lives.findIndex(
        (item) => item.live_id === Number(data.live_id) && data.status === 0
      );
      if (targetIndex !== -1) {
        this.lives.splice(targetIndex, 1);
      }
    },

    /**
     * 更新 直播热度/观看人数
     */
    updataViewCount(data) {
      const targetIndexObj = this.lives.find(
        (item) => item.live_id === Number(data.live_id)
      );
      if (targetIndexObj) {
        targetIndexObj.view_count = data.view_count;
      }
    },

    /**
     * 监听ws推送
     */
    // watchWs() {
    //   this.watchWsViewCount();
    //   this.watchWsLiveStatus();
    //   this.watchWsLivingScores();
    // },

    /**
     * 移除ws推送监听
     */
    // removeWatchWs() {
    //   this.removeHandleGlobalMessge({
    //     type: ["view_count"],
    //     id: "AnchorInformationViewCount",
    //   });
    //   this.removeHandleGlobalMessge({
    //     type: ["live_status"],
    //     id: "AnchorInformationLiveStatus",
    //   });
    //   this.removeHandleGlobalMessge({
    //     type: ["football_scores", "basketball_scores"],
    //     id: "AnchorInformationLivingScores",
    //   });
    // },

    // 接收来自AnchorInformation 转发给LiveRoom
    toChat(target_id) {
      this.$emit("changeTabToChat", target_id);
    },
  },

  // compititionSkip(item) {
  //   if (item.is_live) {
  //     localStorage.setItem("liveId", item.lives[0].id);
  //     this.$router.push("/LiveRoom");
  //   } else {
  //     localStorage.setItem("competitionId", item.id);
  //     this.$router.push("/toBeLive");
  //   }
  // },
};
</script>
<style lang="scss" scoped>
@import "@/Components/AnchorInformation/Index.scss";
</style>
<style lang="scss">
@import "@/styles/Home/MoreStreamers.vant.scss";
</style>
