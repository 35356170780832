var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"square"},[_c('div',{staticClass:"Mobile"},[_c('div',{staticClass:"MsquareC",on:{"touchstart":_vm.handleTouchStart,"touchend":_vm.handleTouchEnd}},[_c('div',{staticClass:"welcomeCardWrapper"},[(_vm.isShowWelcome && _vm.cardInfo)?_c('WelcomeCard',{attrs:{"cardInfo":_vm.cardInfo,"TMoptionActivity":_vm.TMoptionActivity},on:{"toStreamerPrivateChat":_vm.toStreamerPrivateChat}}):_vm._e()],1),_c('div',{ref:"messageListWrapper",staticClass:"messageListWrapper",on:{"scroll":_vm.handleScroll,"touchmove":_vm.handleScroll}},[_c('div',{staticClass:"messageLists"},[_vm._l((_vm.messageList),function(item,key){return _c('ul',{key:key,staticClass:"messageItem"},[(item.content_type === 'notice')?_c('div',{staticClass:"notice"},[_c('NoticeMessage',{attrs:{"message":item?.content[0]}})],1):_c('li',{staticClass:"item"},[(item.sender?.role !== 'audience')?_c('span',{class:item.sender?.role},[_vm._v(" "+_vm._s(_vm.$t(`${item.sender?.role}`))+" ")]):_vm._e(),_c('span',{staticClass:"live-anchor-name",style:({
                  color:
                    item.sender?.role !== 'audience'
                      ? ' var(--theme-score-word)'
                      : '#fff',
                })},[_vm._v(" "+_vm._s(item.sender.name)+": ")]),_c('span',{staticClass:"dialog"},[_c('span',{staticClass:"text-content",style:({
                    color:
                      item.sender?.role !== 'audience'
                        ? '#b47b39'
                        : '#c7c8ca',
                  })},[_c('MessageContent',{attrs:{"content":item.content,"mode":"square"}})],1)])])])}),_c('div',{ref:"endElement"})],2)])]),_c('div',{staticClass:"newMessageTip"},[(_vm.tipVisible)?_c('div',{staticClass:"tipContent",on:{"click":_vm.clickNewMessageTip}},[_vm._v(" "+_vm._s(_vm.$t("newMessage"))+" ")]):_vm._e()])]),_c('div',{staticClass:"inptChat"},[(!_vm.showInput)?_c('div',{staticClass:"input-selection",on:{"click":_vm.gotoLogin}},[_c('van-field',{staticClass:"custom-input",attrs:{"rows":"1","autosize":"","type":"textarea","maxlength":"1024","id":"send_M","readonly":true,"placeholder":_vm.$t(_vm.placeholderText)},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1):_c('div',{staticClass:"input-selection"},[_c('div',{staticClass:"cancelArea",on:{"click":function($event){$event.stopPropagation();return _vm.closeInput.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("cancelSend"))+" ")]),_c('div',{staticClass:"confirmArea",on:{"click":function($event){$event.stopPropagation();return _vm.sendMessage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("confirmSend"))+" ")])])]),_c('van-popup',{staticClass:"inputPopup",attrs:{"position":"top","overlay":false},model:{value:(_vm.showInput),callback:function ($$v) {_vm.showInput=$$v},expression:"showInput"}},[_c('div',{staticClass:"input-selection"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"closeArea",on:{"click":_vm.closeInput}})]),_c('van-field',{ref:"realInput",staticClass:"custom-input",style:({ pointerEvents: _vm.role !== 'user' ? 'none' : '' }),attrs:{"rows":"1","autosize":"","type":"textarea","maxlength":"1024","id":"send_M","placeholder":_vm.$t(_vm.placeholderText)},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"iconRight",on:{"click":_vm.sendMessage}})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }