import { Toast } from 'vant';

let toast = {
  initWaitToast(duration) {
    return  Toast({
      type: "loading",
      icon: require("@/assets/client-logos/nodata.webp"),
      // message: 'loading...',
      // overlay: true,
      forbidClick: true,
      closeOnClick: true,
      closeOnClickOverlay: true,
      duration: duration ||  3000,
      className: 'loading-toast'
    });
  },

  clearWaitToast(data) {
    if(data) data.clear();
    // console.log(data)
    
  },
}

export default toast