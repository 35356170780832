<template>
  <div class="fileMessageItem">
    <div class="contentArea">
      <div class="fileIcon">
        <div class="fileMessageIcon"></div>
      </div>
      <div class="contentName">
        <div>{{ message.content }}</div>
        <div class="size">
          {{ message.size }}
        </div>
      </div>
    </div>
    <div class="downloadArea" @click="downloadFile(message)">
      <!-- vant 没有想要的效果，旋转180度 -->
      <van-icon name="back-top" class="icon"/>
      
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
export default {
  name: "FileMessageItem",
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    async downloadFile(message) {
      console.log(message);
      saveAs(message.file, message.content);
      // TODO: webclip下使用不同的域名进行跳转
      // saveAs("http://43.139.156.49:8081" + message.file, message.content);
      // eval(`plus.runtime.openURL('http://43.139.156.49:8081${message.file}')`)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./Index.scss";
</style>
