/**
 * @file 简体中文 本地化语言文件
 */

//组件
const AgreementArea = {
  userAgreement: "用户协议",
  privacyPolicy: "隐私政策",
  termsOfService: "服务条款",
};

const AnchorAvatarAndName = {
  live: "直播中",
  offLine: "未开播",
};

const AnchorInfoCard = {
  live: "直播中",
  alreadyPrompt: "已开播",
  watch: "去观看",
  follow: "关注",
  following: "已关注",
  subscribe: "订阅",
  streamerSchedule: "主播赛程",
  noDataPrompt: "暂无数据",
  unfollowPrompt: "是否确认取消关注?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  hours: "小时",
  mins: "分钟",
};

const AnchorInformation = {
  recommendedLiveStreams: "推荐直播",
  noDataPrompt: "暂无数据",
};

const BasketballScoreIndex = {
  details: "详情",
  over: "完",
  pointsDifference: "分差",
  totalScore: "总分",
  live: "直播中",
  unSubscribePrompt: "是否确认取消订阅?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
};

const BasketballSmallBig = {
  details: "详情",
  over: "完",
  pointsDifference: "分差",
  totalScore: "总分",
  live: "直播中",
  unSubscribePrompt: "是否确认取消订阅?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
  overIndex: "大",
  underIndex: "小",
};

const BindPhoneDialog = {
  bindPhonePrompt: "为了您的账号安全，请绑定手机号，以便我们为您提供更好的服务",
  goBind: "去绑定",
};

const BottomNavigationBar = {
  home: "首页",
  matches: "比赛",
  recommend: "推荐",
  chat: "聊天",
  personal: "个人",
  discovery: "发现",
  money: "赚钱",
};

const ChatInterface = {
  noCsPrompt: "暂时无法聊天",
  uploadRulePrompt: "请上传jpg、jpeg、png格式图片",
  uploadSizePrompt: "图片大小不能超过10M",
  sendError: "发送失败",
  picSendError: "图片发送失败",
  InternetErrorPrompt: "网络错误",
  bannedSendPrompt: "您已被禁言",
};

const ChatList = {
  noNewPrompt: "暂无消息",
  logInPrompt: "登录可查看内容，请先登录",
  logIn: "登录",
  group: "群组",
};

const ChatListItem = {
  withdrawMessage: "撤回了一条消息",
  bannedPrompt: "被禁言",
  unBanPrompt: "被解除禁言了",
  joinGroupPrompt: "加入了群组",
  leftGroupPrompt: "退出了群组",
  picture: "图片",
  link: "链接",
  emoji: "动画表情",
  addedFriendPrompt: "已添加为好友",
};

const CompetitionList = {
  logInPrompt: "登录可查看内容，请先登录",
  logIn: "登录",
  today: "今天",
  Monday: "星期一",
  Tuesday: "星期二",
  Wednesday: "星期三",
  Thursday: "星期四",
  Friday: "星期五",
  Saturday: "星期六",
  Sunday: "星期日",
  noGames: "暂无比赛",
  toCollapse: "点击收起",
  toExpand: "点击展开"
};

const CompetitionListItem = {
  details: "详情",
  unSubscribePrompt: "是否确认取消订阅?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
};

const CompetitionMode = {
  matchMode: "比赛模式",
  confirm: "确认",
  cancel: "取消",
};

const EmojiCom = {
  logAndSign: "立即登录/注册",
  yes: "是",
  no: "否",
};

const ExponentCom = {
  company: "公司",
  initial: "初始",
  ongoing: "即时",
  preMatch: "赛前",
  noDataPrompt: "暂无数据",
  handicap: "让球",
  WinDrawLose: "胜平负",
  totalGoals: "总进球",
  cornerKick: "角",
  pleaseSelect: "请选择",
  confirm: "确认",
  cancel: "取消",
  FH: "半",
  FT: "全场",
  pinnedPrompt: "已置顶至“广场”",
};

const FollowedStreamers = {
  live: "直播中",
  follow: "关注",
  following: "已关注",
  chat: "聊天",
  noDataPrompt: "暂无数据",
  unfollowPrompt: "是否确认取消关注?",
  yes: "是",
  no: "否",
  InternetErrorPrompt: "网络错误",
};

const FootballCornerIndex = {
  details: "详情",
  over: "完",
  FH: "半",
  cornerKick: "角",
  unSubscribePrompt: "是否确认取消订阅?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
};

const FootballScoreIndex = {
  details: "详情",
  over: "完",
  FH: "半",
  cornerKick: "角",
  live: "直播中",
  unSubscribePrompt: "是否确认取消订阅?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
};

const FootballScoreItem = {
  details: "详情",
  over: "完",
  FH: "半",
  cornerKick: "角",
  live: "直播中",
  unSubscribePrompt: "是否确认取消订阅?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
};

const GoalToast = {
  goal: "进球",
};

const LiveRecommend = {
  noDataPrompt: "暂无数据",
  recoverLosses: "回血",
  winningBetSlip: "红单",
};

const LiveRecommendList = {
  victoryOrDefeat: "胜负",
};

const MatchCardList = {
  details: "详情",
};

const MatchStatus = {
  noDataPrompt: "暂无数据",
};

const MoreStreams = {
  switchLines: "切换线路",
  cancel: "取消",
  currentStreamer: "当前主播",
  live: "直播中",
  offLine: "未开播",
  followersCount: "关注",
  otherStreamersPrompt: "当前比赛还有其他主播正在直播中",
  watch: "去观看",
};

const NewMatchStatus = {
  team: "球队",
  halfTimeScore: "半场比分",
  shotOnTarget: "射正球门",
  missedShot: "射偏球门",
  attack: "进攻",
  dangerousAttack: "危险进攻",
  number3PointGoals: "3分球进球数",
  number2PointGoals: "2分球进球数",
  penaltyKickGoals: "罚球进球数",
  freeThrowPercentage: "罚球命中率%",
  numberOfPausesRemaining: "剩余暂停数",
  numberOfFouls: "犯规数",
  totalNumberOfPauses: "总暂停数",
  threePointShot: "3分球",
  twoPointShot: "2分球",
  shoot: "投篮",
  penalty: "罚球",
  firstHalf: "上半场",
  secondHalf: "下半场",
  redCard: "红牌",
  yellowCard: "黄牌",
};

const NoData = {
  noDataPrompt: "暂无数据",
};

const NoticeMessage = {
  addedFriendPrompt: "已添加为好友",
  withdrawMessage: "撤回了一条消息",
  withdrawMessageByAdmin: "被管理员撤回了一条消息",
  joinGroupPrompt: "加入了群组",
  leftGroupPrompt: "退出了群组",
  removeGroupPrompt: "被管理员移出了群组",
  bannedPrompt: "被禁言",
  unBanPrompt: "被解除禁言了",
};

const OddsStatisticsList = {
  initial: "初始",
  ongoing: "即时",
  preMatch: "赛前",
  HW: "主胜",
  Dr: "平局",
  AW: "客胜",
  max: "最高值",
  min: "最低值",
  average: "平均值",
};

const RecommendCom = {
  live: "直播中",
  noDataPrompt: "暂无数据",
};

const RecommendCompetitions = {
  today: "今天",
  winsInARow: "连中",
  noLivePrompt: "暂无直播",
};

const RecommendStreamerList = {
  noDataPrompt: "暂无数据",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "周命中",
  victoryOrDefeat: "胜负",
  check: "查看",
  chat: "聊天",
  quarterlyProfit: "季度盈利",
};

const RecommendStreamers = {
  recommendedByExperts: "达人推荐",
  winsInARow: "连中",
  changeBatch: "换一批",
  follow: "关注",
  following: "已关注",
  unFollowing: "取关",
  noDataPrompt: "暂无数据",
  logAndSign: "立即登录/注册",
  yes: "是",
  no: "否",
  InternetErrorPrompt: "网络错误",
  unfollowPrompt: "是否确认取消关注?",
};

const RecordChart = {
  record: "战绩",
  allWaysToPlay: "全部玩法",
  fromFarToNear: "由远及近",
  lastTenGames: "近10场",
  longestConsecutiveHit: "最长连中",
  hitRate: "命中率",
  currentPropmt: "场最近比赛",
  
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "请谨慎选择您的国家/地区和语言，该选择将影响到后续功能的使用，且后续无法修改",
  getUserInfoErrorPrompt: "获取用户信息失败",
  getAreaAndLanguageErrorPrompt: "获取地区语言失败",
};

const ScheduleCom = {
  startTime: "开始时间",
};

const SchemeCard = {
  planList: "方案列表",
  release: "发布",
  draw: "和",
  win: "中",
  halfWin: "半",
  lost: "未",
  halfLost: "半",
  all: "全部",
  guaranteedCompensation: "包赔",
  free: "免费",
  toll: "收费",
  victoryOrDefeat: "胜负",
};

const SquareCom = {
  watchPrompt: "人正在观看",
  startedPrompt: "分钟前开播",
  chat: "聊天",
  sendMessageNeedLoginPrompt: "请登录后发送消息",
  assistant: "管理员",
  audience: "用户",
  newMessage: "新消息",
  confirmSend: "确认发送",
  cancelSend: "取消",
};

const StatisticalChart = {
  hitRate: "命中率",
};

const SwitchLines = {
  switchLines: "切换线路",
  cancel: "取消",
  currentStreamer: "当前主播",
  live: "直播中",
  offLine: "未开播",
  followersCount: "关注",
  otherStreamersPrompt: "当前比赛还有其他主播正在直播中",
  watch: "去观看",
  LINE: "线路",
  VIP: "VIP",
  OFFICIAL: "官方",
};

const VideoCom = {
  videoErrorPrompt: "信号不太好噢，请稍后重试",
  clickRetryPrompt: "点击重试",
};

const WelcomeCard = {
  join: "加入群组",
};

//utils
const tools = {
  yesterday: "昨天",
  addedFriendPrompt: "已添加为好友",
  withdrawMessage: "撤回了一条消息",
  joinGroupPrompt: "加入了群组",
  leftGroupPrompt: "退出了群组",
  removeGroupPrompt: "被管理员移出了群组",
  bannedPrompt: "被禁言",
  unBanPrompt: "被解除禁言了",
};

//页面
const AddressBook = {
  search: "查找",
  addFriend: "添加好友",
  myGroupChat: "我的群聊",
  friends: "好友",
  delete: "删除",
  noFriendsPrompt: "暂无好友",
  nameAndIdSearch: "输入名称、ID",
  searchResults: "查找结果",
  notFoundPrompt: "查找不到用户",
  add: "添加",
  deleteFriendPrompt: "是否确认删除好友?",
  yes: "是",
  no: "否",
  addFriendSuccessPrompt: "添加好友成功",
  deleteFriendSuccessPrompt: "删除好友成功",
  InputSearchPrompt: "请输入内容",
};

const GroupChat = {
  groupChat: "群聊",
  groupSearch: "输入群聊名称",
  noGroupPrompt: "暂无群聊",
  chat: "聊天",
  InputSearchPrompt: "请输入内容",
};

const GroupAdministrator = {
  administrator: "管理员",
  groupOwner: "群主",
};

const GroupAnnouncement = {
  groupAnnouncement: "群公告",
  groupOwner: "群主",
  administrator: "管理员",
};

const GroupDetail = {
  groupDetail: "群详情",
  groupMembers: "群成员",
  groupName: "群名称",
  groupDescription: "群描述",
  groupOwner: "群主",
  administrator: "管理员",
  administratorList: "管理员列表",
  groupAnnouncement: "群公告",
  groupFiles: "群文件",
  exitGroup: "退出群",
  exitGroupPrompt: "是否确认退出群?",
  yes: "是",
  no: "否",
  leftGroupSuccessPrompt: "退出群组成功",
};

const GroupFile = {
  groupFiles: "群文件",
  fileSearch: "输入文件名",
  today: "今天",
  multimedia: "多媒体",
  file: "文件",
  noFilesPrompt: "暂无文件",
};

const GroupMembers = {
  groupMembers: "群成员",
  nameAndIdSearch: "输入名称、ID",
};

const Chat = {
  chat: "聊天",
  nameAndIdSearch: "输入名称、ID",
  contacts: "通讯录",
  InputSearchPrompt: "请输入内容",
  deleteContactPrompt: "是否确认删除聊天?",
};

const Competition = {
  all: "全部",
  ongoing: "即时",
  popular: "热门",
  liveCompetition: "直播",
  schedule: "赛程",
  results: "赛果",
  subscribed: "已订阅",
  goal: "进球",
  today: "今天",
  Monday: "星期一",
  Tuesday: "星期二",
  Wednesday: "星期三",
  Thursday: "星期四",
  Friday: "星期五",
  Saturday: "星期六",
  Sunday: "星期日",
};

const CompetitionDetails = {
  subscribe: "订阅",
  subscribed: "已订阅",
  matchStreamerPrompt: "当场比赛主播",
  live: "直播中",
  noDataPrompt: "暂无数据",
  noStreamersPrompt: "该比赛暂无主播开播",
  alreadyStreamerPrompt: "当场比赛已有主播开播 是否前往观看?",
  yes: "是",
  no: "否",
  liveStream: "直播",
  matchStatus: "赛况",
  odds: "指数",
  unSubscribePrompt: "是否确认取消订阅?",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
};

const OddsDetails = {
  company: "公司",
  handicap: "让球",
  WinDrawLose: "胜平负",
  totalGoals: "总进球",
  cornerKick: "角球",
  HT: "主队",
  Trd: "走势",
  AT: "客队",
  Tm: "时间",
  Sc: "比分",
  HW: "主胜",
  Dr: "平局",
  AW: "客胜",
  RR: "返还率",
  Ov: "多",
  Un: "少",
};

const Home = {
  logIn: "登录",
  signUp: "注册",
  popularMatches: "热门比赛",
  more: "更多",
  noDataPrompt: "暂无数据",
  currentlyLive: "正在直播",
  popularStreamers: "热门主播",
  thinkYouLike: "猜你喜欢",
  loadMore: "加载更多",
  noMoreDataPrompt: "暂无更多数据",
  addDeskPrompt: "添加到桌面",
  clikToolPrompt: "点击下面的工具栏",
  andSeletcPrompt: "并选择",
  addToScreenPrompt: "我们希望添加到您的主屏幕，以提供更好的服务",
  addToScreen: "添加到主屏幕",
};

const MoreLives = {
  noDataPrompt: "暂无数据",
};

const MoreStreamers = {
  streamer: "主播",
  streamerSearch: "输入主播名称、ID",
  all: "全部",
  notFollowing: "未关注",
  following: "已关注",
  live: "直播中",
  offLine: "未开播",
  followersCount: "关注",
  unfollowPrompt: "是否确认取消关注?",
  yes: "是",
  no: "否",
  logAndSign: "立即登录/注册",
  InternetErrorPrompt: "网络错误",
  InputSearchPrompt: "请输入内容",
  dataRequestErrorPrompt: "获取数据失败",
};

const LiveRoom = {
  switchLines: "切换线路",
  streamChat: "广场",
  streamerPrivateChat: "主播私聊",
  chat: "聊天",
  odds: "指数",
  streamerInformation: "主播信息",
  matchStatus: "赛况",
};

const AnchorIntroduction = {
  streamerInformation: "主播信息",
  photosWall: "照片墙",
  seeMore: "查看更多",
  streamerPlan: "主播方案",
};

const AnchorPhoto = {
  photosWall: "照片墙",
  noPhotosPrompt: "暂无更多照片",
};

const LogInRegister = {
  logIn: "登录",
  signUp: "注册",
  getAreaAndLanguageErrorPrompt: "获取地区语言失败",
  quickRegisterFrequentPrompt: "请求频繁，请稍后重试",
};

const LoginFn = {
  phone: "手机号",
  email: "邮箱",
  logInPassword: "登录密码",
  rememberPassword: "记住密码",
  forgotPassword: "忘记密码",
  logIn: "登录",
  logInAgreement: "登录即表示同意",
  userAgreement: "用户协议",
  and: " 及 ",
  privacyPolicy: "隐私政策",
  passwordRulePrompt: "密码必须由大小写字母和数字组成且大于8位",
  passwordEmptyPrompt: "密码不能为空",
  emailRulePrompt: "请输入正确格式的邮箱",
  emailEmptyPrompt: "邮箱不能为空",
  phoneRulePrompt: "请输入正确格式的手机号",
  phoneEmptyPrompt: "手机号不能为空",
  phoneNum8: "请输入8位数字手机号码",
  phoneNum9To10: "请输入9至10位数字手机号码",
  phoneNum9: "请输入9位数字手机号码",
  enterErrorPrompt: "输入错误或网络异常",
  loginNoAgreePrompt: "您需要同意用户协议和隐私政策才能登录",
  accountAndPassword: "账号",
  account: "账号",
  accountEmptyPrompt: "账号不能为空",
  accountOrPasswordError: "账号或密码错误",
};

const Register = {
  phone: "手机号",
  email: "邮箱",
  code: "验证码",
  getCode: "获取验证码",
  logInPassword: "登录密码",
  CS: "客服",
  signUp: "注册",
  signUpAgreement: "注册即表示同意",
  userAgreement: "用户协议",
  and: "及",
  privacyPolicy: "隐私政策",
  contactCSPrompt: "注册遇到问题了吗？联系客服为您解决",
  contactCS: "联系客服",
  passwordRulePrompt: "密码必须由大小写字母和数字组成且大于8位",
  passwordEmptyPrompt: "密码不能为空",
  emailRulePrompt: "请输入正确格式的邮箱",
  emailEmptyPrompt: "邮箱不能为空",
  phoneRulePrompt: "请输入正确格式的手机号",
  phoneEmptyPrompt: "手机号不能为空",
  codeRulePrompt: "验证码为6位数",
  codeEmptyPrompt: "验证码不能为空",
  phoneNum8: "请输入8位数字手机号码",
  phoneNum9To10: "请输入9至10位数字手机号码",
  phoneNum9: "请输入9位数字手机号码",
  phoneCodeSendSuccessPrompt: "验证码发送成功，请查看手机短信",
  emailCodeSendSuccessPrompt: "验证码发送成功，请查看邮箱",
  phoneCodeSendErrorPrompt: "验证码发送失败,请查看手机号是否可用",
  emailCodeSendErrorPrompt: "验证码发送失败,请查看邮箱是否可用",
  codeSendToMuchPrompt: "同一时间发送太多次，请稍后再试",
  phoneAlreadySignupPrompt: "该手机号已注册，请直接登录",
  emailAlreadySignupPrompt: "该邮箱已注册，请直接登录",
  signUpErrorPrompt: "注册失败，请检查信息是否正确",
  enterErrorPrompt: "输入错误或网络异常",
  noAgreePrompt: "您需要同意用户协议和隐私政策才能注册",
  regionAndLanguagePrompt: "请选择您真实的地区语言，否则将影响功能的使用",
  accountAndPassword: "账号",
  quickSignup: "一键注册",
  accountEmptyPrompt: "账号不能为空",
  accountRulePrompt: "账号必须由8-16位的字母和数字组成",
  accountAlreadySignupPrompt: "该账号已注册，请直接登录",
  tryAgain: "重发",
};

const PersonalCenter = {
  logAndSign: "立即登录/注册",
  logAndSignPrompt: "立即解锁专属功能和独家福利",
  logOut: "退出登录",
  myFollow: "我的关注",
  mySubscription: "我的订阅",
  accountSecurity: "账号安全",
  feedback: "意见反馈",
  deviceInformation: "设备",
  aboutUs: "关于我们",
  coin: "金币",
  moreServices: "更多服务",
  following: "已关注",
  subscribed: "已订阅",
  browser: "浏览器",
  user: "用户",
  profile: "个人资料",
  nickname: "昵称",
  save: "保存",
  change: "更改",
  nicknamePrompt: "请输入昵称",
  avatar: "头像",
  changeSuccessPrompt: "更改成功",
  ballAnimation: "进球动画",
  ballVoice: "进球音效",
  setting: "设置",
};

const IntegralSystem = {
  Coin: "金币",
  goldCoinTask: "金币任务",
  PointsDetails: "金币详情",
  myCoins: "我的金币",
  income: "收入",
  expenditure: "支出",
  signInPrompt: "连续签到，奖励送不停",
  toComplete: "去完成",
  toShare: "去分享",
  shareTasks: "分享任务",
  exclusiveLink: "专属链接",
  copyLink: "复制链接",
  highestWin: "最高赢",
  day1: "第1天",
  day2: "第2天",
  day3: "第3天",
  day4: "第4天",
  day5: "第5天",
  day6: "第6天",
  day7: "第7天",
  invitePrompt: "惊喜！现在注册并下载我们的APP，尊享新用户专属福利！免费赛事直播、实时数据分析、专业主播预测，尽在指尖。别再等待，点击链接加入吧！"
};

const Money = {
  activeCenter: "活动中心",
  missionCenter: "任务中心",
};

const ActivityCenter = {
  toParticipate: "去参与",
  sportActivity: "体育活动",
};

const ActivityDetails = {
  eventDetails: "活动详情",
  dailyBetting: "每日竞猜",
  matchTime: "赛事时间",
  popularForEvent: "本场活动 热门方案",
  clickToParticipate: "点击立刻参与",
  activityRules: "活动规则",
  clickToContactParticipate: "点击联系客服参与",
  activityObj: "活动对象",
  activityPlatform: "活动平台",
  activityDuration: "活动时长",
  activityDemand: "活动要求",
  activityTime: "活动时间",
};

const AboutUs = {
  aboutUs: "关于我们",
  platformIntroduction: "平台简介",
  privacyPolicy: "隐私政策",
  userAgreement: "用户协议",
};

const DeviceInfo = {
  deviceInformation: "设备",
  currentTime: "当前时间",
  port: "登录端口",
  ip: "登录IP",
  version: "当前版本",
  ipAndPort: "登录信息",
};

const FeedBack = {
  feedback: "意见反馈",
  feedbackType: "反馈类型",
  productSuggestion: "产品建议",
  functionalFault: "功能故障",
  interfaceSuggestion: "界面建议",
  accountIssue: "账号问题",
  operationExperience: "操作体验",
  otherFeedback: "其他反馈",
  problemDescription: "问题说明",
  submit: "提交",
  addPhoto: "添加照片",
  uploadPrompt: "抱歉，仅支持上传3张照片",
  uploadRulePrompt: "请上传jpg、jpeg、png格式图片",
  uploadSizePrompt: "图片大小不能超过10M",
  chooseFeedback: "请选择反馈类型",
  inputQuestionPrompt: "请输入问题",
  submitSuccessPrompt: "提交成功",
};

const MySubscription = {
  myFollow: "我的关注",
  mySubscription: "我的订阅",
  streamer: "主播",
  matches: "比赛",
};

const PrivacyAgreement = {
  privacyPolicy: "隐私政策",
};

const TermsofService = {
  termsOfService: "服务条款",
};

const UserAgreement = {
  userAgreement: "用户协议",
};

const AccountSecurity = {
  accountSecurity: "账号安全",
  phone: "手机号",
  email: "邮箱",
  bind: "绑定",
  notBound: "未绑定",
  change: "更改",
  logInPassword: "登录密码",
  accountChangePassword: "请先绑定您的手机/邮箱",
};

const BindEmail = {
  bindEmail: "绑定邮箱",
  email: "邮箱",
  code: "验证码",
  getCode: "获取验证码",
  confirm: "确认",
  emailRulePrompt: "请输入正确格式的邮箱",
  emailEmptyPrompt: "邮箱不能为空",
  codeRulePrompt: "验证码为6位数",
  codeEmptyPrompt: "验证码不能为空",
  changeSuccessPrompt: "更改成功",
  changeErrorPrompt: "更改失败",
  bindSuccessPrompt: "绑定成功",
  bindErrorPrompt: "绑定失败",
  emailCodeSendSuccessPrompt: "验证码发送成功，请查看邮箱",
  emailCodeSendErrorPrompt: "验证码发送失败,请查看邮箱是否可用",
  codeSendToMuchPrompt: "同一时间发送太多次，请稍后再试",
  emailAlreadySignupPrompt: "该邮箱已注册，请直接登录",
  tryAgain: "重发",
};

const BindPhone = {
  bindPhone: "绑定手机",
  phone: "手机号",
  code: "验证码",
  getCode: "获取验证码",
  confirm: "确认",
  cancel: "取消",
  phoneRulePrompt: "请输入正确格式的手机号",
  phoneEmptyPrompt: "手机号不能为空",
  codeRulePrompt: "验证码为6位数",
  codeEmptyPrompt: "验证码不能为空",
  changeSuccessPrompt: "更改成功",
  changeErrorPrompt: "更改失败",
  bindSuccessPrompt: "绑定成功",
  bindErrorPrompt: "绑定失败",
  phoneNum8: "请输入8位数字手机号码",
  phoneNum9To10: "请输入9至10位数字手机号码",
  phoneNum9: "请输入9位数字手机号码",
  phoneCodeSendSuccessPrompt: "验证码发送成功，请查看手机短信",
  phoneCodeSendErrorPrompt: "验证码发送失败,请查看手机号是否可用",
  codeSendToMuchPrompt: "同一时间发送太多次，请稍后再试",
  phoneAlreadySignupPrompt: "该手机号已注册，请直接登录",
  checkSignup: "请检查号码是否已注册",
  tryAgain: "重发",
};

const ChangePassword = {
  resetPassword: "重置密码",
  phone: "手机号",
  email: "邮箱",
  code: "验证码",
  getCode: "获取验证码",
  logInPassword: "登录密码",
  confirm: "确认",
  cancel: "取消",
  passwordRulePrompt: "密码必须由大小写字母和数字组成且大于8位",
  passwordEmptyPrompt: "密码不能为空",
  emailRulePrompt: "请输入正确格式的邮箱",
  emailEmptyPrompt: "邮箱不能为空",
  phoneRulePrompt: "请输入正确格式的手机号",
  phoneEmptyPrompt: "手机号不能为空",
  codeRulePrompt: "验证码为6位数",
  codeEmptyPrompt: "验证码不能为空",
  phoneNum8: "请输入8位数字手机号码",
  phoneNum9To10: "请输入9至10位数字手机号码",
  phoneNum9: "请输入9位数字手机号码",
  resetSuccess: "密码重置成功",
  resetError: "密码重置失败，请检查信息是否正确",
  checkSignup: "请检查号码是否已注册",
  phoneCodeSendSuccessPrompt: "验证码发送成功，请查看手机短信",
  codeSendToMuchPrompt: "同一时间发送太多次，请稍后再试",
  phoneAlreadySignupPrompt: "该手机号已注册，请直接登录",
  phoneCodeSendErrorPrompt: "验证码发送失败,请查看手机号是否可用",
  emailCodeSendSuccessPrompt: "验证码发送成功，请查看邮箱",
  emailCodeSendErrorPrompt: "验证码发送失败,请查看邮箱是否可用",
  emailAlreadySignupPrompt: "该邮箱已注册，请直接登录",
  bindPhoneOrEmail: "请先绑定手机号或邮箱",
  tryAgain: "重发",
};

const QuickRegister = {
  downloadScreenshot: "下载截图",
  saveAccountAndPassword: "请保管好您的账号和密码",
  suggestSave: "建议您截图保存或复制存至备忘录保存",
  account: "账号",
  password: "密码",
  copyAccountAndPassword: "复制账号密码",
  prompt: "提示",
  sureSave: "您是否已经保存好账号密码?",
  confirm: "确认",
  cancel: "取消",
  copySuccess: "复制成功",
  copyFailure: "复制失败",
  manualRegister: "注册次数已达上限，请手动注册",
};

const Recommend = {
  recommend: "推荐",
  InternetErrorPrompt: "网络错误",
  hitOfTheWeek: "周命中",
  victoryOrDefeat: "胜负",
  check: "查看",
  lastTenGames: "近10场",
  recommendedByExperts: "达人推荐",
  changeBatch: "换一批",
  follow: "关注",
  following: "已关注",
  unFollowing: "取关",
  all: "全部",
  guaranteedCompensation: "包赔",
  free: "免费",
  toll: "收费",
  HOT: "热卖",
  HIT: "命中",
  STREAK: "连中",
  WEEK_HIT: "周命中",
  MONTH_HIT: "月命中",
  QUARTER_HIT: "季命中",
  RECENT_10_HIT: "近十命中",
};

const RecommendDetail = {
  homePage: "主页",
  chat: "聊天",
  follow: "关注",
  following: "已关注",
  unFollowing: "取关",
  followersCount: "关注",
  planCount: "方案",
  viewCount: "观看",
  statistics: "统计",
  plan: "方案",
};

const SchemeDetails = {
  planDetails: "方案详情",
  release: "发布",
  chat: "聊天",
  follow: "关注",
  following: "已关注",
  unFollowing: "取关",
  details: "详情",
  analyze: "分析",
  title: "标题",
  privateChatStreamerJoinsGroup: "私聊主播进群",
  upcoming: "未开始",
  othersPlan: "其他方案",
};
const MatchInfo = {
  homeTag: "主",
};

const LeagueSelection = {
  matchSorting: "比赛排序",
  byTime: "按时间",
  byLeague: "按联赛",
  confirm: "确认",
  cancel: "取消",
};

const RecommendPublish = {
  enterTeam: "输入队伍",
  planRelease: "方案发布",
  predictionTitle: "标题（所有用户可见，请勿透露预测结果）",
  titleEmptyPrompt: "分析的标题和内容不能为空",
  pleaseSelectAtLeastOneGameToPredictResults: "请至少选择一种玩法进行结果预测",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暂时未使用
  football: "足球",
  basketball: "篮球",
  mixed: "综合",
  setLogInPassword: "设置登录密码",
  OCS: "在线客服",
  OCSPrompt: "为您服务",
  phoneInCorrectPrompt: "手机号输入错误",
  codeInCorrectPrompt: "验证码输入错误",
  emailInCorrectPrompt: "邮箱输入错误",
  passwordPrompt: "请输入8-16位英文和数字的组合",
  newPassword: "输入新密码",
  resetPasswordPrompt: "是否确认重置密码?",
  startChatPrompt: "现在可以开始聊天了",
  send: "发送",
  noFollowPrompt: "暂无关注",
  feedbackPrompt: "请留下您宝贵的意见，我们将努力改进！",
  bindPhoneByOtherPrompt: "该手机号已被其他用户绑定",
  bindEmailByOtherPrompt: "该邮箱已被其他用户绑定",
  super_manager: "超级管理员",
  score: "比分",
  scoreIndex: "比分-指数",
  cornerKickIndex: "角球-指数",
  close: "封",
  midfield: "中",
  overtime: "加时",
  penaltyKick: "点球",
  endOfFirstQuarter: "第一节完",
  endOfSecondQuarter: "第二节完",
  endOfThirdQuarter: "第三节完",
  endOfFourthQuarter: "第四节完",
  pullUpToLoadMore: "上拉加载更多",
  pullDownToRefresh: "下拉可以刷新",
  releaseToRefreshImmediately: "松开立即刷新",
  refreshPrompt: "正在刷新中...",
  download: "下载",
  appDownload: "APP下载",
  brandName: "UFOScore",
  saveToDesktop: "添加到主屏幕",
  downloadTitle: "APP下载",
  normalDownload: "普通下载",
  scanningPrompt: "扫码安装，领取专属大额优惠，更有其他豪华福利等您来领！",
  downloadFailToWeb: "若无法下载APP,点击此处使用网页版",
  scanQrCode: "手机扫描二维码",
  usingMobileDevices: "使用移动端",
  installTutorial: "点击查看安装教程",
  whyNameDifferent: "安装APP与下载APP名称不一致,请放心下载安装使用!",
  clickDownload: "点击下载",
  installTutorialPrompt: "安装教学指南",
  brandNativeName: "UFOScore原生APP",
  uploadingPrompt: "上传中...",
  bHandicap: "让分",
  different: "差",
  total: "总",
  off: "未开始",
  video: "视频",
  animation: "动画",
  install: "安装",
  startDownloading: "开始下载",
  cancelGoal: "进球取消",
  appUpdatePrompt: "APP有新版本,请更新",
};
