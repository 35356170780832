/* eslint-disable */
import { getSpeed, getDomain } from "../api/Domain";
import CryptoJS from "crypto-js";
// 请求返回最快的域名对象
export const basePathObj = {
  clientServer: "",
  fileServer: "",
  IMServer: "",
  liveChatServer: "",
};
// 加密数据
export function encryptData(data, localStorageKey) {
  const key = process.env.VUE_APP_ENCRYPTION_KEY;
  const dataStr = JSON.stringify(data);
  const localStorageValue = CryptoJS.AES.encrypt(dataStr, key).toString();
  localStorage.setItem(localStorageKey, localStorageValue);
}
// 解密数据
async function decryptData(localStorageKey, callback) {
  const key = process.env.VUE_APP_ENCRYPTION_KEY;
  let localStorageValue = localStorage.getItem(localStorageKey);
  let localStorageValueObj = {};

  try {
    if(localStorageValue){
      const decryptedDataStr = CryptoJS.AES.decrypt(
        localStorageValue,
        key
      ).toString(CryptoJS.enc.Utf8);
      localStorageValueObj = JSON.parse(decryptedDataStr);
    }
  } catch (e) {
    console.log(e);
  }
  let flag = true;
  // 检测localStorageValueObj中的属性值是否有为空
  for (let key in localStorageValueObj) {
    if (!localStorageValueObj[key] || !localStorageValueObj[key]?.domains) {
      flag = false;
      break;
    }
  }
  // 遍历
  if (!localStorageValue || !flag) {
    return fetch(`/domain.json?time=${new Date()}`)
      .then((response) => response.json())
      .then(async (data) => {
        const { testingDomain, internationalDomain } = data;
        const domain =
          process.env.VUE_APP_ACCESSS_LINK === "true"
            ? internationalDomain
            : testingDomain;
        const decryptedDataStr = CryptoJS.AES.decrypt(domain, key).toString(
          CryptoJS.enc.Utf8
        );
        if (callback) {
          return await callback(JSON.parse(decryptedDataStr));
        }
      })
      .catch((error) => console.error("Error:", error));
  } else {
    const decryptedDataStr = CryptoJS.AES.decrypt(
      localStorageValue,
      key
    ).toString(CryptoJS.enc.Utf8);
    if (callback) {
      return callback(JSON.parse(decryptedDataStr));
    }
  }
}
// 获取所有域名
export async function getServerFastestUrl() {
  await decryptData("pathObj", async (pathObj) => {
    if (pathObj) {
      for (let server in pathObj) {
        const domains = pathObj[server]?.domains;
        if (domains) {
          let fastestUrl = await getFastestUrl(pathObj, server, true);
          basePathObj[server] = fastestUrl;
        }
      }
    }
    // encryptData(basePathObj, "basePathObj");
    localStorage.setItem("basePathObj", JSON.stringify(basePathObj));
    encryptData(pathObj, "pathObj");
  });
}
// 获取最快的域名
async function getFastestUrl(pathObj, server, shouldUseDomain) {
  const urls = pathObj[server].domains;
  const hidden_domains = pathObj[server].hidden_domains;
  let promises;
  promises = urls?.map((url) => getSpeed(server, url).then(() => url));
  let fastestUrl = "";
  try {
    fastestUrl = await Promise.race(promises);
    // console.log("fastestUrl: ", fastestUrl);
  } catch (error) {
    // console.error("Error with Promise.race: ", error);
    if (shouldUseDomain) {
      let rand = `${Math.random()}`;
      let domainData;
      for (let url of hidden_domains) {
        try {
          const { data, statusCode } = await getDomain(server, url, { rand });
          if (statusCode === 200) {
            domainData = data;
          }
          break;
        } catch (error) {
          console.error(`Error with URL ${url}: `, error);
        }
      }
      if (domainData && domainData.json_params.rand !== rand) {
        console.error("Rand mismatch in getDomain");
        return "";
      } else {
        const { domains, hidden_domains, version } = domainData || {};
        pathObj[server].domains = domains;
        pathObj[server].hidden_domains = hidden_domains;
        pathObj[server].version = version;
        fastestUrl = await getFastestUrl(pathObj, server, false);
      }
    }
  }
  // console.log("fastestUrl: ", fastestUrl);
  return fastestUrl;
}
