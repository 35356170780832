import {
  login,
  getUser,
  register,
  refreshTokenAPI,
  guestLogin,
  // getLoginStatus,
} from "@/services/api/User";
import { getToken, setToken, removeToken } from "@/utils/authCookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { languageObj } from "@/locales";
// import { resetRouter } from '@/router'
const TOKEN_KEY = "client_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const getDefaultState = () => {
  return {
    token: getToken(TOKEN_KEY),
    refreshToken: getToken(REFRESH_TOKEN_KEY), // 保存刷新toke
    time_zone: "",
    userInfo: "",
    exp: null,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_REFRESHTOKEN: (state, refreshToken) => {
    state.refreshToken = refreshToken;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_TIME: (state, time_zone) => {
    state.time_zone = time_zone;
  },
  SET_USERINFOR: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_TOKEN_EXPIRE: (state, exp) => {
    state.exp = exp;
  },
};

const getters = {
  gettime_zone: (state) => state.time_zone,
  getUserInfo: (state) => state.userInfo,
};

const actions = {
  // 登陆业务
  async login({ commit }, { userInfo, callback }) {
    // console.log(userInfo);
    const { phone, email, password, username } = userInfo || {};
    const res = await login({ phone, email, username, password });
    const { data, statusCode } = res;
    if (statusCode === 200) {
      const {
        access_token,
        refresh_token,
        country_id,
        language_id,
        // expire_in,
      } = data;
      // commit("SET_EXPIRE_IN", expire_in);
      commit("SET_TOKEN", access_token);
      commit("SET_REFRESHTOKEN", refresh_token);
      setToken(TOKEN_KEY, access_token);
      setToken(REFRESH_TOKEN_KEY, refresh_token);
      // localStorage.setItem("timestamp",res.data.expire_in)
      // 清除游客登陆的状态
      localStorage.setItem("country_id", country_id);
      localStorage.setItem("language_id", language_id);
      const language_abbreviation = languageObj[language_id];
      callback && callback(language_abbreviation);
    } else {
      // 接口发生错误
      // this.$vantToast({
      //   type: "fail",
      //   message: this.$t("InternetErrorPrompt"),
      // });
      console.log("fail:网络错误！");
    }
  },

  // 刷新登录
  async refreshAccessToken({ commit, state, dispatch }, callback) {
    const refreshToken = state.refreshToken; // 从状态中获取refresh token
    try {
      const response = await refreshTokenAPI({ refresh_token: refreshToken }); // 调用刷新令牌的接口
      const { data } = response || {};
      if (data && data.access_token) {
        // console.log('refreshAccessToken');
        commit("SET_TOKEN", data.access_token); // 更新access token
        setToken(TOKEN_KEY, data.access_token); // 存储新的access token
        if (data.refresh_token) {
          commit("SET_REFRESHTOKEN", data.refresh_token);
          setToken(REFRESH_TOKEN_KEY, data.refresh_token);
        }
        callback && callback?.(response.data.access_token);
      } else {
        // 刷新登录不成功，进行游客登录
        await actions.guestLogin({ commit, dispatch });
      }
    } catch (error) {
      // 如果刷新令牌失败并返回401状态码，触发未授权事件
      await actions.guestLogin({ commit, dispatch });
      console.error(error);
    }
  },

  // 游客登录
  async guestLogin({ commit, dispatch }, callback) {
    try {
      //浏览器指纹
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const language = navigator.language.toLowerCase();
      const res = await guestLogin({
        signature: result.visitorId,
        language,
      });
      const { data, statusCode } = res;
      if (statusCode === 200) {
        // 清除一下消息未读数
        dispatch("chatIM/clearTotalUnreadCount", 0, { root: true });
        // 清除订阅比赛
        dispatch(
          "competitions/clearSubscribeCompetitionObj",
          {},
          { root: true }
        );
        const {
          access_token,
          refresh_token,
          language_id,
          country_id,
          // expire_in,
        } = data || {};
        // commit("SET_EXPIRE_IN", expire_in);
        commit("SET_TOKEN", access_token);
        setToken(TOKEN_KEY, access_token);
        commit("SET_REFRESHTOKEN", refresh_token);
        setToken(REFRESH_TOKEN_KEY, refresh_token);
        localStorage.setItem("language_id", language_id);
        localStorage.setItem("country_id", country_id);
        const language_abbreviation = languageObj[language_id];
        callback &&
          callback?.({
            country_id,
            language_id,
            languageAbbreviation: language_abbreviation,
          });
      }
    } catch (error) {
      // 游客登录失败，设置默认语言
      const language_id = localStorage.getItem("language_id");
      if (language_id) {
        const languageAbbreviation = languageObj[language_id];
        const translations = require("@/locales/" +
          languageAbbreviation +
          ".js").default;
        // 更新翻译配置
        // 更新 VueI18n 实例的翻译配置
        this.$i18n.locale = languageAbbreviation;
        this.$i18n.setLocaleMessage("en", translations);
      } else {
        //FIXME language_id 3是英文 country_id 1 是其他地区
        localStorage.setItem("language_id", 3);
        localStorage.setItem("country_id", 1);
        const languageAbbreviation = "en";
        const translations = require("@/locales/" +
          languageAbbreviation +
          ".js").default;
        // 更新翻译配置
        // 更新 VueI18n 实例的翻译配置
        this.$i18n.locale = languageAbbreviation;
        this.$i18n.setLocaleMessage("en", translations);
      }
      console.error(error);
    }
  },
  // 注册业务
  async register({ commit }, userInfo) {
    const {
      password,
      email,
      phone,
      code,
      country_id,
      language_id,
      username,
      share_code,
      utc_offset,
      browser_signature,
      browser,
      device
    } = userInfo || {};
    try {
      const res = await register({
        password,
        email,
        phone,
        code,
        username,
        country_id,
        language_id,
        share_code,
        utc_offset,
        browser_signature,
        browser,
        device
      });
      const { data } = res || {};
      // commit("SET_EXPIRE_IN", data.expire_in);
      commit("SET_TOKEN", data.access_token);
      commit("SET_REFRESHTOKEN", data.refresh_token);
      setToken(TOKEN_KEY, data.access_token);
      setToken(REFRESH_TOKEN_KEY, data.refresh_token);
      return "ok";
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // changeExpireIn({ commit }, expire_in) {
  //   commit("SET_EXPIRE_IN", expire_in);
  // },

  // 获取个人信息
  async getInfo({ commit }) {
    const country_id = localStorage.getItem("country_id");
    const language_id = localStorage.getItem("language_id");
    await getUser({
      country_id,
      language_id,
    })
      .then((response) => {
        const data = response.data;
        const { time_zone } = data;
        commit("SET_USERINFOR", data);
        commit("SET_TIME", time_zone?.utc_offset);
        // resolve(data)
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // 退出
  logout({ commit }, callback) {
    return new Promise((resolve) => {
      removeToken(TOKEN_KEY); // must remove  token  first
      removeToken(REFRESH_TOKEN_KEY);
      commit("RESET_STATE");
      localStorage.removeItem("country_id");
      localStorage.removeItem("language_id");
      localStorage.removeItem("timestamp");
      callback && callback();
      resolve();
    });
  },

  // 移除token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(TOKEN_KEY); // must remove  token  first
      removeToken(REFRESH_TOKEN_KEY);
      commit("RESET_STATE");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
