/**
 * 获取本地时间与UTC时间的时区偏移
 * - getTimezoneOffset() 返回的是UTC时间减去本地时间的分钟数，为负值
 * - 如果我们的时区是UTC+2，则返回值将是-120
 * - 改变它的符号来正确表示时区偏移
 * @return { Number } timeZoneOffsetInMinutes 时区偏移量(分钟)
 */
export function getTimeZoneOffset() {
  const now = new Date();
  const timeZoneOffsetInMinutes = -now.getTimezoneOffset();
  // console.log("Time Zone Offset in Minutes:", timeZoneOffsetInMinutes);
  return timeZoneOffsetInMinutes;
}

/**
 * 获取给定时间戳的时刻与当前时刻的差值(单位秒)
 * @param { String } timestamp 
 * @return { String }
 */
export function getSecondsSinceTimestamp(timestamp) {
  var currentTimestamp = Date.now(); // 获取当前时间的时间戳
  var differenceInMilliseconds = currentTimestamp - timestamp; // 毫秒差值
  return Math.ceil(differenceInMilliseconds / 1000); // 转换为秒并返回
}

/**
 * 获取给定时间戳的时刻与当前时刻的差值(单位分钟)
 * @param { String } timestamp 
 * @return { String }
 */
export function getMinutesSinceTimestamp(timestamp) {
  var currentTimestamp = Date.now(); // 获取当前时间的时间戳
  var differenceInMilliseconds = currentTimestamp - timestamp; // 毫秒差值
  return Math.ceil(differenceInMilliseconds / 1000 / 60); // 转换为分钟并返回
}