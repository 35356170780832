<template>
  <!-- 比赛状况 -->
  <div class="MatchInfoSkeleton">
    <!-- 比赛时间 -->
    <div class="match-startTime">
      <el-skeleton-item variant="text" />
    </div>
    <div class="minTime">
      <el-skeleton-item variant="text" />
    </div>

    <div class="teams">
      <div class="team">
        <div class="team-flaga">
          <el-skeleton-item variant="circle" />
        </div>
        <div class="team-name">
          <el-skeleton-item variant="h1" />
        </div>
      </div>
      <div class="center">
        <el-skeleton-item variant="rect" />
      </div>
      <div class="team">
        <!-- 篮球显示主队标志 -->
        <div class="team-flaga">
          <el-skeleton-item variant="circle" />
        </div>
        <div class="team-name">
          <el-skeleton-item variant="h1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "MatchInfoSkeleton",
  props: {},
  components: {
  },
  computed: {},
  data() {
    return {};
  },

  async created() {},
  watch: {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.MatchInfoSkeleton {
  height: 245px;
  background-color: var(--theme-bg);
  padding: 40px 20px 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: var(--theme-matchInfo-bg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 300px;

  .match-startTime {
    margin-bottom: 2px;
    width: 20%;
  }

  .minTime {
    width: 10%;
  }

  .teams {
    width: 100%;
    height: 126px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .team {
      width: 96px;
      height: 126px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .team-flaga {
        width: 61px;
        height: 61px;
        margin-top: 12px;

        .el-skeleton__circle {
          width: 100%;
          height: 100%;
        }
      }

      .team-name {
        margin-top: 5px;
        width: 96px;
        height: 26px;
      }
    }

    .center {
      width: 140px;
      height: 126px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;

      .el-skeleton__rect {
        width: 66%;
        height: 30%;
      }
    }
  }
}
</style>
