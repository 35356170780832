/**
 * @file authCookie
 * @description 定义了处理身份验证令牌cookies的方法。
 */
import Cookies from 'js-cookie'
import { KJUR } from "jsrsasign";
/**
 * ...说明...
 * @const
 * @type {string}
 */

/**
 * 从cookies中获取存储的身份验证令牌。
 * @return {string|null} 如果存在令牌则返回该令牌，否则返回null。
 */
export function getToken(key) {
  return Cookies.get(key)
}

/**
 * 将身份验证令牌存储到cookies中。
 * @param {string} token 要存储的令牌
 * @return {void}
 */
export function setToken(key,token) {
  return Cookies.set(key, token)
}

/**
 * 从cookies中移除身份验证令牌。
 * @return {void}
 */
export function removeToken(key) {
  return Cookies.remove(key)
}

/**
 * 检测token是否过期
 */
export function checkTokenExpired(key) {
  let token = getToken(key);
  if(!token) return true;
  let decoded = KJUR.jws.JWS.parse(token);
  if (decoded) {
    let now = Date.now() / 1000;
    let exp = decoded.payloadObj.exp;
    if (now > exp) {
      return true;
    } else {
      return false;
    }
  } 
}
/**
 * 检测token的身份
 * @param {string} key 要检测的token
 * @return {string} 返回token的身份 newVistor:新访客 user:用户 visitor:临时客户
 */
export function checkTokenRole(key) {
  let token = getToken(key);
  if(!token) return "newVistor";
  let decoded = KJUR.jws.JWS.parse(token);
  if (decoded) {
    let role = decoded.payloadObj.role;
    if(role === "客户"){
      return "user";
    }else if(role === "临时客户"){
      return "visitor";
    }
  }else{
    return "newVisitor"; 
  }

}
/**
 * 检测clienttoken的身份
 */
export function checkClientTokenRole() {
  const TOKEN_KEY = "client_token";
  return checkTokenRole(TOKEN_KEY);
}
  
/**
 * ...说明...
 * @const
 * @type {string}
 */

/**
 * 从cookies中获取存储的身份验证令牌。
 * @return {string|null} 如果存在令牌则返回该令牌，否则返回null。
 */
export function getCookie(key) {
  return Cookies.get(key)
}

/**
 * 将身份验证令牌存储到cookies中。
 * @param {string} token 要存储的令牌
 * @return {void}
 */
export function setCookie(key,value) {
  return Cookies.set(key, value)
}

/**
 * 从cookies中移除身份验证令牌。
 * @return {void}
 */
export function removeCookie(key) {
  return Cookies.remove(key)
}