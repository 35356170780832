<template>
  <div
    class="MatchStatus"
    :class="{
      parentCompetitionDetails: parentComponent === 'CompetitionDetails',
      parentLiveRoom: parentComponent === 'LiveRoom',
    }"
  >
    <!-- 篮球表格数据 -->
    <div class="table-container" v-if="liveCurrentMatch.sport_type_id === 2">
      <div class="fixed-section">
        <table class="styled-table">
          <thead>
            <tr>
              <th>{{ $t("team") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ liveCurrentMatch.away_team }}</td>
            </tr>
            <tr>
              <td class="home">{{ liveCurrentMatch.home_team }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="scrollable-section" ref="scrollableSection">
        <!-- NCAA -->
        <table class="styled-table" v-if="liveCurrentMatch.period_count === 2">
          <thead>
            <tr>
              <th>{{ $t("firstHalf") }}</th>
              <th>{{ $t("secondHalf") }}</th>
              <th v-for="n in maxOvertimes" :key="'QT' + n">QT{{ n }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(score, index) in liveCurrentMatch?.away_scores?.slice(
                  1,
                  3
                )"
                :key="'away-score-' + index"
              >
                {{ score != 0 ? score : "-" }}
              </td>
              <!-- 遍历加时赛得分 -->
              <td v-for="n in maxOvertimes" :key="'away-ot-' + n">
                <span
                  v-if="
                    liveCurrentMatch.away_scores[5] &&
                    liveCurrentMatch.away_scores[5]?.length >= n
                  "
                  >{{ liveCurrentMatch.away_scores[5][n - 1] }}</span
                >
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td
                class="home"
                v-for="(score, index) in liveCurrentMatch?.home_scores?.slice(
                  1,
                  3
                )"
                :key="'away-score-' + index"
              >
                {{ score != 0 ? score : "-" }}
              </td>
              <!-- 遍历加时赛得分 -->
              <td v-for="n in maxOvertimes" :key="'home-ot-' + n">
                <span
                  v-if="
                    liveCurrentMatch.home_scores[5] &&
                    liveCurrentMatch.home_scores[5]?.length >= n
                  "
                  >{{ liveCurrentMatch.home_scores[5][n - 1] }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>

        <!-- 常规赛 -->
        <table class="styled-table" v-else>
          <thead>
            <tr>
              <th>Q1</th>
              <th>Q2</th>
              <th>Q3</th>
              <th>Q4</th>
              <th v-for="n in maxOvertimes" :key="'QT' + n">QT{{ n }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(score, index) in liveCurrentMatch?.away_scores?.slice(
                  1,
                  5
                )"
                :key="'away-score-' + index"
              >
                {{ score != 0 ? score : "-" }}
              </td>
              <!-- 遍历加时赛得分 -->
              <td v-for="n in maxOvertimes" :key="'away-ot-' + n">
                <span
                  v-if="
                    liveCurrentMatch.away_scores[5] &&
                    liveCurrentMatch.away_scores[5]?.length >= n
                  "
                  >{{ liveCurrentMatch.away_scores[5][n - 1] }}</span
                >
              </td>
            </tr>
            <tr>
              <!-- 遍历常规得分 -->
              <td
                class="home"
                v-for="(score, index) in liveCurrentMatch?.home_scores?.slice(
                  1,
                  5
                )"
                :key="'home-score-' + index"
              >
                {{ score != 0 ? score : "-" }}
              </td>
              <!-- 遍历加时赛得分 -->
              <td v-for="n in maxOvertimes" :key="'home-ot-' + n">
                <span
                  v-if="
                    liveCurrentMatch.home_scores[5] &&
                    liveCurrentMatch.home_scores[5]?.length >= n
                  "
                  >{{ liveCurrentMatch.home_scores[5][n - 1] }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-section">
        <table class="styled-table">
          <thead>
            <tr>
              <th>{{ $t("totalScore") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{
                  liveCurrentMatch?.away_scores?.[0] !== 0
                    ? liveCurrentMatch.away_scores[0]
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td class="home">
                {{
                  liveCurrentMatch?.home_scores?.[0] !== 0
                    ? liveCurrentMatch.home_scores[0]
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 队伍栏 -->
    <div class="teamsBar">
      <!-- 客队 -->
      <div class="team awayTeam">
        <div class="teamName">
          {{
            liveCurrentMatch.sport_type_id === 1
              ? liveCurrentMatch.home_team
              : liveCurrentMatch.away_team
          }}
        </div>
        <!-- <div
              class="logo"
              :style="{
                backgroundImage: 'url(' + placeholderLogo.away_team_logo + ')',
              }"
            ></div> -->
        <div
          class="logo"
          :style="{
            backgroundImage:
              liveCurrentMatch.sport_type_id === 1
                ? 'url(' + placeholderLogo.home_team_logo + ')'
                : 'url(' + placeholderLogo.away_team_logo + ')',
          }"
        ></div>
      </div>
      <div class="VS"></div>
      <!-- 主队 -->
      <div class="team homeTeam">
        <div
          class="logo"
          :style="{
            backgroundImage:
              liveCurrentMatch.sport_type_id === 1
                ? 'url(' + placeholderLogo.away_team_logo + ')'
                : 'url(' + placeholderLogo.home_team_logo + ')',
          }"
        ></div>
        <div class="teamName">
          {{
            liveCurrentMatch.sport_type_id === 1
              ? liveCurrentMatch.away_team
              : liveCurrentMatch.home_team
          }}
        </div>
      </div>
    </div>

    <!-- 赛况 -->
    <!-- 足球 -->
    <div class="scheduleData" v-if="liveCurrentMatch.sport_type_id === 1">
      <div class="footballRing">
        <div
          class="footballRingList"
          v-for="(item, index) in footballRing"
          :key="index"
        >
          <div class="data">{{ item.data[0] }}</div>
          <van-circle
            v-model="item.rate"
            :rate="item.rate"
            :text="item.name"
            size="55px"
            :stroke-width="80"
            layer-color="var(--theme-box-2)"
            color="var(--theme-gradient-start)"
            :clockwise="false"
          />
          <div class="data">{{ item.data[1] }}</div>
        </div>
      </div>
      <div class="footballProgressBar">
        <div class="leftData">
          <div class="icon">
            <div class="corner"></div>
            <div class="redCard"></div>
            <div class="yellowCard"></div>
          </div>
          <div class="iconData">
            <div class="title">{{ footballMatchStatus[0].data[0] }}</div>
            <div class="title">{{ footballMatchStatus[2].data[0] }}</div>
            <div class="title">{{ footballMatchStatus[1].data[0] }}</div>
          </div>
        </div>
        <div class="centerData">
          <div
            class="footballProgressBarList"
            v-for="(item, index) in footballProgressBar"
            :key="index"
          >
            <div class="title">
              <div>{{ item?.name }}</div>
            </div>
            <div class="progressDatas">
              <div class="progressData">{{ item.data?.[0] }}</div>
              <van-progress
                class="van-progressA"
                :percentage="getPercentage(item?.data, item.data?.[0])"
                :show-pivot="false"
                track-color="var(--theme-box-2)"
                color="var(--theme-gradient-start)"
                stroke-width="8px"
              />
              <div class="progressData">{{ item.data?.[1] }}</div>
            </div>
          </div>
        </div>
        <div class="leftData">
          <div class="icon">
            <div class="yellowCard"></div>
            <div class="redCard"></div>
            <div class="corner"></div>
          </div>
          <div class="iconData">
            <div class="title">{{ footballMatchStatus[1].data[1] }}</div>
            <div class="title">{{ footballMatchStatus[2].data[1] }}</div>
            <div class="title">{{ footballMatchStatus[0].data[1] }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 篮球 -->
    <div class="scheduleData" v-if="liveCurrentMatch.sport_type_id === 2">
      <!-- 篮球 -->
      <div class="basketballCenterData">
        <div class="otherData">
          <div class="item">
            <div class="title">{{ matchStatus[4]?.name }}</div>
            <div class="title">
              {{ matchStatus[4]?.data[1] }}
            </div>
          </div>
          <div class="item">
            <div class="title">{{ matchStatus[3]?.name }}</div>
            <div class="title">
              {{ matchStatus[3]?.data[1] }}
            </div>
          </div>
        </div>
        <div class="ProgressBarList">
          <div
            class="basketballProgressBarList"
            v-for="(item, index) in basketballProgressBar"
            :key="index"
          >
            <div class="title">
              {{ item?.name }}
            </div>
            <div class="progressDatas">
              <div class="progressData">{{ item.data?.[1] }}</div>
              <van-progress
                class="van-progressB"
                :percentage="getPercentage(item?.data, item.data?.[1])"
                :show-pivot="false"
                track-color="var(--theme-box-2)"
                color="var(--theme-gradient-start)"
                stroke-width="4px"
              />
              <div class="progressData">{{ item.data?.[0] }}</div>
            </div>
          </div>
        </div>
        <div class="otherData">
          <div class="item">
            <div class="title">{{ matchStatus[4]?.name }}</div>
            <div class="title">
              {{ matchStatus[4]?.data[0] }}
            </div>
          </div>
          <div class="item">
            <div class="title">{{ matchStatus[3]?.name }}</div>
            <div class="title">
              {{ matchStatus[3]?.data[0] }}
            </div>
          </div>
        </div>
      </div>
      <!-- 篮球赛况 -->
      <!-- <div class="basketballRing">
          <div
            class="basketballRingList"
            v-for="(item, index) in basketballRing"
            :key="index"
          >
            <div class="data">{{ item.data[0] }}</div>
            <van-circle
              v-model="item.rate"
              :rate="item.rate"
              :text="item.name"
              size="40px"
              :stroke-width="50"
              layer-color="#7232dd"
              color="#282641"
              :clockwise="false"
            />
            <div class="data">{{ item.data[1] }}</div>
          </div>
        </div> -->
    </div>
    <!-- 无赛况数据 -->
    <!-- <div
        class="noScheduleData"
        v-if="matchStatus??.length === 0 || matchStatus == ''"
      >
        <NoData>{{ $t("noDataPrompt") }}</NoData>
      </div> -->
  </div>
</template>

<script>
import {
  getFootballLiveStats,
  getBasketballLiveStats,
} from "@/services/api/Competition";
// import NoData from "@/components/NoData/Index.vue";
// import toast from "@/utils/toast.js";
import { mapState } from "vuex";
export default {
  name: "MatchStatus",
  components: {
    // NoData,
  },
  props: {
    // 比赛ID
    competitionId: {
      type: String,
      require: true,
    },
    /**
     *  双方队伍的信息
     *  - key: `home_team` `home_team_logo` `away_team` `away_team_logo`
     */
    // teamsInfo: {
    //   type: Object,
    //   require: true,
    // },
    /**
     * 父组件
     * - 赛事详情页: CompetitionDetails
     * - 直播间: LiveRoom
     */
    parentComponent: {
      type: String,
      require: true,
    },
    // competitionData: {
    //   type: Object,
    //   require: true,
    // },
    TMoptionActivity: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      // 足球数据 2-角球 3-黄牌 4-红牌 13-半场比分 21-射正 22-射偏 23-进攻 24-危险进攻
      footballMatchStatus: [
        { id: 2, name: this.$t("cornerKick"), data: [0, 0] },
        { id: 3, name: this.$t("yelowCard"), data: [0, 0] },
        { id: 4, name: this.$t("redCard"), data: [0, 0] },
        { id: 13, name: this.$t("halfTimeScore"), data: [0, 0] },
        { id: 21, name: this.$t("shotOnTarget"), data: [0, 0] },
        { id: 22, name: this.$t("missedShot"), data: [0, 0] },
        { id: 23, name: this.$t("attack"), data: [0, 0] },
        { id: 24, name: this.$t("dangerousAttack"), data: [0, 0] },
      ],
      footballRing: [],
      footballProgressBar: [],
      footballCard: [],
      // 篮球数据 1-三分球 2-两分球 3-罚球 4-暂停 5-犯规 6-罚球命中率 7-剩余暂停 8-三分命中率 9-两分命中率 10-投篮命中率 11-罚球命中率
      matchStatus: [
        { name: this.$t("number3PointGoals"), data: [0, 0], id: 1 },
        { name: this.$t("number2PointGoals"), data: [0, 0], id: 2 },
        { name: this.$t("penaltyKickGoals"), data: [0, 0], id: 3 },
        { name: this.$t("numberOfPausesRemaining"), data: ["-", "-"], id: 4 },
        { name: this.$t("numberOfFouls"), data: ["-", "-"], id: 5 },
        { name: this.$t("freeThrowPercentage"), data: [0, 0], id: 6 },
        { name: this.$t("totalNumberOfPauses"), data: ["-", "-"], id: 7 },
        { name: this.$t("threePointShot"), data: [0, 0], rate: 50, id: 8 },
        { name: this.$t("twoPointShot"), data: [0, 0], rate: 50, id: 9 },
        { name: this.$t("shoot"), data: [0, 0], rate: 50, id: 10 },
        {
          name: this.$t("freeThrowPercentage"),
          data: [0, 0],
          rate: 50,
          id: 11,
        },
      ],
      // 篮球模拟数据
      basketballProgressBar: [],
      basketballRing: [
        { name: this.$t("threePointShot"), data: [0, 0], rate: 50, id: 8 },
        { name: this.$t("twoPointShot"), data: [0, 0], rate: 50, id: 9 },
        { name: this.$t("shoot"), data: [0, 0], rate: 50, id: 10 },
        {
          name: this.$t("freeThrowPercentage"),
          data: [0, 0],
          rate: 50,
          id: 11,
        },
      ],
      placeholderLogo: {
        home_team_logo: require("@/assets/client-logos/nodata.webp"),
        away_team_logo: require("@/assets/client-logos/nodata.webp"),
      }, // 球队logo的占位符
      competitionTimer: null,
      // 最新请求
      latestRequest: 0,
      imageBaseUrl: "", // 图片基础路径
    };
  },
  watch: {
    TMoptionActivity: {
      handler: function (newValue) {
        this.replaceBackgroundImage();
        if (newValue === "matchStatus") {
          this.setCompetitionTimer();
        } else {
          this.clearCompetitionTimer();
        }
      },
      immediate: true,
    },
    liveCurrentMatch: {
      handler: function () {
        console.log(123)
        this.replaceBackgroundImage();
      },
      immediate: true,
    },
  },
  created() {
    // FIXME: 后端暂时没有跑域名的服务，使用提供logo图片路径 ""
    // const basePathStr = localStorage.getItem("basePathObj");
    // const basePathObj = JSON.parse(basePathStr) || {};
    // this.imageBaseUrl = basePathObj?.fileServer || "";
  },
  async mounted() {
    await this.loadCompetitionStatus(
      this.competitionId,
      Number(localStorage.getItem("language_id"))
    );
    this.replaceBackgroundImage();
  },
  beforeDestroy() {
    this.clearCompetitionTimer();
  },
  computed: {
    ...mapState({
      liveCurrentMatch: (state) => state.competitions.liveCurrentMatch,
    }),
    maxOvertimes() {
      // 计算最大加时赛轮数
      const awayOvertimes =
        this.liveCurrentMatch.away_scores[
          this.liveCurrentMatch.away_scores?.length - 1
        ];
      const homeOvertimes =
        this.liveCurrentMatch.home_scores[
          this.liveCurrentMatch.home_scores?.length - 1
        ];
      return Math.max(awayOvertimes?.length, homeOvertimes?.length);
    },
    // footballMatchStatus(){
    //   return this.footballMatchStatus;
    // }
  },
  methods: {
    /**
     * 动态替换头像
     */
    replaceBackgroundImage() {
      ["home_team_logo", "away_team_logo"].forEach((team) => {
        const img = new Image();
        img.src = this.imageBaseUrl + this.liveCurrentMatch[team];
        img.onload = () => {
          // 图像加载完成后，更新头像
          this.placeholderLogo[team] =
            this.imageBaseUrl + this.liveCurrentMatch?.[team];
        };
        img.onerror = () => {
          console.log('Failed to load image');
          this.placeholderLogo[team] = require("@/assets/client-logos/nodata.webp")
        };
      });
    },
    /**
     * 加载指定赛事的赛况数据
     * @param {number} match_id 比赛id
     * @param {number} language_id 语言id
     */
    async loadCompetitionStatus(match_id, language_id) {
      const currentRequest = ++this.latestRequest;
      try {
        const getCompetitionStatus =
          this.liveCurrentMatch.sport_type_id === 1
            ? getFootballLiveStats
            : getBasketballLiveStats;
        const res = await getCompetitionStatus({ match_id, language_id });
        const { data, statusCode } = res;
        if (statusCode === 200) {
          // 保证请求的数据是最新的
          if (currentRequest === this.latestRequest) {
            const { items } = data || [];
            const formatData =
              items.map((item) => {
                return {
                  ...item,
                  data: [item.home, item.away],
                  id: item.type,
                };
              }) || [];

            if (this.liveCurrentMatch.sport_type_id === 1) {
              if (formatData?.length > 0) {
                // console.log("formatData: ", formatData);
                this.footballMatchStatus = formatData;
              }

              await this.dataClassificationFootball();
            } else {
              if (formatData?.length > 0) {
                this.matchStatus = formatData;
                // console.log("formatData: ", formatData);
              }
              await this.dataClassificationBasketball();
            }
          }
        }

        // await watiToast.clear();
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 定时请求比赛详情
     */
    setCompetitionTimer() {
      this.competitionTimer = setInterval(async () => {
        await this.loadCompetitionStatus(
          this.competitionId,
          Number(localStorage.getItem("language_id"))
        );
      }, 3 * 1000);
    },
    /**
     * 清除定时请求赛事列表数据（即时和直播）
     */
    clearCompetitionTimer() {
      if (this.competitionTimer) {
        clearInterval(this.competitionTimer);
        this.competitionTimer = null;
      }
    },

    // 足球篮球赛况数据分类处理
    dataClassificationFootball() {
      // 足球数据分类 13-半场比分 23-进攻 24-危险进攻
      const footballRings = this.footballMatchStatus.filter((item) => {
        // return [13, 23, 24].includes(item.id);
        return [23, 24].includes(item.id);
      });
      this.footballRing = footballRings.map((item) => {
        let rate =
          item.data?.length >= 2 && item.data[0] + item.data[1] !== 0
            ? Math.round(
                100 - (item.data[1] / (item.data[0] + item.data[1])) * 100
              )
            : 0;
        // 都为0时默认为50，进度条各占一半
        if (item.data?.[0] === 0 && item.data?.[1] === 0) {
          rate = 50;
        }
        return { ...item, rate };
      });
      this.footballProgressBar = this.footballMatchStatus.filter((item) => {
        return [21, 22].includes(item.id);
      });
      this.footballCard = this.footballMatchStatus.filter((item) => {
        return [2, 3, 4].includes(item.id);
      });
    },
    dataClassificationBasketball() {
      this.basketballProgressBar = this.matchStatus.filter((item) => {
        return [1, 2, 3, 6].includes(item.id);
      });
      if (this.matchStatus?.length > 9) {
        //篮球数据分类
        const basketballRings = this.matchStatus.filter((item) => {
          return [8, 9, 10, 11].includes(item.id);
        });
        this.basketballRing = basketballRings.map((item) => {
          let rate =
            item.data?.length >= 2 && item.data[0] + item.data[1] !== 0
              ? Math.round((item.data[1] / (item.data[0] + item.data[1])) * 100)
              : 0;
          // 都为0时默认为50，进度条各占一半
          if (item.data?.[0] === 0 && item.data?.[1] === 0) {
            rate = 50;
          }
          return { ...item, rate };
        });
      }
      // console.log(this.basketballProgressBar);
    },
    /**
     * 获取赛况数据占满分数据的百分比
     */
    getPercentage(data, currentData) {
      if (!data || data?.length === 0 || !currentData) {
        // 都为0时默认为50，进度条各占一半
        if (data?.[0] === 0 && data?.[1] === 0) return 50;
        return 0;
      }
      const total = data[0] + data[1];
      return total === 0 ? 50 : Math.round((currentData / total) * 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
