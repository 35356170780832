<template>
  <div class="AnchorInfoCardSkeleton">
    <div class="top">
      <div class="left">
        <!-- 头像 -->
        <div class="avatar"><el-skeleton-item variant="image" /></div>
        <div class="AnchorM">
          <!-- 昵称 -->
          <div class="anchorName"><el-skeleton-item variant="h1" /></div>
          <!-- id/username -->
          <div class="anchorId"><el-skeleton-item variant="p" /></div>
        </div>
      </div>
      <div class="subscription">
        <!-- 订阅按钮 -->
        <div class="button"><el-skeleton-item variant="rect" /></div>
        <!-- 订阅人数 -->
        <div class="followerNumber"><el-skeleton-item variant="p" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.AnchorInfoCardSkeleton {
  width: 100%;
  background: var(--theme-box-3);
  height: auto;
  padding: 15px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top {
    width: 100%;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      height: 43px;
      width: 225px;
      display: flex;
      align-items: center;

      .avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        box-sizing: border-box;
        overflow: hidden;

        .el-skeleton__item.el-skeleton__image {
          height: 100%;
          width: 100%;
        }
      }

      .AnchorM {
        width: 177px;
        height: 100%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .anchorName {
          width: 100%;
          height: 22px;

          .el-skeleton__item {
            height: 100%;
            width: 90%;
          }
        }

        .anchorId {
          width: 100%;
          height: 17px;

          .el-skeleton__item {
            height: 100%;
            width: 60%;
          }
        }
      }
    }

    .subscription {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .button {
        height: 26px;
        width: 88px;
        border-radius: 5px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        .el-skeleton__item {
          height: 100%;
        }
      }

      .followerNumber {
        width: 66px;
        height: 17px;
      }
    }
  }
}
</style>
