<template>
  <div class="recommendStreamerListItem">
    <div class="itemHeader" @click="toSchemeDetails(data)">
      <div
        class="streamerInfo"
        @click.stop="toStreamerRecommendDetail(data.publisher.username)"
      >
        <!-- 头像 -->
        <van-image
          :src="imageBaseUrl + data.publisher.avatar"
          fit="cover"
          class="avatar"
          round
        >
          <template v-slot:loading>
            <van-image
              fit="cover"
              round
              class="avatar"
              :src="placeholderAvatar"
            >
            </van-image>
          </template>
          <template v-slot:error>
            <van-image
              fit="cover"
              round
              class="avatar"
              :src="placeholderAvatar"
            >
            </van-image>
          </template>
        </van-image>
        <div class="info">
          <!-- 主播名称 -->
          <div class="text">{{ data.publisher.name }}</div>
          <!-- 主播标签 -->
          <div class="tags">
            <div class="tagItem" v-for="tag in data.publisher.tags" :key="tag">
              {{ tag }}
            </div>
            <!-- <div class="tagItem" v-for="(tag, index) in [1, 2]" :key="index">
              {{ "季度盈利" }}
            </div> -->
          </div>
        </div>
      </div>
      <!-- 命中率 -->
      <div class="displayHit" v-if="displayHit">
        <div class="hitRate" v-if="activeTab === 'STREAK'">
          <div class="data">
            <span
              class="value"
              v-if="data.publisher?.winning_streak !== null"
              >{{ data.publisher?.winning_streak }}</span
            >
          </div>
          <div class="text" v-if="data?.publisher?.winning_streak !== null">
            {{ $t("STREAK") }}
          </div>
        </div>
        <div class="hitRate" v-else>
          <div class="data">
            <span class="value" v-if="data.publisher?.hit !== null">{{
              (Number(data.publisher?.hit?.rate) * 100).toFixed(2)
            }}</span
            ><span class="symbol" v-if="data?.publisher?.hit !== null">%</span>
          </div>
          <div class="text" v-if="data?.publisher?.hit !== null">
            {{ $t(data.publisher?.hit?.type) }}
          </div>
        </div>
      </div>
      <div class="hideHit" v-else>
        <!-- 推荐结果 -->
        <!-- <div class="result" v-if="data?.hit">W</div> -->
        <div
          class="result"
          :class="{ D: !data?.hit }"
          v-if="data?.status === 'END'"
        >
          {{ data?.hit ? "W" : "D" }}
        </div>
        <div class="status" v-else>{{ data?.match?.status }}</div>
      </div>
    </div>
    <div class="itemContent" @click="toSchemeDetails(data)">
      <div class="title" @click="toSchemeDetails(data)">
        {{ data.title }}
      </div>
      <!-- 比赛信息 -->
      <div class="competitionInfo" @click="toSchemeDetails(data)">
        <div class="competitionInfoContent">
          <div class="title">
            <div class="text">{{ data.match.competition.name }}</div>
            <div class="text">
              {{ formatTime(data.match.match_time) }}
            </div>
          </div>
          <div class="divider"></div>
          <div class="teamContent">
            <div
              class="content"
              :class="{ reverse: data?.sport_type_id === 2 }"
            >
              <div class="homeTeam teamName">{{ fromHome ? data.match.home.team_name : data.match.home.name }}</div>
              <div class="awayTeam teamName">{{ fromHome ? data.match.away.team_name : data.match.away.name }}</div>
              <!-- {{ data.competition.name }} -->
            </div>
            <div class="vs">
              <img
                width="21"
                height="21"
                src="@/assets/icons/Common/vs.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 底部栏 -->
      <div class="bottomArea">
        <div class="leftArea">
          <!-- 玩法 -->
          <div class="playType">{{ data?.selection_type }}</div>
          <!-- 购买人数 -->
          <div class="buyCount">
            <!-- <span class="count">{{ data.buy_count }}</span> -->
            <span class="count">{{ data.view_count }}</span>
            <span class="viweIcon"></span>
          </div>
        </div>
        <div class="rightArea">
          <div class="coin"></div>
          <div class="text">
            {{ data.price === 0 ? $t("free") : data.price }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "RecommendStreamerListItem",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    userInfo: {
      type: Object,
    },
    displayHit: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: String,
    },

    fromHome: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  data() {
    return {
      placeholderAvatar: require("@/assets/client-logos/nodata.webp"),
      imageBaseUrl: "",
    };
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
  },
  methods: {
    formatTime(time) {
      return dayjs(time * 1000).format("MM/DD HH:mm");
    },
    /**
     * 跳转路由-主播方案详情
     */
    toStreamerRecommendDetail(username) {
      // 判断当前是否在主播方案详情页
      if (this.$route.name === "AnchorIntroduction") {
        return;
      }

      this.$router.push({
        name: "AnchorIntroduction",
        query: {
          id: username,
        },
      });
    },

    /**
     * 跳转路由-方案详情
     */
    toSchemeDetails(data) {
      const { sport_type_id, id } = data;
      this.$router.push({
        name: "SchemeDetails",
        query: {
          sport_type_id,
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
