<template>
  <div class="square">
    <!-- 移动端 -->
    <div class="Mobile">
      <!-- <div class="squareTitle">
        <div class="hotLogo"></div>
        <div class="viewNumber" v-if="squareData.startTime">
          {{ squareData.viewsNumber }}{{ $t("watchPrompt") }}
        
        </div>
        <div class="viewNumber" v-else>
          {{ squareData.viewsNumber }}{{ $t("watchPrompt") }}
        </div>
      </div> -->
      <!-- <div class="squareTitle"></div> -->
      <div
        class="MsquareC"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <div class="welcomeCardWrapper">
          <WelcomeCard
            v-if="isShowWelcome && cardInfo"
            @toStreamerPrivateChat="toStreamerPrivateChat"
            :cardInfo="cardInfo"
            :TMoptionActivity="TMoptionActivity"
          ></WelcomeCard>
        </div>
        <div
          class="messageListWrapper"
          ref="messageListWrapper"
          @scroll="handleScroll"
          @touchmove="handleScroll"
        >
          <div class="messageLists">
            <ul
              class="messageItem"
              v-for="(item, key) in messageList"
              :key="key"
            >
              <div v-if="item.content_type === 'notice'" class="notice">
                <NoticeMessage :message="item?.content[0]" />
              </div>
              <li v-else class="item">
                <span
                  :class="item.sender?.role"
                  v-if="item.sender?.role !== 'audience'"
                >
                  {{ $t(`${item.sender?.role}`) }}
                </span>
                <span
                  class="live-anchor-name"
                  :style="{
                    color:
                      item.sender?.role !== 'audience'
                        ? ' var(--theme-score-word)'
                        : '#fff',
                  }"
                >
                  {{ item.sender.name }}:
                </span>
                <span class="dialog">
                  <span
                    class="text-content"
                    :style="{
                      color:
                        item.sender?.role !== 'audience'
                          ? '#b47b39'
                          : '#c7c8ca',
                    }"
                  >
                    <!-- {{ renderMessageText(item.content) }} -->
                    <MessageContent :content="item.content" mode="square" />
                  </span>
                </span>
              </li>
            </ul>
            <div ref="endElement"></div>
          </div>
        </div>
      </div>
      <div class="newMessageTip">
        <div class="tipContent" @click="clickNewMessageTip" v-if="tipVisible">
          {{ $t("newMessage") }}
        </div>
      </div>
    </div>
    <div class="inptChat">
      <div class="input-selection" v-if="!showInput" @click="gotoLogin">
        <van-field
          v-model="newMessage"
          rows="1"
          autosize
          type="textarea"
          maxlength="1024"
          class="custom-input"
          id="send_M"
          :readonly="true"
          :placeholder="$t(placeholderText)"
        />
        <!-- <div class="iconRight"></div> -->
      </div>
      <div class="input-selection" v-else>
        <div class="cancelArea" @click.stop="closeInput">
          {{ $t("cancelSend") }}
        </div>
        <div class="confirmArea" @click.stop="sendMessage">
          {{ $t("confirmSend") }}
        </div>
      </div>
    </div>
    <van-popup
      v-model="showInput"
      position="top"
      class="inputPopup"
      :overlay="false"
    >
      <div class="input-selection">
        <!-- <div class="backIcon" @click="closeInput"></div> -->
        <div class="left">
          <div class="closeArea" @click="closeInput"></div>
        </div>
        <van-field
          v-model="newMessage"
          rows="1"
          autosize
          type="textarea"
          maxlength="1024"
          class="custom-input"
          id="send_M"
          :placeholder="$t(placeholderText)"
          :style="{ pointerEvents: role !== 'user' ? 'none' : '' }"
          ref="realInput"
        />
        <div class="right">
          <div class="iconRight" @click="sendMessage"></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { debounce } from "lodash";
import WelcomeCard from "@/components/WelcomeCard/Index.vue";
import MessageContent from "@/components/MessageContent/Index.vue";
import NoticeMessage from "@/components/NoticeMessage/Index.vue";
// import emoji from "@/components/EmojiCom/Index.vue";
import { liveChatSendMessage } from "@/services/api/LiveChat";
import { getSquareTopCardInfo } from "@/services/api/Live";
import { renderMessage } from "@/utils/tools";
// import BScroll from "@better-scroll/core";
// import ObserveDOM from "@better-scroll/observe-dom";
// BScroll.use(ObserveDOM);
import { checkClientTokenRole } from "@/utils/authCookie";
export default {
  components: {
    // emoji,
    MessageContent,
    NoticeMessage,
    WelcomeCard,
  },
  props: {
    // required: true,
    userInfo: Object,
    messages: [],
    squareData: Object,
    TMoptionActivity: String,
  },
  watch: {
    messages: {
      handler: function (newValue, oldValue) {
        let old;
        if (oldValue) {
          old = [...oldValue];
        }
        this.messageList = newValue;
        // const isBottom = this.bScroll ? this.isAtBottom(this.bScroll) : false;
        const isBottom = this.$refs.messageListWrapper
          ? this.isAtBottom(this.$refs.messageListWrapper)
          : false;
        if (old?.length === 0 || isBottom) {
          this.scrollToBottom();
        }
        if (old && old?.length !== 0 && !isBottom) {
          this.tipVisible = true;
        }
      },
      deep: true,
      immediate: true,
    },
    TMoptionActivity: {
      handler: function (val) {
        if (val === "streamChat") {
          this.scrollToBottom();
        }
      },
      deep: true,
    },

    "$route.query.liveId": {
      handler() {
        this.liveId = this.$route.query.liveId;
        this.loadCardInfo(this.liveId);
      },
    },
  },
  data() {
    return {
      // historyMessages: [],      //广场历史消息
      messageList: [],
      newMessage: "", // 用户输入的新消息
      avatar: "", //主播头像
      Showfloating: true,
      isInputFocused: false,
      role: "", // 0未登录 1已登录
      placeholderText: "InputSearchPrompt",
      liveId: "", //直播间id
      showEmoji: false,
      // bScroll: null,
      //   发送防抖
      canSend: true,
      tipVisible: false, // 新消息提示是否出现
      showInput: false, // 是否显示输入框
      //  广场欢迎卡片
      isShowWelcome: true,
      cardInfo: null,
    };
  },

  async created() {
    this.role = checkClientTokenRole();
    if (this.role !== "user") {
      this.placeholderText = "sendMessageNeedLoginPrompt";
    }
    //获取本地直播间id
    this.liveId = this.$route.query.liveId;
    await this.loadCardInfo(this.liveId);
  },
  async mounted() {
    // if (!this.bScroll) {
    //   await this.initScroll(); // 初始化 BetterScroll
    //   await this.scrollToBottom();
    // }
    if (!this.$refs.endElement) {
      this.scrollToBottom();
    }
  },
  updated() {
    // if (!this.bScroll) {
    //   this.initScroll(); // 初始化 BetterScroll
    // } else {
    //   this.bScroll.refresh();
    // }
  },
  beforeDestroy() {
    // this.destroyScroll();
  },
  methods: {
    /**
     * 加载卡片的信息
     */
    async loadCardInfo(live_id) {
      // console.log("loadCardInfo", live_id);
      if (!live_id) {
        return;
      }
      try {
        const { data, statusCode } = await getSquareTopCardInfo(
          {
            country_id: localStorage.getItem("country_id"),
            live_id,
            language_id: localStorage.getItem("language_id"),
          },
          live_id
        );
        if (statusCode && statusCode === 200) {
          this.cardInfo = data;
          this.isShowWelcome = data.status;
        }
      } catch (error) {
        console.error(error);
      }
    },

    gotoLogin() {
      // console.log("gotoLogin");
      if (this.role !== "user") {
        sessionStorage.setItem("preLoginRoute", this.$route.fullPath);
        sessionStorage.setItem("liveRoomTabActivity", this.TMoptionActivity);
        this.$router.push({ name: "LogInRegister" });
      } else {
        this.handleInputFocus();
      }
    },
    closeInput() {
      this.newMessage = "";
      this.showInput = false;
    },
    MEmotion(emotion) {
      let input = document.getElementById("send_M");
      let startPos = input.selectionStart;
      let endPos = input.selectionEnd;
      this.newMessage =
        input.value.substring(0, startPos) +
        emotion +
        input.value.substring(endPos);
    },

    handleInputFocus() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs.realInput.focus();
      });
    },
    handleInputBlur() {
      this.isInputFocused = false;
    },
    // 消息数据渲染
    renderMessageText(content = []) {
      return renderMessage(content);
    },
    showMessageLoading() {
      const arr = [...this.messageList];
      arr.push({
        send_at: Date.now(),
        message_id: "loading",
        sender: {
          name: this.userInfo.name,
          username: this.userInfo.username,
          avatar: this.userInfo?.avatar,
        },
        content: [
          {
            type: "loading",
          },
        ],
      });
      setTimeout(() => {
        this.scrollToBottom();
      }, 50);
    },
    hideMessageLoading() {
      const index = this.messageList.findIndex(
        (item) => item.message_id === "loading"
      );
      if (index !== -1) {
        this.messageList.splice(index, 1);
      }
    },

    //广场发送消息
    async sendMessage() {
      if (!this.canSend) return;
      const message = this.newMessage.trim();
      this.closeInput();
      if (message !== "") {
        let content = [
          {
            type: "text",
            data: {
              text: message,
            },
          },
        ];
        let ParamData = {
          live_id: this.liveId,
          content,
        };
        const messageData = {
          send_at: Date.now(),
          sender: {
            name: this.userInfo.name,
            username: this.userInfo.username,
            avatar: this.userInfo.avatar,
            role: "audience",
          },
          content,
        };
        try {
          this.canSend = false;
          this.showMessageLoading();
          const { data, statusCode } = await liveChatSendMessage(ParamData);
          const arr = [...this.messageList];
          if (statusCode === 200) {
            this.hideMessageLoading();
            this.canSend = true;
            const cur = this.messages.find(
              (item) => Number(item.message_id) === Number(data.message_id)
            );
            this.newMessage = "";
            if (cur) return;
            arr.push(data);
            this.$emit("changeMessages", arr);
            this.scrollToBottom();
          }
          // else if (statusCode === 204) {
          //   this.$vantToast({
          //     type: "fail",
          //     message: this.$t("bannedSendPrompt"),
          //   });
          //   this.hideMessageLoading();
          //   this.canSend = true;
          //   this.newMessage = "";
          //   arr.push(messageData);
          //   this.$emit("changeMessages", arr);
          //   this.scrollToBottom();
          // }
          else {
            this.hideMessageLoading();
            messageData["send_error"] = true;
            arr.push(messageData);
            this.$emit("changeMessages", arr);
            this.newMessage = "";
            this.scrollToBottom();
            this.canSend = true;
          }
        } catch (error) {
          const { response } = error;
          if (response.status === 400) {
            const { data } = response;
            console.log(data.detail.msg);
            const arr = [...this.messageList];
            if (data.detail.msg === "信息包含敏感词。") {
              this.hideMessageLoading();
              this.canSend = true;
              this.newMessage = "";
              arr.push(messageData);
              this.$emit("changeMessages", arr);
              this.scrollToBottom();
            }
            if (data.detail.msg === "你已被禁言。") {
              this.$vantToast({
                type: "fail",
                message: this.$t("bannedSendPrompt"),
              });
              this.hideMessageLoading();
              this.canSend = true;
              this.newMessage = "";
              arr.push(messageData);
              this.$emit("changeMessages", arr);
              this.scrollToBottom();
            }
            return;
          }
          this.$vantToast({
            type: "fail",
            message: this.$t("sendError"),
          });
          this.hideMessageLoading();
          this.canSend = true;
        }
      }
    },
    /**
     * 初始化better-scroll实例
     */
    // initScroll() {
    //   this.$nextTick(() => {
    //     this.bScroll = new BScroll(this.$refs.messageListWrapper, {
    //       click: true, // 允许点击事件
    //       // probeType: 3, // 启用 probeType 来监听滚动事件
    //       pullDownRefresh: true, // 开启下拉刷新
    //       observeDOM: true, // 开启 DOM 观察者
    //       useTransition: false, // 使用 requestAnimationFrame 做动画
    //       bounce: {
    //         top: false,
    //       },
    //     });
    //     // this.bScroll.on("pullingDown", this.pullingDownHandler); // 监听下拉事件

    //
    //     this.bScroll.on("scroll", () => {
    //       if (this.isAtBottom(this.bScroll)) {
    //         // 到达底部，执行相应操作
    //         this.tipVisible = false;
    //       }
    //     });
    //   });
    // },

    /**
     * 重新计算 BetterScroll
     * 当 DOM 结构发生变化的时候务必要调用确保滚动的效果正常
     */
    // refreshScroll() {
    //   this.$nextTick(() => {
    //     if (this.bScroll) {
    //       this.bScroll.refresh();
    //     } else {
    //       this.initScroll();
    //     }
    //   });
    // },

    /**
     * 销毁 better-scroll 实例。
     * 在 Vue 组件销毁前调用，用于清理和释放滚动实例占用的资源，防止内存泄漏。
     */
    // destroyScroll() {
    //   this.bScroll && this.bScroll.destroy();
    // },

    // 滑动到底部
    scrollToBottom() {
      // setTimeout(() => {
      //   this.bScroll && this.bScroll.scrollTo(0, this.bScroll.maxScrollY, 0);
      // }, 50);

      this.$nextTick(() => {
        this.$refs.endElement?.scrollIntoView();
      });
    },

    handleScroll: debounce(function () {
      if (this.isAtBottom(this.$refs.messageListWrapper)) {
        // 到达底部，执行相应操作
        this.tipVisible = false;
      }
    }, 200),

    windowClose() {
      this.Showfloating = false;
    },

    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchEnd(event) {
      const endX = event.changedTouches[0].clientX;
      const deltaX = endX - this.startX;
      if (deltaX > 100) {
        this.handleSwipeRight();
      }
    },
    handleSwipeRight() {
      // 处理右滑事件的逻辑
      this.$emit("closeDrawer");
      // 在这里执行你希望触发的事件
    },

    /**
     * 接收来自WelcomeCard组件的事件
     */
    toStreamerPrivateChat() {
      this.$emit("changeTabToStreamerPrivateChat");
    },

    /**
     * 判断是否在滚动容器底部
     * @param {bs} bs better-scroll实例
     */
    isAtBottom(scrollContainer) {
      // 使用Math.abs是因为这些值都是负数
      // 根据需要设置一个阈值来判断是否“接近”底部，这里以0为例
      // const threshold = 0; // 可以调整这个阈值，以允许一定的误差范围
      // return Math.abs(bs.y) - Math.abs(bs.maxScrollY) >= -threshold;

      return (
        scrollContainer.scrollHeight - scrollContainer.scrollTop ===
        scrollContainer.clientHeight
      );
    },

    clickNewMessageTip() {
      this.scrollToBottom();
      this.tipVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
.el-textarea.is-disabled .el-textarea__inner {
  background-color: #0d0b21 !important;
  color: #fff !important;
}
.van-cell::after {
  border-bottom: none !important;
}
</style>
