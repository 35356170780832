<template>
  <div class="WelcomeCard">
    <div class="welcomeCardContent">
      <!-- 主播头像 -->
      <!-- <div class="streamerAvatar">
        <img :src="placeholderAvatar" alt="UFOScore" />
      </div> -->
      <!-- 欢迎信息 -->
      <div class="welcomeInfo">
        <van-notice-bar ref="notice-bar" color="var(--theme-main-word)" background="var(--theme-box-1)">
          {{ dynamicInfo }}
        </van-notice-bar>
      </div>
      <div class="intoGroupChat">
        <button class="nativeButton" @click="toGroupChat">
          {{ $t("join") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from "dompurify";
export default {
  name: "WelcomeCard",
  components: {},
  props: {
    cardInfo: {
      type: Object,
    },
    TMoptionActivity: {
      type: String,
    }
    
  },
watch: {
  TMoptionActivity: {
      handler: function (val) {
        if (val === "streamChat") {
          this.$refs["notice-bar"].reset();
        }
      },
      deep: true,
    },
},
  data() {
    return {
      placeholderAvatar: require("@/assets/client-logos/nodata.webp"),
      dynamicInfo: null, // 文案信息
      avatar: null, // 头像
      imageBaseUrl: "",
    };
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
  },
  mounted() {
    const { content, sender_avatar } = this.cardInfo || "";
    this.dynamicInfo = content;
    this.avatar = sender_avatar;
  },
  methods: {
    /**
     * 进入群聊 跳转路由-聊天
     * @param { String } targetId username 好友用户名/id
     * @param { String } targetName name 好友昵称
     * @param { String } targetAvatar avatar 好友头像
     */
    toGroupChat() {
      this.$emit("toStreamerPrivateChat");
    },

    /**
     * 动态替换头像
     */
    replaceAvatar() {
      // 预加载完整图像
      const img = new Image();
      img.src = this.imageBaseUrl +  this.avatar;
      img.onload = () => {
        // 图像加载完成后，更新头像
        this.placeholderAvatar = this.imageBaseUrl +  this.avatar;
      };
    },

    /**
     * 清洗HTML 防止XSS攻击
     */
    sanitizeHTML(html) {
      return DOMPurify.sanitize(html);
    },
  },
};
</script>

<style lang="scss" scoped>
.WelcomeCard {
  // width: 355px;
  width: 100%;
  max-height: 120px;
  background-color: var(--theme-box-1);
  // border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  .welcomeCardContent {
    width: 100%;
    display: flex;
    align-items: center;
    .streamerAvatar {
      width: 35px;
      height: 100%;

      img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }
    }

    .welcomeInfo {
      width: 100%;
      box-sizing: border-box;
      padding:0px 5px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      line-height: 18px;
      color: var(--theme-main-word);

      .guideWord {
        color: #f56e14;
      }
    }

    .intoGroupChat {
      // margin-top: 6px;

      .nativeButton {
        background: none; /* 移除背景 */
        color: inherit; /* 使文字颜色继承自父元素 */
        border: none; /* 移除边框 */
        padding: 0; /* 移除内边距 */
        margin: 0; /* 移除外边距 */
        font: inherit; /* 使字体样式继承自父元素 */
        cursor: pointer; /* 将鼠标指针样式设置为手形 */
        outline: inherit; /* 使轮廓（点击或聚焦时出现的边框）继承自父元素 */
        /* 以上为移除原生默认样式 */
        width: 80px;
        height: 30px;
        // border: 1px solid #f56e14;
        border-radius: 5px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: bold;
        text-align: center;
        color: var(--theme-whiteWord);
        background: linear-gradient(20deg, var(--theme-gradient-start), var(--theme-gradient-end));
      }
    }
  }
  :deep(.van-notice-bar) {
    padding: 0px;
  }
}
</style>
