/* eslint-disable */
// IM 相关的store
import { getRecentMessages, getTotalUnreadCount } from "@/services/api/IM";
import { checkClientTokenRole } from "@/utils/authCookie";
const state = {
  // 客服未读消息数量
  customerUnreadCount: 0,
  // 个人/群聊
  recentMessages: [],
  totalUnreadCount: 0,
};
const mutations = {
  // 客服未读消息数量
  SET_CUSTOMER_UNREADCOUNT(state, count) {
    state.customerUnreadCount = count;
  },
  // 聊天界面
  SET_TOTAL_UNREADCOUNT(state, count) {
    state.totalUnreadCount = count;
  },
  SET_RECENTMESSAGES(state, messages) {
    state.recentMessages = messages;
  },
};
const actions = {
  /**
   * 设置未读消息总数量
   * @param {number} count 未读消息数量
   */
  changeTotalUnreadCount({ commit }, count) {
    if(checkClientTokenRole() === "user"){
      commit("SET_TOTAL_UNREADCOUNT", count);
    }
  },
  /**
   * 清除未读消息总数量
   */
  clearTotalUnreadCount({ commit }) {
    commit("SET_TOTAL_UNREADCOUNT", 0);
  },
  /**
   *
   * @param {string} name  联系人名称。好友名称或群名称
   * @param {string} category 联系人分类。private-私聊，group-群聊，all-所有
   * @param {string} page 页面序号
   * @param {string} size 页面容量
   */
  async getRecentMessages({ commit }, params) {
    try{    const res = await getRecentMessages(params);
      const { data, statusCode } = res;
      if (statusCode === 200) {
        const { items } = data;
        const tempArr = [...items].filter((item) => item !== "none");
        tempArr.sort((a, b) => {
          return b.latest_message?.send_at - a.latest_message?.send_at;
        });
        const OfficialIndex = tempArr.findIndex(item => item.contact.role === "官方客服");
        if (OfficialIndex !== -1) {
          const Official = tempArr.splice(OfficialIndex, 1);
          tempArr.unshift(Official[0]);
        }
        commit("SET_RECENTMESSAGES", tempArr);
      }
    }catch (err) {
     console.log(err);
    }

  },

  /**
   * 获取未读消息总数量
   *
   */
  async getTotalUnreadCount({ commit }) {
    const res = await getTotalUnreadCount();
    const { data, statusCode } = res;
    if (statusCode === 200) {
      const { total_unread_message_count } = data;
      commit("SET_TOTAL_UNREADCOUNT", total_unread_message_count);
    }
  },

  /*
  /**
   * format RecentMessage
   * @param {string} type
   * @param {object} data
   */

  /**
   * 添加一条最近消息
   * @param {object} message 最近消息
   */
  addRecentMessage({ commit }, result) {
    const { data: message } = result || {};
    const tempArr = [...state.recentMessages];
    const { contact, total_unread_message_count } = message || {};
    const targetIndex = tempArr.findIndex(
      (item) => item.contact.target_id === contact.target_id
    );
    if (total_unread_message_count !== null) {
      if(checkClientTokenRole() === "user"){
      commit("SET_TOTAL_UNREADCOUNT", total_unread_message_count);
      }
    }
    if (targetIndex !== -1) {
      // 将原来的替换成message
      tempArr.splice(targetIndex, 1, message);
    } else {
      tempArr.unshift(message);
    }
    tempArr.sort((a, b) => {
      return b.latest_message?.send_at - a.latest_message?.send_at;
    });
    const OfficialIndex = tempArr.findIndex(item => item.contact.role === "官方客服");
    if (OfficialIndex !== -1) {
      const Official = tempArr.splice(OfficialIndex, 1);
      tempArr.unshift(Official[0]);
    }
    
    commit("SET_RECENTMESSAGES", tempArr);
  },

  /**
   * 设置客服未读消息数量
   * @param {number} count 未读消息数量
   */
  setCustomerMessageCount({ commit }, count) {
    // console.log('setCustomerMessageCount', count);
    commit("SET_CUSTOMER_UNREADCOUNT", count);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
