var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AnchorInfoCard"},[_c('el-skeleton',{staticClass:"chatListSkeleton",attrs:{"loading":_vm.dataLoading,"animated":"","count":1}},[_c('template',{slot:"template"},[_c('AnchorInfoCardSkeleton')],1),[(
          _vm.streamer?.live && _vm.streamer?.live.live_duration && _vm.isShowLiveDuration
        )?_c('div',{staticClass:"liveDuration"},[_c('div',{staticClass:"information"},[_c('div',{staticClass:"state"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"text"},[_vm._v("LIVE")])]),(_vm.streamer?.startTime)?_c('div',{staticClass:"duration"}):_vm._e()]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.toLiveRoom(_vm.streamer?.live)}}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("watch")))]),_c('div',{staticClass:"icon"})])]):_vm._e(),(_vm.streamer)?_c('div',{staticClass:"Anchor"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left",on:{"click":function($event){return _vm.toAnchorIntroduction(_vm.streamer?.username)}}},[_c('div',{staticClass:"avatar",style:({
                backgroundImage:
                  'url(' + _vm.imageBaseUrl + _vm.streamer?.avatar + ')',
              })}),_c('div',{staticClass:"AnchorM"},[_c('div',{staticClass:"anchorName"},[_vm._v(_vm._s(_vm.streamer?.name))]),_c('div',{staticClass:"anchorId"},[_vm._v("ID: "+_vm._s(_vm.streamer?.username))])])]),_c('div',{staticClass:"subscription"},[_c('div',{staticClass:"button",class:{ subscribed: _vm.streamer?.followed },on:{"click":function($event){return _vm.clickFollowed(_vm.streamer?.followed, _vm.streamer?.username)}}},[_c('div',{staticClass:"icon",style:({
                  backgroundImage:
                    'url(' +
                    (_vm.streamer?.followed
                      ? _vm.followedIcon.loveRedIcon
                      : _vm.followedIcon.loveWhiteIcon) +
                    ')',
                })}),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.streamer?.followed ? _vm.$t("following") : _vm.$t("follow"))+" ")])]),_c('div',{staticClass:"followerNumber"},[_vm._v(" "+_vm._s(_vm.$t("follow"))+": "+_vm._s(_vm.streamer?.follower_count)+" ")])])])]):_vm._e(),(_vm.streamer?.schedules && _vm.streamer?.schedules.length !== 0)?_c('div',{staticClass:"anchorMatchStatus"},[_c('div',{staticClass:"Mtitle"},[_vm._v(_vm._s(_vm.$t("streamerSchedule")))]),(_vm.streamer?.schedules?.length !== 0)?_c('div',{ref:"competitionsList",staticClass:"competitionsList"},[_c('MatchCardList',{attrs:{"competitions":_vm.streamer?.schedules}})],1):_vm._e(),(_vm.streamer?.schedules?.length === 0 && !_vm.dataLoading)?_c('NoData',[_vm._v(_vm._s(_vm.$t("noDataPrompt")))]):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.streamer),expression:"!streamer"}],staticClass:"noDataWrapper"},[_c('NoData',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataLoading),expression:"!dataLoading"}]}),_c('WaitToastArea',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}]})],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }