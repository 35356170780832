<template>
  <span>
    <span
      v-for="(element, index) in renderedMessage"
      :key="index"
      class="content"
    >
      <span
        v-if="element.type === 'text'"
        :class="mode === 'square' ? 'isSquare' : 'isPrivate'"
        >{{ element.content }}</span
        
      >
      <span
        v-if="element.type === 'face'"
        :class="mode === 'square' ? 'isSquare' : 'isPrivate'"
        >{{ element.content }}</span
      >
      <ImageMessageItem v-else-if="element.type === 'image'" :message="element">
      </ImageMessageItem>
      <LinkMessageItem v-else-if="element.type === 'link'" :message="element">
      </LinkMessageItem>
      <FileMessageItem
        v-else-if="element.type === 'document'"
        :message="element"
      >
      </FileMessageItem>
      <!-- <span v-else-if="element.type === 'loading'"
        ><van-loading type="spinner"
      /></span> -->
    </span>
  </span>
</template>

<script>
import ImageMessageItem from "@/components/ImageMessageItem/Index.vue";
import LinkMessageItem from "@/components/LinkMessageItem/Index.vue";
import FileMessageItem from "@/components/FileMessageItem/Index.vue";
export default {
  name: "MessageContent",
  components: {
    ImageMessageItem,
    LinkMessageItem,
    FileMessageItem,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
    },
  },
  computed: {
    renderedMessage() {
      if (this.content.length === 0) return [];
      let elements = [];
      this.content.map((item) => {
        const { type, data } = item;
        switch (type) {
          case "text":
            elements.push({ type: "text", content: data?.text || "" });
            break;
          case "face":
            elements.push({ type: "face", content: data?.code });
            break;
          case "image":
            elements.push({ type: "image", url: data?.url });
            break;
          case "link":
            elements.push({
              type: "link",
              content: data?.name,
              link: data?.link,
            });
            break;
          case "document":
            elements.push({
              type: "document",
              content: data?.name,
              file: data?.file,
              size: data?.size,
            });
            break;
          case "loading":
            elements.push({ type: "loading" });
            break;
        }
      });
      return elements;
    },
  },
  methods: {
  
  },
};
</script>
<style scoped lang="scss">
.content {
  // display: flex;
  // justify-content: center;
  .isSquare {
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 0.5;
    margin: 5px;
    -webkit-user-drag: text !important;
    -webkit-user-select:text !important;
    user-select: text !important;
  }
  .isPrivate {
    white-space: pre-wrap;
    margin: 2px 5px;
    max-width: 250px;
    display: inline-block;
    // 超过最大宽度的文字换行
    word-wrap: break-word;
    word-break: normal;
    -webkit-user-drag: text !important;
    -webkit-user-select:text !important;
    user-select: text !important;
  }
}
</style> 
