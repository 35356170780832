import clientRequest from "@/services/axios/domainRequest/clientServer";
import fileRequest from "@/services/axios/domainRequest/fileServer";
import IMRequest from "@/services/axios/domainRequest/IMServer";
import liveChatRequest from "@/services/axios/domainRequest/liveChatServer";
// 测速
export function getSpeed(type, baseUrl) {
  // type
  let request;
  if (type === "clientServer") {
    request = clientRequest;
  } else if (type === "fileServer") {
    request = fileRequest;
  } else if (type === "IMServer") {
    request = IMRequest;
  } else if (type === "liveChatServer") {
    request = liveChatRequest;
  }
  return request({
    url: `${baseUrl}/speed`,
    method: "get",
  });
}
// 获取域名
export function getDomain(type, baseUrl, data) {
  let request;
  if (type === "clientServer") {
    request = clientRequest;
  } else if (type === "fileServer") {
    request = fileRequest;
  } else if (type === "IMServer") {
    request = IMRequest;
  } else if (type === "liveChatServer") {
    request = liveChatRequest;
  }
  return request({
    url: `${baseUrl}/get-domains`,
    method: "post",
    data,
  });
}
