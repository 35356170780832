<template>
  <div class="recommendStreamerList">
    <div class="list" v-if="dataList?.length > 0">
      <RecommendStreamerListItem
        v-for="(item, index) in dataList"
        :key="index"
        :data="item"
        :activeTab="activeTab"
        :displayHit="displayHit"
      ></RecommendStreamerListItem>
    </div>
    <!-- <div class="NoData" v-else>
      <NoData>{{ $t("noDataPrompt") }}</NoData>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import RecommendStreamerListItem from "@/components/RecommendStreamerListItem/Index.vue";
import NoData from "@/components/NoData/Index.vue";
export default {
  name: "RecommendStreamerList",
  components: {
    RecommendStreamerListItem,
    NoData,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    userInfo: {
      type: Object,
    },
    displayHit:{
      type: Boolean,
      default: true
    },
    activeTab: {
      type: String,
    }
  },
  data() {
    return {
      // userInfo: null,
    };
  },
  async mounted() {
    // console.log(this.dataList);
  },
  methods: {
 
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
