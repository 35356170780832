<template>
  <div class="liveRoomChatInterface">
    <!-- 移动端-->
    <div class="Mprivate" v-if="target.targetId && userInfo">
      <div class="Mtop" v-if="mode !== 'privateChatMode'">
        <div class="iconArrow" @click="goBack">
          <van-icon
            name="arrow-left"
            color="var(--theme-main-word)"
            size="22px"
          />
        </div>
        <div class="MprivateName">
          <div class="name">{{ target.targetName }}</div>
          <div
            class="topLoadingArea"
            v-if="showLoad && mode !== 'privateChatMode'"
          >
            <van-loading type="spinner" class="loadingArea" size="20px" />
          </div>
        </div>
        <!--  -->
        <!-- <div v-if="category === 'group'" class="more" @click="goDetail">
          ...
        </div> -->
        <div v-if="category === 'group'" class="more"></div>
        <div v-else></div>
      </div>
      <div
        :class="mode === 'privateChatMode' ? 'MprivateChatP' : 'MprivateChatC'"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <van-loading
          type="spinner"
          class="loadingArea"
          size="20px"
          style="text-align: center"
          v-if="showLoad && mode === 'privateChatMode'"
        />

        <div
          class="chatInterfaceScrollWrapper"
          :ref="`chatInterfaceScrollWrapper-${mode}`"
          @scroll="handleScroll"
          @touchmove="handleScroll"
        >
          <div class="MprivateMessageList">
            <!-- 加载图标 -->
            <div>
              <div
                class="messageItem"
                v-for="(item, key) in messages"
                :key="key"
              >
                <div class="time" v-if="formatTime(item.send_at, key)">
                  {{
                    $t(formatDate(item.send_at, key)) +
                    formatTime(item.send_at, key)
                  }}
                </div>
                <div v-if="item.content_type === 'notice'" class="notice">
                  <noticeMessage :message="item?.content[0]" />
                </div>
                <div
                  v-else
                  class="messageContent"
                  :class="
                    item.sender.username === userInfo?.username
                      ? 'sent'
                      : 'received'
                  "
                >
                  <!-- 头像 -->
                  <div
                    class="avatar"
                    :class="
                      item.sender.username === userInfo?.username
                        ? 'avatar-right'
                        : 'avatar-left'
                    "
                  >
                    <van-image
                      width="30"
                      height="30"
                      v-if="item.sender.username === userInfo?.username"
                      :src="imageBaseUrl + userInfo.avatar"
                      round
                    />
                    <van-image
                      width="30"
                      height="30"
                      v-else
                      :src="imageBaseUrl + item.sender.avatar"
                      round
                    />
                  </div>

                  <!-- 对话框  对方-->
                  <div
                    v-if="item.sender.username !== userInfo?.username"
                    class="message-content-left"
                  >
                    <div class="name" v-if="category === 'group'">
                      {{ item.sender.name }}
                    </div>

                    <div class="dialog">
                      <div
                        class="text-content"
                        :style="{
                          background:
                            item.content?.[0].type === 'text' ||
                            item.content?.length > 1
                              ? 'var(--theme-chat-other)'
                              : '',
                        }"
                      >
                        <MessageContent :content="item.content" />
                      </div>
                      <!-- <div class="message-error">
                        <van-icon v-if="item.send_error" name="warning-o"  color="red" size="16"/>
                      </div> -->
                    </div>
                  </div>
                  <!-- 对话框  我-->
                  <div v-else class="message-content-right">
                    <div class="name" v-if="category === 'group'">
                      {{ item.sender.name }}
                    </div>
                    <div class="dialogI">
                      <!-- <div v-if="item.send_error" class="message-error">
                        <van-icon name="warning-o" color="red" size="16" />
                      </div> -->
                      <div v-if="item.send_loading" class="message-loading">
                        <div><van-loading type="spinner" size="16" /></div>
                      </div>
                      <div
                        class="text-content"
                        :style="{
                          background:
                            item.content?.[0].type === 'text' ||
                            item.content?.length > 1
                              ? 'var(--theme-chat-me)'
                              : '',
                        }"
                      >
                        <MessageContent :content="item.content" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div ref="endElement"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nocs" v-else>
      <NoData v-if="!showLoad">{{ $t("noCsPrompt") }}</NoData>
    </div>
    <!--输入框 -->
    <div class="inptChat">
      <div class="input-selection" v-if="!showInput">
        <van-uploader
          v-if="category === 'private'"
          :after-read="afterRead"
          :before-read="beforeRead"
          class="iconImage"
          :disabled="!target.targetId"
        >
          <van-icon name="photo-o" size="22" color="var(--theme-main-word)" />
        </van-uploader>
        <van-field
          v-model="newMessage"
          rows="1"
          autosize
          type="textarea"
          maxlength="1024"
          class="custom-input"
          id="send_M"
          :readonly="true"
          :placeholder="$t('chat')"
          @click="openInput"
        />
        <!-- <div class="iconRight"></div> -->
      </div>
      <div class="input-selection" v-else>
        <div class="cancelArea" @click.stop="closeInput">
          {{ $t("cancelSend") }}
        </div>
        <div class="confirmArea" @click.stop="sendMessage">
          {{ $t("confirmSend") }}
        </div>
      </div>
    </div>
    <van-popup
      v-model="showInput"
      position="top"
      class="inputPopup"
      :overlay="false"
    >
      <div class="input-selection">
        <!-- 群聊不允许发送图片 -->
        <!-- <div class="backIcon" @click="closeInput"></div> -->
        <div class="closeArea" @click="closeInput">
          <!-- <van-icon name="cross" /> -->
        </div>
        <van-field
          v-model="newMessage"
          rows="1"
          autosize
          type="textarea"
          class="custom-input"
          maxlength="1024"
          id="send_M"
          :placeholder="$t('InputSearchPrompt')"
          :disabled="!target.targetId"
          ref="realInput"
        />

        <div class="iconRight" @click="sendMessage"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import emoji from "@/components/EmojiCom/Index.vue";
import { debounce } from "lodash";
import NoData from "@/components/NoData/Index.vue";
import noticeMessage from "@/components/NoticeMessage/Index.vue";
import MessageContent from "@/components/MessageContent/Index.vue";
import { mapActions } from "vuex";
import { getUser } from "@/services/api/User";
import {
  getHistoryMessages,
  sent,
  uploadImage,
  clearUnread,
  getPrivateWelcome,
} from "@/services/api/IM";
import { formatDateText, formatTimeText, renderMessage } from "@/utils/tools";
import Compressor from "compressorjs";
import dayjs from "dayjs"; // 日期库
// import BScroll from "@better-scroll/core"; // 滚动核心库
// import PullDown from "@better-scroll/pull-down";
// import ObserveDOM from "@better-scroll/observe-dom"; // 滚动监听DOM插件
import { v4 as uuidv4 } from "uuid";

// BScroll.use(ObserveDOM);
// BScroll.use(PullDown);
export default {
  components: {
    // emoji,
    noticeMessage,
    MessageContent,
    NoData,
  },
  props: {
    mode: {
      type: String, // privateChatMode 主播私聊 chatInterfaceMode 通讯录进入聊天/直播间聊天
    },
    chatData: {
      type: Object,
    },
    TMoptionActivity: {
      type: String,
    },
    liveId: {
      type: Number,
    },
    targetName: {
      type: String,
    },
  },
  watch: {
    TMoptionActivity: {
      handler: function (val) {
        if (val === "streamerPrivateChat" || val === "chat") {
          // this.initScroll();
          this.scrollToBottom();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      //公共--------------------------------------------------------------------------------------------------------
      messages: [], // 存储聊天消息的数组
      newMessage: "", // 用户输入的新消息
      limit: 10, //信息请求条数
      scroll: "",
      showLoad: true,
      //PC----------------------------------------------------------------------------------
      show: false, //loading图标
      more: false, //加载更多消息按钮
      isLast: false, //是否没有历史消息
      target: {},
      // 迁移
      userInfo: null,
      category: "",
      pageParam: 1, // 页码
      sizeParam: 30, // 页面大小 // TODO：测试用 实际应扩大容量
      maxPages: null, // 最大页码/总页数
      // bScroll: null, // better-scroll实例
      showEmoji: false,
      initialViewportHeight: window.innerHeight,
      canSend: true, //发送防抖, 是否可以发送
      firstMessageTimeVisible: false, // 首条消息是否显示时间
      showInput: false,
      imageBaseUrl: "", // 图片基础路径
    };
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
  },
  async mounted() {
    const { category, targetName, targetId, targetAvatar, streamer_username } =
      this.chatData || {};
    this.target = {
      targetId,
      targetName,
      streamer_username,
      targetAvatar,

    };
    this.category = category;
    await this.historyMessages();
    await this.getUserInfo();
    if (this.mode === "privateChatMode") await this.getWelcome();
    // this.initScroll();
    // this.disableOtherScroll();
    this.scrollToBottom();
    // 监听聊天ws消息
    this.handleChatMessge({
      type: [
        "chat_message_receive",
        "friend_add",
        "friend_recall",
        "group_recall",
        "group_increase",
        "group_decrease",
        "group_upload",
        "group_ban",
        "ocs_chat_message_receive",
      ],
      callback: async (message) => {
        await this.addRecentMessage(message);
        const { data, type } = message;
        if (type === "ocs_chat_message_receive") {
          this.setCustomerMessageCount(data.unread_message_count);
        }
      },
      id: "chatInterface",
    });
  },
  //   computed: {
  //     target: {
  //       get() {
  //       const { targetName, targetId, targetAvatar } = this.chatData || {};
  //       return {
  //         targetId,
  //         targetName,
  //         targetAvatar,
  //       };
  //     },
  //   },
  // },
  updated() {
    // if (!this.bScroll) {
    //   this.initScroll(); // 初始化 BetterScroll
    // } else {
    //   this.bScroll.refresh();
    // }
  },
  beforeDestroy() {
    this.removeHandleChatMessge({
      type: [
        "chat_message_receive",
        "friend_add",
        "friend_recall",
        "group_recall",
        "group_increase",
        "group_decrease",
        "group_upload",
        "group_ban",
      ],
      id: "chatInterface",
    });
  },
  methods: {
    ...mapActions("chatWS", ["handleChatMessge", "removeHandleChatMessge"]),
    ...mapActions("chatIM", [
      "setCustomerMessageCount",
      "changeTotalUnreadCount",
    ]),
    /**
     * 初始化better-scroll实例
     */
    // initScroll() {
    //   if (
    //     !this.bScroll &&
    //     this.$refs[`chatInterfaceScrollWrapper-${this.mode}`]
    //   ) {
    //     this.bScroll = new BScroll(
    //       this.$refs[`chatInterfaceScrollWrapper-${this.mode}`],
    //       {
    //         click: true, // 允许点击事件
    //         probeType: 3, // 启用 probeType 来监听滚动事件
    //         pullDownRefresh: {
    //           threshold: 0,
    //           // stop: 0,
    //         }, // 开启下拉行为
    //         // pullDownRefresh: true, // 开启下拉行为
    //         observeDOM: true, // 监听 DOM 变化
    //         useTransition: false, // 使用 requestAnimationFrame 做动画
    //         bounce: {
    //           top: false,
    //         },
    //       }
    //     );
    //     this.bScroll.on("pullingDown", this.pullingDownHandler); // 监听下拉事件
    //   }
    // },
    // disableOtherScroll() {
    //   // 禁止滚动
    //   document.addEventListener(
    //     "touchmove",
    //     function (event) {
    //       // 获取事件目标元素
    //       let target = event.target;
    //       // 检查是否为videoContent
    //       while (target !== null) {
    //         if (
    //           target.classList &&
    //           target.classList.contains("liveRoomChatInterface")
    //         ) {
    //           event.preventDefault();
    //           break;
    //         }
    //         target = target.parentElement;
    //       }
    //     },
    //     { passive: false }
    //   );
    // },
    openInput() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs.realInput.focus();
      });
    },
    closeInput() {
      this.newMessage = "";
      this.showInput = false;
    },
    async getWelcome() {
      if (!this.target.targetId || !this.targetName) return;
      try {
        const res = await getPrivateWelcome(this.target.targetId, {
          username: this.target.targetId,
          streamer_username: this.target.streamer_username,
          language_id: localStorage.getItem("language_id"),
          country_id: localStorage.getItem("country_id"),
        });
        const { statusCode, data } = res;
        if (statusCode === 200) {
          if (!data.status) return;
          const content = [
            {
              type: "text",
              data: {
                text: data.content,
              },
            },
          ];
          const messageData = {
            send_at: Date.now(),
            sender: {
              name: this.target.name,
              username: this.target.targetId,
              avatar: data.sender_avatar,
            },
            content,
          };
          setTimeout(() => {
            this.messages.push(messageData);
          }, 500);
          setTimeout(() => {
            this.scrollToBottom();
          }, 700);
        }
      } catch (e) {
        console.log("welcome", e);
        // this.$vantToast({
        //   type: "fail",
        //   message: this.$t("InternetErrorPrompt"),
        // });
      }
    },
    showMessageLoading(messageData) {
      this.messages.push({
        ...messageData,
        send_loading: true,
      });
      setTimeout(() => {
        this.scrollToBottom();
      }, 50);
    },

    hideMessageLoading(loading_id, data) {
      const index = this.messages.findIndex(
        (item) => item.send_loading && item.loading_id === loading_id
      );
      if (index !== -1) {
        const oldData = this.messages[index];
        if (data) {
          this.messages.splice(index, 1, {
            ...data,
            content: oldData.content,
          });
        } else {
          this.messages.splice(index, 1);
        }
      }
    },
    // 用户上传图片的前置处理
    beforeRead(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        this.$vantToast({
          type: "fail",
          message: this.$t("uploadRulePrompt"),
        });
        return false;
      }
      if (file.size > 1024 * 1024 * 10) {
        this.$vantToast({
          type: "fail",
          message: this.$t("uploadSizePrompt"),
        });
        return false;
      }
      return true;
    },
    // 发送图片
    async sendImage(formData, url) {
      const loading_id = uuidv4();
      const loadingContent = [
        {
          type: "image",
          data: {
            url: url,
          },
        },
      ];
      let messageData = {
        send_at: Date.now(),
        sender: {
          name: this.userInfo.name,
          username: this.userInfo.username,
          avatar: this.userInfo?.avatar,
        },
        content: loadingContent,
      };

      this.showMessageLoading({ ...messageData, loading_id });
      try {
        const res = await uploadImage(formData);
        const { statusCode, data } = res;
        if (statusCode === 200) {
          const content = [
            {
              type: "image",
              data: {
                url: data.url,
              },
            },
          ];
          let paramData = {
            category: this.category,
            target_id: this.target.targetId,
            content,
          };
          messageData["content"] = content;
          sent(paramData)
            .then((res) => {
              const { statusCode, data } = res;
              // 禁言和
              if (statusCode === 200) {
                const cur = this.messages.find(
                  (item) => Number(item.message_id) === Number(data.message_id)
                );
                if (cur) {
                  this.hideMessageLoading(loading_id);
                  return;
                } else {
                  this.hideMessageLoading(loading_id, data);
                }
                // this.messages.push(data);
                // this.initScroll();
                setTimeout(() => {
                  this.scrollToBottom();
                }, 100);
                // 禁言
              } else if (statusCode === 204) {
                this.hideMessageLoading(loading_id);
                this.canSend = true;
                this.$vantToast({
                  type: "fail",
                  message: this.$t("bannedSendPrompt"),
                });
              } else {
                this.hideMessageLoading(loading_id);
                messageData["send_error"] = true;
                this.messages.push(messageData);
                setTimeout(() => {
                  this.scrollToBottom();
                }, 100);
                this.$vantToast({
                  type: "fail",
                  message: this.$t("sendError"),
                });
              }
            })
            .catch(() => {
              this.$vantToast({
                type: "fail",
                message: this.$t("sendError"),
              });

              this.hideMessageLoading(loading_id);
            });
        } else {
          this.$vantToast({
            type: "fail",
            message: this.$t("sendError"),
          });
          this.hideMessageLoading(loading_id);
          return;
        }
      } catch (e) {
        this.$vantToast({
          type: "fail",
          message: this.$t("sendError"),
        });
        this.hideMessageLoading(loading_id);
      }
    },
    // 用户上传图片的后置处理
    async afterRead(data) {
      const that = this;
      const { file } = data;
      let quality = file.size > 1024 * 1024 * 1 ? 0.4 : 1;
      new Compressor(file, {
        quality: quality,
        async success(result) {
          const formData = new FormData();
          const url = URL.createObjectURL(result);
          formData.append("file", result, result.name);
          await that.sendImage(formData, url);
        },
        error(err) {
          console.log(err.message);
        },
      });
    },

    /**
     * 下拉事件加载更多历史消息
     */
    // async pullingDownHandler() {
    //   this.showLoad = true;
    //   console.log("下拉事件加载更多历史消息");
    //   if (this.pageParam + 1 > this.maxPages) {
    //     this.bScroll.finishPullDown(); // 结束下拉行为
    //   } else {
    //     // 记录旧的滚动容器高度
    //     this.bScroll.refresh();
    //     const oldScrollHeight = this.bScroll.scrollerHeight;
    //     await this.historyMessages(true); // 加载下一页历史消息
    //     this.bScroll.refresh();
    //     this.$nextTick(() => {
    //       // 计算新的高度并调整滚动位置
    //       this.bScroll.refresh(); // 重要: 更新better-scroll的状态，以便获取最新的scrollerHeight
    //       const newScrollHeight = this.bScroll.scrollerHeight;
    //       const scrollOffset = newScrollHeight - oldScrollHeight;
    //       // 使用scrollTo方法调整滚动位置，这里的时间设置为0，实现无动画效果
    //       this.bScroll.scrollTo(0, this.bScroll.y - scrollOffset + 22, 1);
    //     });
    //     this.bScroll.finishPullDown(); // 结束下拉行为
    //   }
    //   this.showLoad = false;
    // },

    handleScroll: debounce(async function () {
      if (!this.$refs[`chatInterfaceScrollWrapper-${this.mode}`]) {
        return;
      }
      if (
        this.$refs[`chatInterfaceScrollWrapper-${this.mode}`].scrollTop === 0
      ) {
        this.showLoad = true;
        if (this.pageParam + 1 <= this.maxPages) {
          const oldScrollHeight =
            this.$refs[`chatInterfaceScrollWrapper-${this.mode}`].scrollHeight;
          await this.historyMessages(true); // 加载下一页历史消息
          this.$nextTick(() => {
            // 计算新数据的高度
            const newScrollHeight =
              this.$refs[`chatInterfaceScrollWrapper-${this.mode}`]
                .scrollHeight;
            const newScrollTop = newScrollHeight - oldScrollHeight;
            // 将新数据的高度添加到 scrollTop
            this.$refs[`chatInterfaceScrollWrapper-${this.mode}`].scrollTop =
              newScrollTop - 20;
          });
        }
        this.showLoad = false;
      }
    }, 200),

    async readMessage({ category, target_id }) {
      const res = await clearUnread({
        category,
        target_id,
      });
      const { statusCode, data } = res || {};
      const { total_unread_message_count } = data;
      if (statusCode === 200) {
        this.changeTotalUnreadCount(total_unread_message_count);
      } else {
        // this.$vantToast({
        //   type: "fail",
        //   // FIXME:网络错误注释
        //   // message: this.$t("InternetErrorPrompt"),
        // });
        console.log("fail:网络错误！");
      }
    },
    /**
     * @param {*} result
     * 处理ws上报的消息
     */
    async addRecentMessage(result) {
      const { data } = result || {};
      const { contact, latest_message, total_unread_message_count } = data;
      if (total_unread_message_count !== null) {
        this.changeTotalUnreadCount(total_unread_message_count);
      }
      const cur = this.messages.find(
        (item) => Number(item.message_id) === Number(latest_message.message_id)
      );
      if (cur) return;
      if (contact.target_id !== this.target.targetId) return;
      if(latest_message.content[0].type === 'image') {
        latest_message.content[0].data.url = this.imageBaseUrl + latest_message.content[0].data.url;
      }
      this.messages.push(latest_message);
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
      await this.readMessage({
        category: contact.category,
        target_id: contact.target_id,
      });
    },

    goDetail() {
      sessionStorage.setItem("liveRoomTabActivity", this.TMoptionActivity);

      this.$router.push({
        name: "GroupDetails",
        query: {
          groupId: this.target.targetId,
        },
      });
    },
    goBack() {
      if (this.mode === "chatInterfaceMode") {
        this.$emit("goBack");
      } else {
        // 处理返回按钮的逻辑
        this.$router.go(-1);
      }
    },
    //  获取用户信息
    async getUserInfo() {
      this.showLoad = true;
      try {
        const country_id = localStorage.getItem("country_id");
        const language_id = localStorage.getItem("language_id");
        const res = await getUser({
          country_id,
          language_id,
        });
        if (res.statusCode === 200) {
          const { data } = res;
          this.userInfo = data;
          this.showLoad = false;
        } else {
          this.userInfo = null;
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    /**
     * 加载历史消息
     * @param { Boolean } isLoadMore 是否加载更多 默认为false
     */
    async historyMessages(isLoadMore = false) {
      if (!this.target?.targetId) return;
      const params = {
        category: this.category,
        target_id: this.target.targetId,
        page: isLoadMore ? this.pageParam + 1 : 1,
        size: this.sizeParam,
      };
      try {
        const res = await getHistoryMessages(params);
        const { data, statusCode } = res;
        if (statusCode === 200) {
          const rows = data.items;
          rows.sort((a, b) => a.send_at - b.send_at);
          // 遍历rows，判断是否为图片类型
          rows.forEach((item) => {
            item.content[0].type === "image";
            item.content[0].data.url =
              this.imageBaseUrl + item.content[0].data.url;
          });
          this.messages.unshift(...rows);
          this.pageParam = data.page;
          this.maxPages = data.pages;
          // 如果不是最后一页数据 首条消息不展示时间
          if (data.page < data.pages) {
            this.firstMessageTimeVisible = false;
          } else {
            this.firstMessageTimeVisible = true;
          }
        } else {
          // this.$vantToast({
          //   type: "fail",
          //   // FIXME:网络错误注释
          //   // message: this.$t("InternetErrorPrompt"),
          // });
          console.log("fail:网络错误！");
        }
      } catch (error) {
        // this.$vantToast({
        //   type: "fail",
        //   // FIXME:网络错误注释
        //   // message: this.$t("InternetErrorPrompt"),
        // });
        console.log("fail:网络错误！", error);
      }
    },

    // 滑动到底部
    scrollToBottom() {
      // if (this.bScroll) {
      //   this.bScroll.scrollTo?.(0, this.bScroll.maxScrollY, 1);
      // }

      // TODO
      this.$nextTick(() => {
        this.$refs.endElement?.scrollIntoView();
      });
    },
    //发送信息
    sendMessage() {
      if (!this.canSend) return;
      const message = this.newMessage.trim();
      this.closeInput();
      if (message !== "") {
        let content = [
          {
            type: "text",
            data: {
              text: message,
            },
          },
        ];

        let paramData = {
          category: this.category,
          target_id: this.target.targetId,
          content,
        };
        if (this.mode === "privateChatMode") {
          paramData["live_id"] = this.liveId;
        }
        const time = Date.now();
        const messageData = {
          send_at: time,
          sender: {
            name: this.userInfo.name,
            username: this.userInfo.username,
            avatar: this.userInfo?.avatar,
          },
          content,
        };
        this.canSend = false;
        const loading_id = uuidv4();
        this.showMessageLoading({ ...messageData, loading_id });
        sent(paramData)
          .then((res) => {
            const { statusCode, data } = res;
            if (statusCode === 200) {
              this.canSend = true;
              this.hideMessageLoading(loading_id);
              const cur = this.messages.find(
                (item) => Number(item.message_id) === Number(data.message_id)
              );
              this.newMessage = "";
              if (cur) return;
              this.messages.push(data);
              setTimeout(() => {
                this.scrollToBottom();
              }, 100);
            } else if (statusCode === 204) {
              this.hideMessageLoading(loading_id);
                this.canSend = true;
                this.$vantToast({
                  type: "fail",
                  message: this.$t("bannedSendPrompt"),
                });
            } else {
              this.hideMessageLoading(loading_id);
              this.canSend = true;
              messageData["send_error"] = true;
              this.messages.push(messageData);
              setTimeout(() => {
                this.scrollToBottom();
              }, 100);
              this.$vantToast({
                type: "fail",
                message: this.$t("sendError"),
              });
            }
          })
          .catch(() => {
            this.$vantToast({
              type: "fail",
              message: this.$t("sendError"),
            });
            this.hideMessageLoading(loading_id);
            this.canSend = true;
          });
      }
      // 将新消息添加到消息列表中
      // 清空输入框
    },

    //移动端-----------------------------------------------------------------------
    MEmotion(emotion) {
      if (!this.target.targetId) return;
      let input = document.getElementById("send_M");
      let startPos = input.selectionStart;
      let endPos = input.selectionEnd;
      this.newMessage =
        input.value.substring(0, startPos) +
        emotion +
        input.value.substring(endPos);
    },

    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchEnd(event) {
      const endX = event.changedTouches[0].clientX;
      const deltaX = endX - this.startX;
      if (deltaX > 100) {
        this.handleSwipeRight();
      }
    },
    handleSwipeRight() {
      // 处理右滑事件的逻辑
      // this.$emit("closeDrawer")
      // 在这里执行你希望触发的事件
    },

    /**
     * 根据给定的时间和消息索引格式化时间。
     * @param {string} time - 需要格式化的时间戳字符串。
     * @param {number} index - 当前消息的索引。
     * @returns {string | null} 格式化的时间字符串或者null。
     */
    formatDate(time, index) {
      if (index === 0) {
        // 如果当前消息是列表中的第一条（index === 0），直接返回格式化后的时间。
        return formatDateText(time);
      } else {
        // dayjs比较当前消息时间和前一条消息时间的差异。时间差
        const preMessage = this.messages?.[index - 1];
        const diff = Math.abs(dayjs(time).diff(preMessage.send_at, "minutes"));
        if (diff >= 5) {
          // 间差大于或等于5分钟，则返回格式化后的时间
          return formatDateText(time);
        } else {
          return "";
        }
      }
    },

    formatTime(time, index) {
      if (index === 0 && !this.firstMessageTimeVisible) {
        return "";
      }
      if (index === 0) {
        // 如果当前消息是列表中的第一条（index === 0），直接返回格式化后的时间。
        return formatTimeText(time);
      } else {
        // dayjs比较当前消息时间和前一条消息时间的差异。时间差
        const preMessage = this.messages?.[index - 1];
        const diff = Math.abs(dayjs(time).diff(preMessage.send_at, "minutes"));
        if (diff >= 5) {
          // 间差大于或等于5分钟，则返回格式化后的时间
          return formatTimeText(time);
        } else {
          return "";
        }
      }
    },
    // 格式化消息
    formatMessage(content) {
      return renderMessage(content);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
.van-cell::after {
  border-bottom: none !important;
}
textarea {
  resize: none !important;
  ::-webkit-resizer {
    display: none !important;
  }
}
.el-textarea.is-disabled .el-textarea__inner {
  background-color: var(--theme-bg);
  color: #fff;
}
</style>
