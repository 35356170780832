var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-skeleton',{attrs:{"loading":_vm.dataLoading,"animated":""}},[_c('template',{slot:"template"},[_c('MatchInfoSkeleton')],1),[(_vm.match)?_c('div',{staticClass:"matchInfo",class:{ noLiveId: _vm.noLiveId }},[_c('div',{staticClass:"match-startTime"},[_c('div',[_vm._v(_vm._s(_vm.match?.league))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.match?.formatTime))])]),(_vm.match?.competition_status.id === 2)?_c('div',{staticClass:"minTime"},[_vm._v(" "+_vm._s(_vm.match?.match_real_time || "")+" "),(
            _vm.match.competition_status.id === 2 &&
            _vm.match.match_real_time &&
            _vm.sportTypeId === 1 &&
            _vm.match.match_real_time !== 'HT'
          )?_c('div',{staticClass:"flashingSymbol"},[_vm._v(" ' ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"teams"},[_c('div',{staticClass:"team"},[_c('div',{staticClass:"tag"}),_c('van-image',{staticClass:"team-flaga",attrs:{"fit":"cover","round":"","src":_vm.typeId === 1
                ? _vm.placeholderLogo.home_team_logo
                : _vm.placeholderLogo.away_team_logo,"alt":_vm.typeId === 1 ? _vm.match.home_team : _vm.match.away_team}}),_c('div',{staticClass:"team-name"},[_vm._v(" "+_vm._s(_vm.typeId === 1 ? _vm.match.home_team : _vm.match.away_team)+" ")])],1),_c('div',{staticClass:"center"},[(_vm.match?.competition_status.id !== 1)?_c('div',{staticClass:"scores"},[(
                _vm.match.away_scores.length !== 0 &&
                _vm.match.home_scores.length !== 0
              )?_c('div',{staticClass:"score",class:{
                yellowColor:
                  _vm.typeId === 1
                    ? _vm.match.home_scores[0] > _vm.match.away_scores[0]
                    : _vm.match.away_scores[0] > _vm.match.home_scores[0],
              }},[_vm._v(" "+_vm._s(_vm.typeId === 1 ? _vm.match.home_scores[0] : _vm.match.away_scores[0])+" ")]):_vm._e(),_c('div',{staticClass:"scores-icon"},[_vm._v("—")]),(
                _vm.match.home_scores.length !== 0 &&
                _vm.match.away_scores.length !== 0
              )?_c('div',{staticClass:"score",class:{
                yellowColor:
                  _vm.typeId === 1
                    ? _vm.match.home_scores[0] < _vm.match.away_scores[0]
                    : _vm.match.away_scores[0] < _vm.match.home_scores[0],
              }},[_vm._v(" "+_vm._s(_vm.typeId === 1 ? _vm.match.away_scores[0] : _vm.match.home_scores[0])+" ")]):_vm._e()]):_vm._e(),(_vm.match?.competition_status.id === 1)?_c('div',{staticClass:"scores"},[_c('div',{staticClass:"vs"},[_vm._v("VS")])]):_vm._e(),(
              _vm.match.competition_status.id === 1 &&
              _vm.countDownTime.hours >= 0 &&
              _vm.countDownTime.hours < 24
            )?_c('div',{staticClass:"countdown"},[_c('div',{staticClass:"countdown-icon"}),_c('div',{staticClass:"countdown-wrap"},[_c('van-count-down',{attrs:{"time":_vm.countDownTime.ms}})],1)]):_c('div',{staticClass:"status"},[(
                _vm.match.competition_status.id === 2 &&
                _vm.match.competition_status.midfield
              )?_c('div',{staticClass:"status-text"},[_vm._v(" "+_vm._s(`${_vm.$t("FH")}:${_vm.match.home_scores?.[1] || 0}-${ _vm.match.away_scores?.[1] || 0 }`)+" ")]):_vm._e(),(_vm.typeId === 2)?_c('div',{staticClass:"status-text",staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(`(${_vm.$t("different")}:${ _vm.match.home_scores?.[0] - _vm.match.away_scores[0] || 0 } ${_vm.$t("total")}:${ _vm.match.home_scores?.[0] + _vm.match.away_scores?.[0] || 0 })`)+" ")]):_vm._e()])]),_c('div',{staticClass:"team"},[_c('div',{staticClass:"tag"},[_vm._v(" "+_vm._s(_vm.typeId === 2 ? `(${_vm.$t("homeTag")})` : "")+" ")]),_c('van-image',{staticClass:"team-flaga",attrs:{"fit":"contain","round":"","src":_vm.typeId === 1
                ? _vm.placeholderLogo.away_team_logo
                : _vm.placeholderLogo.home_team_logo,"alt":_vm.typeId === 1 ? _vm.match.away_team : _vm.match.home_team}}),_c('div',{staticClass:"team-name"},[_vm._v(" "+_vm._s(_vm.typeId === 1 ? _vm.match.away_team : _vm.match.home_team)+" ")])],1)]),_c('div',{staticClass:"liveArea"},[(_vm.currentVideo)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.changeMode('video')}}},[_vm._v(" "+_vm._s(_vm.$t("video"))+" ")]):_vm._e(),(_vm.currentVideo && _vm.match.coverage.mlive)?_c('div',{staticClass:"division"},[_vm._v(" "+_vm._s("|")+" ")]):_vm._e(),(_vm.match.coverage.mlive && _vm.match.competition_status.id !== 1)?_c('div',{staticClass:"item TASK_WatchGameAnim_BEACON",on:{"click":function($event){return _vm.changeMode('animation')}}},[_vm._v(" "+_vm._s(_vm.$t("animation"))+" ")]):(!_vm.currentVideo && _vm.match.competition_status.id === 1)?_c('div',{staticClass:"subItem"},[_c('div',{staticClass:"item-content-schedule",on:{"click":function($event){$event.stopPropagation();return _vm.clickSubscribe(
                _vm.match.competition_id,
                !_vm.subscribeCompetitionObj?.[_vm.match.competition_id]
              )}}},[_c('div',{staticClass:"item-content-subscribe",style:({
                backgroundImage:
                  'url(' +
                  (_vm.subscribeCompetitionObj?.[_vm.match.competition_id]
                    ? _vm.subscribedIcon.starRedIcon
                    : _vm.subscribedIcon.starGreyIcon) +
                  ')',
              })}),_vm._v(" "+_vm._s(_vm.subscribeCompetitionObj?.[_vm.match.competition_id] ? _vm.$t("subscribed") : _vm.$t("subscribe"))+" ")])]):_vm._e()])]):_c('div',{staticClass:"matchInfoNoData",class:{ noLiveId: _vm.noLiveId }},[_c('NoData',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataLoading),expression:"!dataLoading"}]},[_vm._v(_vm._s(_vm.$t("noDataPrompt")))]),_c('WaitToastArea',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}]})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }