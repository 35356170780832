<template>
  <div class="liveCardSkeleton">
    <div class="img">
      <el-skeleton-item variant="image" />
    </div>
    <div class="header">
      <el-skeleton-item variant="h3" />
      <el-skeleton-item variant="h3" />
    </div>
    <div class="footer">
      <div class="line">
        <el-skeleton-item variant="h1" />
        <el-skeleton-item variant="h1" />
      </div>
      <div class="line">
        <el-skeleton-item variant="h1" />
        <el-skeleton-item variant="h1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.liveCardSkeleton {
  width: calc(50% - 2px);
  height: 221px;
  background-color: var(--theme-skeleton-bg);
  .img {
    height: 104px;
    width: 100%;

    .el-skeleton__image {
      height: 100%;
    }
  }

  .header {
    box-sizing: border-box;
    padding: 0 5px;
    .el-skeleton__h3 {
      margin-top: 5px;
      width: 90%;

      &:nth-child(2) {
        width: 60%;
      }
    }
  }

  .footer {
    margin-top: 5px;
    box-sizing: border-box;
    padding: 0 5px;
    .line {
      display: flex;
      margin-bottom: 8px;
      justify-content: space-between;
      .el-skeleton__h1 {
        &:nth-child(1) {
          width: 70%;
        }

        &:nth-child(2) {
          width: 15%;
        }
      }
    }
  }
}
</style>
