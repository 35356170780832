/* eslint-disable */
import axios from "axios";
import forge from "node-forge";
import CryptoJS from "crypto-js";
import { generateRandomString } from "@/utils/tools";
import router from "@/router";
const pk = process.env.VUE_APP_IM_PUBLIC_KEY;
const publicKey = forge.pki.publicKeyFromPem(pk);
const privateKey = generateRandomString(32);
const iv = generateRandomString(16);
const prkey = publicKey.encrypt(privateKey, "RSA-OAEP", {
  md: forge.md.sha256.create(),
  mgf1: {
    md: forge.md.sha256.create(),
  }, 
});

const ivBase64 = btoa(iv);
const keyBase64 = forge.util.encode64(prkey);
export default function createService(baseURL) {
  // axios拦截器
  const service = axios.create({
    baseURL, // 开发服务器接口,
    timeout: 10 * 1000, // request timeout
  });

  // 请求拦截器
  service.interceptors.request.use(
    async (config) => {
      //   是否加密
      // 请求体
      if (config.data) {
        // 判断是否是表单提交
        let dataStr;
        if (
          config.headers["Content-Type"] === "application/x-www-form-urlencoded"
        ) {
          dataStr = config.data;
        } else {
          dataStr = JSON.stringify(config.data);
        }
        const key = CryptoJS.enc.Utf8.parse(privateKey);
        const ivStr = CryptoJS.enc.Utf8.parse(iv);
        const data = CryptoJS.AES.encrypt(dataStr, key, {
          iv: ivStr,
          mode: CryptoJS.mode.CFB,
          padding: CryptoJS.pad.NoPadding,
        }).toString();
        // console.log('after encrypt:', data, iv, dataStr);
        config.data = {
          encrypted_data: data,
          encrypted_key: keyBase64,
          iv: ivBase64,
        };
      }

      return config;
    },
    (error) => {
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );

  // 响应拦截器
  service.interceptors.response.use(
    (response) => {
      // 解密响应数据
      let res = {};
      if (response.data) {
        const { encrypted_data, iv } = response.data || {};
        // 根据iv和key解密数据，返回解密后的数据
        const key = CryptoJS.enc.Utf8.parse(privateKey);
        const ivStr = CryptoJS.enc.Utf8.parse(iv);
        const bytes = CryptoJS.AES.decrypt(encrypted_data, key, {
          iv: ivStr,
          mode: CryptoJS.mode.CFB,
          padding: CryptoJS.pad.NoPadding,
        });
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        try {
          res = {
            statusCode: response.status,
            statusText: response.statusText,
            data: JSON.parse(decryptedData),
          };
        } catch (e) {
          console.error("Error:", e);
        }
      } else {
        res = {
          statusCode: response?.status,
          statusText: response?.statusText,
          data: response?.data,
        };
      }

      return res;
    },
    async (error) => {
      console.log("err", error);

      if (error.response && error.response?.status === 451) {
        // 路由跳转
        router.push({
          name: "LimitAccess",
        });
      }

      return Promise.reject(error);
    }
  );

  return service;
}
