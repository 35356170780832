<!-- 页面没数据 -->
<template>
  <div class="noData">
    <div class="icon"
    :style="{
      backgroundImage: `url(${iconUrl})`,
    }"
    ></div>
    <div class="tip">
      <slot>{{ $t("noDataPrompt") }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  data() {
    return{
      iconUrl: require("@/assets/client-logos/nodata.webp"),
    }
  }
};
</script>

<style lang="scss" scoped>
.noData {
  // width: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    width: 80px;
    height: 80px;
    background-image: url("@/assets/icons/Common/ufo-nodata.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    // margin-bottom: 20px;
  }

  .tip {
    line-height: 20px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: var(--theme-main-word);
  }
}
</style>
