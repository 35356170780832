<template>
    <div class="scheduleComContainer">
      <!-- 赛事卡片 -->
      <MatchStatus
        :competitionId="competitionId"
        :stats="stats"
        :TMoptionActivity="TMoptionActivity"
        parentComponent="LiveRoom"
      >
      </MatchStatus>
    </div>
</template>

<script>
/* eslint-disable */
import MatchStatus from "@/components/NewmatchStatus/Index.vue";
// import MatchStatus from "@/components/MatchStatus/Index.vue";
// import { mapActions } from "vuex";
export default {
  props: {
    competitionId: String,
    TMoptionActivity: String,
    sportTypeId: Number,
  },
  components: {
    MatchStatus,
  },
  data() {
    return {
      // 实时赛况数据
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
