<template>
  <div class="SwitchLines">
    <!-- <div class="topTitle">
      <div class="titles">{{ $t("switchLines") }}</div>
      <div class="cancel" @click="cancel">{{ $t("cancel") }}</div>
    </div> -->
    <div class="Mcontent" ref="scrollcontainerMcontent">
      <div class="Mcontents">
        <!-- 当前主播 板块 -->
        <div class="Top">
          <div class="Title">
            {{ $t("currentStreamer") }}
          </div>
          <div class="streams">
            <!-- 当前主播的基础信息 -->
            <div
              class="streamsTop"
              @click="toAnchorIntroduction(liveInformation?.streamer_username)"
            >
              <div class="userimg-user-image-wrapper">
                <div class="hotuser-img">
                  <img
                    :src="placeholderAvatar"
                    alt="UFOScore"
                    @load="replaceAvatar"
                  />
                </div>
                <div class="status-indicator islive">
                  <div class="stream-gifs"></div>
                  <div class="title">
                    {{ liveInformation.status ? $t("live") : $t("offLine") }}
                  </div>
                </div>
              </div>
              <div class="streamIntroduce">
                <div class="titleM">
                  {{ liveInformation?.streamer_name }}
                </div>
                <div class="titleX">
                  {{
                    `${$t("followersCount")}:${liveInformation.follower_count} `
                  }}
                </div>
              </div>
            </div>
            <!-- 当前直播的可供切换的线路 -->
            <div class="liveLine">
              <div
                v-for="(item, key) in liveLines"
                :key="key"
                class="line"
                :class="{ active: key === lineKey }"
                @click="changeLine(key, item)"
              >
                {{ $t(item.name) }} {{ key + 1 }}
              </div>
            </div>
          </div>
        </div>
        <!-- 当前比赛的其他直播中的主播 -->
        <div
          class="streamsLists"
          v-if="liveInformation.diversion_streamers?.length > 0"
        >
          <div class="listTitle">
            {{ $t("otherStreamersPrompt") }}
          </div>
          <!-- 主播列表 -->
          <div class="streamsList">
            <div
              class="list"
              v-for="(item, key) in diversionStreamers"
              :key="key"
              @click="toAnchorIntroduction(item.username)"
            >
              <!-- 主播 -->
              <div class="hotuser-content">
                <div class="userimg-user-image-wrapper">
                  <div
                    class="hotuser-img"
                    :style="{
                      backgroundImage: 'url(' + getAvatar(item) + ')',
                    }"
                  ></div>
                  <div class="status-indicator islive" v-if="item.status">
                    <div class="stream-gifs"></div>
                    <div class="title">{{ $t("live") }}</div>
                  </div>
                  <div class="status-indicator" v-else>
                    <div class="title">{{ $t("offLine") }}</div>
                  </div>
                </div>
              </div>
              <!-- 主播姓名 -->
              <div class="streamsInfo">
                <div class="hotuser-text">{{ item.name }}</div>
                <div class="subscriber">
                  <div class="title">
                    {{ `${$t("followersCount")}:${item.follower_count} ` }}
                  </div>
                </div>
              </div>
              <!-- 操作按钮 -->
              <div class="operation">
                <div
                  class="subscription"
                  @click.stop="watchOtherLive(item.live_id)"
                >
                  <div class="follow">{{ $t("watch") }}</div>
                </div>
                <!-- <div class="chat" @click="enterChat">
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "SwitchLines",
  props: ["liveInformation", "liveId", "isShowDrawer"],
  data() {
    return {
      diversionStreamers: [], // '其他主播'主播列表
      lineKey: 0, //线路索引
      placeholderAvatar: require("@/assets/client-logos/nodata.webp"),
      imageBaseUrl: "", //图片基础路径
    };
  },
  computed: {
    /**
     * 获取头像
     */
    getAvatar() {
      return (streamer) => {
        return streamer.isLoaded
          ? this.imageBaseUrl + streamer?.avatar
          : streamer.placeholderAvatar;
      };
    },

    ...mapState({
      currentLiveLineKey: (state) => state.lives.currentLiveLineKey,
      liveLines: (state) => state.lives.currentLiveLines,
    }),
  },
  async created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
    this.diversionStreamers = this.getDiversionStreamers(
      this.liveInformation.diversion_streamers
    );
    this.diversionStreamers.forEach((streamer) =>
      this.loadFullAvatar(streamer)
    );
    await this.getVideoLine();
    this.lineKey = this.currentLiveLineKey
  },
  mounted() {
    // this.watchWsStreamerStatus();
    // this.disableOtherScroll();
  },
  updated() {},
  beforeDestroy() {
    // this.removeWatchWs();
  },
  methods: {
    ...mapMutations("lives", ["SET_CURRENT_LIVE_LINE_KEY"]),
    ...mapActions("lives", ["fetchVideoLine"]),


    // 获取直播线路
    async getVideoLine() {
      this.fetchVideoLine(this.liveInformation.live_id);
    },
    disableOtherScroll() {
      // 禁止滚动 videoContent
      document.addEventListener(
        "touchmove",
        function (event) {
          // 获取事件目标元素
          let target = event.target;
          // 检查是否为videoContent
          while (target !== null) {
            if (target.classList && target.classList.contains("SwitchLines")) {
              event.preventDefault();
              break;
            }
            target = target.parentElement;
          }
        },
        { passive: false }
      );
    },
    /**
     * 获取 可切换的主播 列表
     * 增加属性:占位图 头像加载状态
     * @param { Array } liveInformation.diversion_streamers
     */
    getDiversionStreamers(original) {
      return original.map((item) => {
        return {
          ...item,
          placeholderAvatar: require("@/assets/client-logos/nodata.webp"),
          isLoaded: false,
        };
      });
    },
    /**
     * 跳转路由 主播详情页面
     */
    toAnchorIntroduction(username) {
      this.$router.push({
        name: "AnchorIntroduction",
        query: {
          id: username,
        },
      });
    },

    /**
     * 加载主播头像
     * @param { Object } streamer 主播
     */
    loadFullAvatar(streamer) {
      const img = new Image();
      img.src = this.imageBaseUrl + streamer?.avatar;
      img.onload = () => {
        streamer.isLoaded = true; // 图像加载完成后更新状态
      };
    },

    /**
     * 动态替换'当前主播'头像
     */
    replaceAvatar() {
      this.placeholderAvatar =
        this.imageBaseUrl + this.liveInformation?.streamer_avatar;
    },

    // 切换线路
    changeLine(key, item) {
      this.lineKey = key;
      this.SET_CURRENT_LIVE_LINE_KEY({ key });
      this.$emit("changeCurrentVideo", item);
    },
    cancel() {
      this.$emit("cancel");
    },

    /**
     * 观看其他主播的直播
     */
    watchOtherLive(live_id) {
      this.$emit("refreshLiveRoom", { live_id });
    },

    closeDrawer() {
      this.$router.go(-1);
    },
    enterChat() {
      // console.log("chat");
    },

    /**
     * 监听ws 主播 直播状态的更新推送
     */
    // watchWsStreamerStatus() {
    //   this.handleGlobalMessge({
    //     type: ["streamer_status"],
    //     callback: (message) => {
    //       const { data } = message;
    //       this.updataStreamerStatus(data);
    //     },
    //     id: "SwitchLinesStreamerStatus",
    //   });
    // },

    /**
     * 移除ws推送监听
     */
    // removeWatchWs() {
    //   this.handleGlobalMessge({
    //     type: ["streamer_status"],
    //     id: "SwitchLinesStreamerStatus",
    //   });
    // },

    /**
     * 更新 主播 直播状态
     */
    // updataStreamerStatus(data) {
    //   const targetIndex = this.diversionStreamers.findIndex(
    //     (item) => item.username === data.username && data.status === 0
    //   );
    //   if (targetIndex !== -1) {
    //     this.diversionStreamers.splice(targetIndex, 1);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/SwitchLines/Index.scss";
</style>
