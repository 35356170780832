import { render, staticRenderFns } from "./FootballIndex.vue?vue&type=template&id=5fb6c93a&scoped=true"
import script from "./FootballIndex.vue?vue&type=script&lang=js"
export * from "./FootballIndex.vue?vue&type=script&lang=js"
import style0 from "./FootballIndex.vue?vue&type=style&index=0&id=5fb6c93a&prod&lang=scss&scoped=true"
import style1 from "./FootballIndex.vue?vue&type=style&index=1&id=5fb6c93a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb6c93a",
  null
  
)

export default component.exports