<template>
  <!-- 赛事卡片列表 -->
  <div
    class="MatchCardList"
    ref="MatchCardListScroll"
    @wheel="handleWheel"
    @scroll="handleScroll"
  >
    <div class="MatchCardListContent">
      <!-- 赛事卡片 -->
      <div
        class="matchCard"
        v-for="(item, key) in competitionData"
        :key="key"
        @click="
          jumpRoute(
            item.sport_type_id,
            item.competition_id,
            item.live_id,
            item.source_id
          )
        "
      >
        <!-- 标题栏 -->
        <div class="titleNav">
          <div class="titleNavContent">
            <div class="title">{{ item.league }}</div>
            <!-- 赛事进行状态 未开始/进行中 -->
            <!-- <div class="status">
              <div class="statusIcon" v-if="item.coverage.mlive"></div>
              <div class="statusText">
                {{ item.competition_status.translation }}
              </div>
            </div> -->
            <div class="bottomNav">
              <!-- <div class="bottomNavTime">{{ item.timeFomat }}</div> -->
            </div>
          </div>
        </div>

        <!-- 内容 -->
        <div class="matchCardTeams">
          <!-- 队伍 -->
          <!-- <div class="matchCardTeam">
            <div
              class="teamLogo"
              :style="{
                backgroundImage: 'url(' + getTeamLogo(item, 'home') + ')',
              }"
            ></div>

            <div class="teamName">{{ item.home_team }}</div>

            <div
              class="teamScore"
              :class="{
                yellowColor: item.away_scores?.[0] < item.home_scores?.[0],
              }"
            >
              {{ item.home_scores?.[0] }}
            </div>
          </div>
          <div class="matchCardTeam">
            <div
              class="teamLogo"
              :style="{
                backgroundImage: 'url(' + getTeamLogo(item, 'away') + ')',
              }"
            ></div>
            <div class="teamName">{{ item.away_team }}</div>
            <div
              class="teamScore"
              :class="{
                yellowColor: item.home_scores?.[0] < item.away_scores?.[0],
              }"
            >
              {{ item.away_scores?.[0] }}
            </div>
          </div> -->
          <div class="teamLogos">
            <van-image
              class="teamLogo"
              fit="contain"
              round
              :src="item.home_team_logo"
            >
              <template v-slot:error>
                  <van-image class="teamLogo" fit="scale-down" :src="placeholderLogo" />
              </template>
            </van-image>
            <!-- <div
              class="teamLogo"
              :style="{
                backgroundImage: 'url(' +  getTeamLogo(item, 'home') + ')',
              }"
            ></div> -->
            <div class="teamVs">VS</div>
            <van-image
              class="teamLogo"
              fit="contain"
              round
              :src="item.away_team_logo
              ">
              <template v-slot:error>
                  <van-image class="teamLogo" fit="scale-down" :src="placeholderLogo" />
              </template>
            </van-image>
            <!-- <div
              class="teamLogo"
              :style="{
                backgroundImage: 'url(' + getTeamLogo(item, 'away') + ')',
              }"
            ></div> -->
          </div>
          <div class="teamNames">
            <div class="teamName">{{ item.home_team }}</div>
            <div class="teamName away">{{ item.away_team }}</div>
          </div>
        </div>

        <!-- 时间栏 -->
        <!-- <div class="bottomNav">
          <div class="bottomNavTime">{{ item.timeFomat }}</div> -->
        <!-- <div class="bottomNavRight">
            <div class="title">{{ $t("details") }}</div>
            <div class="icon"></div>
          </div> -->
        <!-- </div> -->

        <!-- 底部状态栏 -->
        <div class="titleDownNav">
          <div class="titleDownNavContent">
            <!-- 赛事进行状态 未开始/进行中 -->
            <div class="status">
              <!-- 进行中 -->
              <div
                class="stream-gifs"
                v-if="item.competition_status?.id === 2"
              ></div>
              <div class="statusText" v-if="item.competition_status?.id === 2">
                {{ "Now" }}
              </div>
              <div class="statusText" v-if="item.competition_status?.id !== 2">
                {{ item.timeFomat }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slideLeftPrompt" v-show="!isScrolling">
      <div class="icon"></div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { debounce } from "lodash";
// import { mapActions } from "vuex";
export default {
  name: "MatchCardList",
  props: {
    competitions: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      placeholderLogo: require("@/assets/client-logos/img-logo-placeholder.webp"),
      competitionData: [], // 经过处理的赛事数据
      isScrolling: false,
      imageBaseUrl: "",
    };
  },
  computed: {
    /**
     * 球队logo
     */
    getTeamLogo() {
      return (competition, venue) => {
        const teamLoaded = venue + "Loaded";
        const teamLogo = venue + "_team_logo";
        return competition[teamLoaded]
          ? this.imageBaseUrl + competition?.[teamLogo]
          : competition.placeholderLogo;
      };
    },
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
    this.processCompetitions();
  },
  mounted() {
    // 实时更新比赛数据
    // this.updataCompetitionsData();
  },
  updated() {},
  beforeDestroy() {
    // 移除ws推送监听
  },
  watch: {
    competitions: {
      handler: function () {
        this.processCompetitions();
      },
      deep: true,
    },
  },
  methods: {
    // 实时更新比赛数据
    // ...mapActions("globalWS", [
    //   "handleGlobalMessge",
    //   "removeHandleGlobalMessge",
    // ]),
    // updataCompetitionsData() {
    //   this.handleGlobalMessge({
    //     type: ["football_scores", "basketball_scores"],
    //     callback: (message) => {
    //       const { data } = message;
    //       this.updataCompetitions(data);
    //     },
    //     id: "MatchCardList",
    //   });
    // },
    updataCompetitions(data) {
      // console.log("【监听到比赛数据有更新】", data);
      const itemIndex = this.competitionData.findIndex(
        (item) => item.competition_id === data.competition_id
      );
      if (itemIndex !== -1) {
        const { competition_status, home_scores, away_scores } = data;
        if (
          competition_status &&
          Object.keys(competition_status).length !== 0
        ) {
          this.competitionData[itemIndex].competition_status =
            competition_status;
        }
        if (home_scores && home_scores.length !== 0) {
          this.competitionData[itemIndex].home_scores = home_scores;
        }
        if (away_scores && away_scores.length !== 0) {
          this.competitionData[itemIndex].away_scores = away_scores;
        }
      }
    },

    /**
     * 根据给定的时间戳格式化比赛日期。
     * @param {number} timestamp - 比赛的时间戳（单位：秒）
     * @returns {string} 格式化后的日期字符串
     */
    formatMatchDate(timestamp) {
      const matchDate = dayjs(timestamp * 1000); // 将时间戳转换为 Day.js 日期对象
      let formattedDate = matchDate.format("MM/DD HH:mm"); //  "年/月/日 时:分"
      return formattedDate;
    },

    /**
     * 处理赛事数据
     * 新增格式化的时间/logo占位图/logo加载状态
     */
    processCompetitions() {
      let competitionData = this.competitions.map((item) => {
        const timeFomat = this.formatMatchDate(item.match_time);
        return {
          ...item,
          timeFomat: timeFomat,
          placeholderLogo: require("@/assets/client-logos/nodata.webp"),
          homeLoaded: false,
          awayLoaded: false,
        };
      });
      this.competitionData = competitionData;
      // console.log(this.competitionData);
      // 加载球队logo
      this.competitionData.forEach((competition) => {
        this.loadTeamLogo(competition, "home");
        this.loadTeamLogo(competition, "away");
      });
      // 如果比赛数量少于两场 不显示可左滑图标
      if (this.competitionData.length < 3) {
        this.isScrolling = true;
      }
    },

    /**
     * 加载球队Logo
     * @param { Object } competition 赛事数据
     * @param { String } venue 主客场 home-主场 away-客场
     */
    loadTeamLogo(competition, venue) {
      const teamLogo = venue + "_team_logo";
      const teamLoaded = venue + "Loaded";
      const img = new Image();
      img.src = this.imageBaseUrl + competition?.[teamLogo];
      img.onload = () => {
        competition[teamLoaded] = true; // 图像加载完成后更新状态
      };
    },

    /**
     * 跳转路由 直播间 或 比赛详情页
     * @param competition_id 比赛id
     * @param live_id 直播id
     */

    // FIXME:需要适配 比赛详情页跳转
    jumpRoute(sport_type_id, competition_id, live_id, source_id) {
      if (live_id) {
        // 有直播间
        this.$router.push({
          name: "LiveRoom",
          query: {
            liveId: live_id,
            competitionId: competition_id,
            sportTypeId: sport_type_id,
            sourceId: source_id,
          },
        });
      } else {
        // 无直播间
        this.$router.push({
          name: "CompetitionDetails",
          query: {
            id: competition_id,
            sportTypeId: sport_type_id,
          },
        });
      }
    },

    handleWheel(event) {
      const scrollContainer = this.$refs.MatchCardListScroll;
      // 检查事件的目标是否是滚动容器或其子元素
      if (scrollContainer.contains(event.target)) {
        // 阻止默认的滚轮滚动行为
        event.preventDefault();
        // 根据鼠标滚轮的滚动量来改变scrollLeft值
        scrollContainer.scrollLeft += event.deltaY * 2;
      }
    },

    handleScroll: debounce(function () {
      const scrollContainer = this.$refs.MatchCardListScroll;
      // 是否已经滚到右边的尽头
      const atRightEdge =
        scrollContainer.scrollWidth - scrollContainer.scrollLeft <=
        scrollContainer.clientWidth;
      // 尽头不显示 右箭头
      if (atRightEdge) {
        this.isScrolling = true;
      } else {
        this.isScrolling = false;
      }
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
