<!-- 更多直播 -->
<template>
  <div class="LiveCard" @click="toLiveRoom(live)">
    <div class="liveCardContent">
      <div
        class="image-container"
        :style="{
          backgroundImage: 'url(' + placeholderImg + ')',
        }"
      >
        <!-- 图片底部遮罩 主播信息 -->
        <div class="image-footer">
          <div class="liveuser">
            <!-- 头像 -->
            <div
              class="liveuser-img"
              :style="{
                backgroundImage: 'url(' + imageBaseUrl + live.streamer?.avatar + ')',
              }"
            ></div>
            <!-- 昵称 -->
            <div class="liveuser-nickname">{{ live.streamer?.name }}</div>
          </div>
        </div>
      </div>

      <!-- 热度 -->
      <div class="viewer-container">
        <div class="fire"></div>
        <div class="viewCount">{{ formattedViewCount }}</div>
      </div>
      <div class="liveCardContent-content">
        <div class="content">{{ live.live_name }}</div>
        <div class="leagueArea" v-if="live?.league">
          <div class="name">{{ live.league.name }}</div>
        </div>
        <div class="scoreArea" v-if="live.match?.length > 0">
          <div class="teamArea" v-for="(match, key) in live.match" :key="key">
            <!-- <div class="teamLogo">
              <van-image
                width="25"
                height="25"
                round
                :src="match?.team_logo || fixImage"
                :error-icon="errorIamge"
              ></van-image>
            </div> -->
            <div class="teamName">{{ match?.team_name }}</div>
            <div class="teamScore">{{ match?.score }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveCard",
  props: {
    live: {
      type: Object,
      require: true,
    },
  },
  watch: {
    live: {
      handler() {
        this.replaceBackgroundImage();
      },
      deep: true,
    },
  },
  data() {
    return {
      placeholderImg: require("@/assets/client-logos/img-placeholder.webp"),
      fixImage: require("@/assets/client-logos/img-placeholder.webp"),
      errorIamge: require("@/assets/client-logos/logo-error.webp"),
      imageBaseUrl: "", // 图片基础路径
    };
  },
  computed: {
    formattedViewCount() {
      return this.live.view_count >= 10000
        ? (this.live.view_count / 10000).toFixed(1) + "w"
        : this.live.view_count;
    },
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
    
    this.replaceBackgroundImage();
  },
  methods: {
    /**
     * 动态替换背景图像
     */
    replaceBackgroundImage() {
      // 预加载完整图像
      const img = new Image();
      img.src =this.imageBaseUrl +  this.live.cover_image;
      img.onload = () => {
        // 图像加载完成后，更新背景图像
        this.placeholderImg = this.imageBaseUrl +  this.live.cover_image;
      };
    },

    /**
     * 跳转路由 直播间 或 比赛详情页
     * @param competition_id 比赛id
     * @param live_id 直播id
     */
    toLiveRoom(live) {
      const {competition_id, live_id, sport_type_id, source_id} = live;
      const currentRoute = this.$route.path; // 获取当前路由路径
      if (currentRoute === "/LiveRoom") {
        // 由直播间跳转另一个直播间 通知父组件AnchorInformation
        this.$emit("refreshParent", { competition_id, live_id, sport_type_id, source_id });
        return;
      }

      if (live_id) {
        // 有直播间
        this.$router.push({
          name: "LiveRoom",
          query: {
            liveId: live_id,
            competitionId: competition_id,
            sportTypeId: sport_type_id,
            sourceId: source_id

          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Index.scss";
</style>
