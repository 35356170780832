<template>
  <div class="linkMessageItem">
    <div class="linkMessageIcon"></div>
    <div class="nameArea">
      <div class="name">{{message.content}}</div>
      <!-- <el-link :href="message.link" target="_blank" type="success">{{
        message.link
      }}</el-link> -->
      <a :href="message.link" target="_blank" class="link">{{
        message.link
      }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkMessageItem',
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
