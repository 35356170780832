<template>
  <div class="imageMessage">
    <van-image
      :src="message.url"
      alt="image"
      lazy-load
      @click="previewImg(message.url)"
      :width="width"
      :height="height"
      @load="onLoad"
    >
      <template v-slot:loading>
        <div :style="{ width: width, height: height }"></div>
      </template>
      <template v-slot:error>
        <div :style="{ width: width, height: height }"></div>
      </template>
    </van-image>
    <!-- <div
      class="imageArea"
      @click="previewImg(message.url)"
      :style="{
        backgroundImage: `url(${message.url})`,
        width: width,
        height: height,
        backgroundSize: 'cover',
      }"
    ></div> -->
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "ImageMessageItem",
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      width: "80px",
      height: "150px",
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    previewImg(imgUrl) {
      ImagePreview({
        images: [imgUrl],
        closeable: true,
      });
    },
    onLoad() {
      this.getImgSize(this.message.url).then((res) => {
        // 适配短图和长图
        const width = (res.width / res.height) * 150;
        if (width > 300) {
          this.width = "300px";
        } else {
          this.width = width + "px";
        }
        this.height = "150px";
      });
    },
    // 获取图片的宽高
    getImgSize(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
        img.onerror = () => {
          reject("image error");
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
:deep {
  .van-image__error {
    width: 100%;
    height: 100%;
  }
}
</style>
