/* eslint-disable */
const state = {
    feedbackData: null,
};
const mutations = {
  SET_FEEDBACK_DATA(state, data) {
    state.feedbackData = data;
  },
};
const actions = {
  setFeedback({ commit }, value) {
    commit("SET_FEEDBACK_DATA", value);
  },
  clearFeedback({ commit }) {
    commit("SET_FEEDBACK_DATA", null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
