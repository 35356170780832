import request from "@/services/axios/liveChatRequest";
// 直播聊天发送消息
export function liveChatSendMessage(data) {
  return request({
    url: "/send-message",
    method: "post",
    data,
  });
}

// 直播聊天获取历史消息
export function getLiveChatHistoryMessage(params) {
  return request({
    url: "/history-messages",
    method: "get",
    params,
  });
}
