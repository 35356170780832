import request from "@/services/axios/commonRequest";
// import { request } from "@/services/axios/commonRequest"; // FIXME: H哥本地服务

// 获取任务列表
export function getTasks(params) {
  return request({
    url: "/task/tasks",
    method: "get",
    params,
  });
}
// 触发任务，埋点上报
export function triggerTask(data) {
  return request({
    url: "/task/task_event_trigger",
    method: "post",
    data,
  });
}
// 获取签到的相关数据
export function getSignIn(params) {
  return request({
    url: "/task/signin_data",
    method: "get",
    params,
  });
}
// 签到
export function signIn(params) {
  return request({
    url: "/task/signin",
    method: "post",
    params,
  });
}

// 个人资产
export function myAssets() {
  return request({
    url: "/asset/my",
    method: "get",
  });
}

// 个人资产流水
export function myAssetsFlow(params) {
  return request({
    url: "/asset/record",
    method: "get",
    params,
  });
}

// 专属分享链接的码
export function myShareCode() {
  return request({
    url: "/my_share_code",
    method: "get",
  });
}

// 获取活动列表
export function getActivityList(params) {
  return request({
    url: "/activity/list",
    method: "get",
    params,
  });
}

/**
 * 获取活动详情
 * @param {Object} params { activity_id: Number, language_id: Number }
 * @returns
 */
export function getActivityDetail(params) {
  return request({
    url: "/activity/detail",
    method: "get",
    params,
  });
}
