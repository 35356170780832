<template>
  <div class="BindPhoneDialog">
    <van-dialog
      v-model="isShowDialog"
      close-on-click-overlay
      theme="round-button"
      :confirm-button-text="$t(confirmButtonText)"
      confirm-button-color="#6418C3"
      @confirm="toBindPhone"
      class="BindPhoneDialog"
    >
      <div class="icon"></div>
      <div class="text">
        {{ $t("bindPhonePrompt") }}
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getIsBindPhoneAndEmail } from "@/services/api/Home/index";
import { getCookie, setCookie } from "@/utils/authCookie";
import dayjs from "dayjs";
export default {
  props: {
    // 是否已登录
    isLogin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBindPhone: true, // 是否绑定手机
      isShowDialog: false, // 是否展示本弹框组件
      confirmButtonText: "goBind", // 按钮文本国际化
    };
  },
  computed: {},
  async mounted() {
    await this.loadIsBindPhoneAndEmail();
    this.changeShowDialog();
  },
  methods: {
    /**
     * 跳转路由 更改手机号
     */
    toBindPhone() {
      this.$router.push({
        name: "BindPhone",
      });
    },
    /**
     * 登录状态下一天只显示一次
     */
    changeShowDialog() {
      // dayjs 获取年月日
      const nowDate = dayjs().format("YYYY-MM-DD");
      const dialogShowTime = getCookie("dialogShowTime");
      if(nowDate !== dialogShowTime) {
      this.isShowDialog = this.isLogin && !this.isBindPhone;
      setCookie("dialogShowTime", nowDate);
      }
    },

    /**
     * 加载用户是否已绑定手机号和邮箱
     */
    async loadIsBindPhoneAndEmail() {
      if (!this.isLogin) {
        return;
      }

      // 登录状态下才调用
      try {
        const res = await getIsBindPhoneAndEmail();
        if (res?.statusCode === 200) {
          this.isBindPhone = res.data.has_bind_phone;
        } else {
          console.error(res);
        }
      } catch (error) {
        console.error(`加载用户是否已绑定手机号和邮箱出错:`, error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.BindPhoneDialog {
  z-index: 9999;
}

:deep .van-dialog {
  width: 312px;
  background-color: var(--theme-box-1) !important;
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // 插槽部分
  .van-dialog__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      width: 130px;
      height: 130px;
      background-image: url("@/assets/icons/Home/icon-bind-phone@3x.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 130px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .text {
      box-sizing: border-box;
      padding-left: 28px;
      padding-right: 28px;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: var(--theme-main-word);
      margin-bottom: 30px;
    }
  }

  .van-goods-action.van-dialog__footer {
    width: 280px !important;
    background-color: var(--theme-box-1);
    padding: 0;

    .van-dialog__confirm {
      width: 280px !important;
    }

    .van-button {
      height: 42px;
      border-radius: 21px;
      width: 280px !important;

      .van-button__content {
        width: 280px !important;
      }

      .van-button__text {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: var(--theme-whiteWord);
      }
    }
  }
}
</style>
