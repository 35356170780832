/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { forbidden } from "@/utils/tools";
require(`./styles/global_${process.env.VUE_APP_BRAND}.scss`);
import "cube-ui/lib/cube.min.css";
import "./assets/fonts/iconfont/iconfont.css"; // FIXME: 需清理掉
// UI组件库
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import Toastification from "vue-toastification";
import { getServerFastestUrl } from "@/services/axios/basePath";
import "vue-toastification/dist/index.css";
import Cube from "cube-ui";
import { languageObj } from "./locales";
import { Skeleton, SkeletonItem } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import {
  Button,
  Icon,
  Popup,
  Form,
  RadioGroup,
  Radio,
  Swipe,
  SwipeItem,
  Checkbox,
  Dialog,
  Toast as VantToast,
  // Toast,
  Field,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Tab,
  Tabs,
  CountDown,
  Badge,
  SwipeCell,
  Progress,
  Uploader,
  Loading,
  Picker,
  Popover,
  Image,
  Divider,
  Sidebar,
  SidebarItem,
  IndexBar,
  IndexAnchor,
  Cell,
  CellGroup,
  NoticeBar,
  Circle,
  Lazyload,
  Sticky,
  Switch,
  Stepper,
} from "vant";

// 国际化
import VueI18n from "vue-i18n";
import ms from "./locales/ms";
import en from "./locales/en";
import vi from "./locales/vi";
import zh_cn from "./locales/zh-cn";
import zh_hk from "./locales/zh-hk";
import VConsole from "vconsole";
// import './registerServiceWorker';

// 语言翻译
Vue.use(VueI18n);
const language_id = localStorage.getItem("language_id");
const i18n = new VueI18n({
  locale: languageObj[language_id], //默认
  messages: {
    ms, // 马来语
    en, // 英语
    vi, // 越南语
    zh_cn, // 简体中文
    zh_hk, // 繁体中文
  },
  // 隐藏警告
  silentTranslationWarn: true,
});
export { i18n };

Vue.config.productionTip = false;

const isForbidden = await forbidden();
if (!isForbidden) {
  // 域名服务
  await getServerFastestUrl();

  Vue.use(VueVirtualScroller);
  // vant组件注册
  Vue.use(Button);
  Vue.use(Icon);
  Vue.use(Popup);
  Vue.use(Form);
  Vue.use(RadioGroup);
  Vue.use(Radio);
  Vue.use(Swipe);
  Vue.use(SwipeItem);
  Vue.use(Checkbox);
  Vue.use(Dialog);
  // Vue.use(Toast); // 与 vue-toastification插件冲突
  Vue.prototype.$vantToast = VantToast;
  VantToast.setDefaultOptions({ duration: 1500 });
  Vue.use(Field);
  Vue.use(DatetimePicker);
  Vue.use(Collapse);
  Vue.use(CollapseItem);
  Vue.use(Tab);
  Vue.use(Tabs);
  Vue.use(CountDown);
  Vue.use(Badge);
  Vue.use(SwipeCell);
  Vue.use(Progress);
  Vue.use(Uploader);
  Vue.use(Loading);
  Vue.use(Picker);
  Vue.use(Popover);
  Vue.use(Image);
  Vue.use(Divider);
  Vue.use(Sidebar);
  Vue.use(SidebarItem);
  Vue.use(IndexBar);
  Vue.use(IndexAnchor);
  Vue.use(Cell);
  Vue.use(CellGroup);
  Vue.use(NoticeBar);
  Vue.use(Circle);
  Vue.use(Sticky);
  Vue.use(Lazyload);
  Vue.use(Switch);
  Vue.use(Stepper);

  // CubeUI
  Vue.use(Cube);

  // ElementUI 组件注册
  Vue.use(Skeleton);
  Vue.use(SkeletonItem);

  // function asyncGetContainer() {
  //   return new Promise((resolve) => {
  //     const observer = new MutationObserver(function (mutations, me) {
  //       const goalToastContainer = document.getElementById("CompetitionPage");
  //       if (goalToastContainer) {
  //         // me.disconnect();
  //         resolve(goalToastContainer);
  //       }
  //     });
  //     observer.observe(document, {
  //       childList: true,
  //       subtree: true,
  //     });
  //   });
  // }
  Vue.use(Toastification, {
    // container: asyncGetContainer,
    maxToasts: 3,
    position: "bottom-center",
    icon: false, // 隐藏默认icon
    closeOnClick: false, // 关闭‘点击关闭’
    closeButton: false, // 隐藏关闭按钮
    pauseOnFocusLoss: false, // 关闭失焦时暂停
    pauseOnHover: false, // 关闭悬停时暂停
  });

  // 在页面加载时检查 localStorage 中的标记
  if (localStorage.getItem("vConsole") === "true") {
    window.vConsole = new VConsole();
  }

  new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n,
    // created() {
    //   const html = document.documentElement;
    //   const windowHeight = html.clientHeight;
    //   const windowWidth = html.clientWidth;
    //   //  判断是否为ipad/PC
    //   if (windowWidth > 500) {
    //     // 根据屏幕宽度与 375/812
    //     let width =
    //       (windowHeight * 375) / 812 >= 375 ? (windowHeight * 375) / 812 : 375;
    //     // 设置body的宽度
    //     document.body.style.width = width + "px";
    //     // 设置一个变量保存当前的宽度，用于绝对定位的元素
    //     document.documentElement.style.setProperty("--maxWidthPx", `${width}px`);
    //   } else {
    //     document.documentElement.style.setProperty(
    //       "--maxWidthPx",
    //       `${windowWidth}px`
    //     );
    //   }
    //   // 设置html的字体大小
    // },
  }).$mount("#app");
}
