/**
 * @file 繁体中文 本地化语言文件
 */

//組件
const AgreementArea = {
  userAgreement: "用戶協議",
  privacyPolicy: "隱私政策",
  termsOfService: "服務條款",
};

const AnchorAvatarAndName = {
  live: "直播中",
  offLine: "未開播",
};

const AnchorInfoCard = {
  live: "直播中",
  alreadyPrompt: "已開播",
  watch: "去觀看",
  follow: "關註",
  following: "已關註",
  subscribe: "訂閱",
  streamerSchedule: "主播賽程",
  noDataPrompt: "暫無數據",
  unfollowPrompt: "是否確認取消關註?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  hours: "小時",
  mins: "分鐘",
};

const AnchorInformation = {
  recommendedLiveStreams: "推薦直播",
  noDataPrompt: "暫無數據",
};

const BasketballScoreIndex = {
  details: "詳情",
  over: "完",
  pointsDifference: "分差",
  totalScore: "總分",
  live: "直播中",
  unSubscribePrompt: "是否確認取消訂閱?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
};

const BasketballSmallBig = {
  details: "詳情",
  over: "完",
  pointsDifference: "分差",
  totalScore: "總分",
  live: "直播中",
  unSubscribePrompt: "是否確認取消訂閱?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
  overIndex: "大",
  underIndex: "小",
};

const BindPhoneDialog = {
  bindPhonePrompt: "為了您的賬號安全，請綁定手機號，以便我們為您提供更好的服務",
  goBind: "去綁定",
};

const BottomNavigationBar = {
  home: "首頁",
  matches: "比賽",
  recommend: "推薦",
  chat: "聊天",
  personal: "個人",
  discovery: "發現",
  money: "賺錢",
};

const ChatInterface = {
  noCsPrompt: "暫時無法聊天",
  uploadRulePrompt: "請上傳jpg、jpeg、png格式圖片",
  uploadSizePrompt: "圖片大小不能超過10M",
  sendError: "發送失敗",
  picSendError: "圖片發送失敗",
  InternetErrorPrompt: "網絡錯誤",
  bannedSendPrompt: "您已被禁言",
};

const ChatList = {
  noNewPrompt: "暫無消息",
  logInPrompt: "登錄可查看內容，請先登錄",
  logIn: "登錄",
  group: "群组",
};

const ChatListItem = {
  withdrawMessage: "撤回了一條消息",
  bannedPrompt: "被禁言",
  unBanPrompt: "被解除禁言了",
  joinGroupPrompt: "加入了群組",
  leftGroupPrompt: "退出了群組",
  picture: "圖片",
  link: "鏈接",
  emoji: "動畫表情",
  addedFriendPrompt: "已添加為好友",
};

const CompetitionList = {
  logInPrompt: "登錄可查看內容，請先登錄",
  logIn: "登錄",
  today: "今天",
  Monday: "星期一",
  Tuesday: "星期二",
  Wednesday: "星期三",
  Thursday: "星期四",
  Friday: "星期五",
  Saturday: "星期六",
  Sunday: "星期日",
  noGames: "暫無比賽",
  toCollapse: "點擊收起",
  toExpand: "點擊展開"
};

const CompetitionListItem = {
  details: "詳情",
  unSubscribePrompt: "是否確認取消訂閱?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
};

const CompetitionMode = {
  matchMode: "比賽模式",
  confirm: "確認",
  cancel: "取消",
};

const EmojiCom = {
  logAndSign: "立即登錄/註冊",
  yes: "是",
  no: "否",
};

const ExponentCom = {
  company: "公司",
  initial: "初始",
  ongoing: "即時",
  preMatch: "賽前",
  noDataPrompt: "暫無數據",
  handicap: "讓球",
  WinDrawLose: "勝平負",
  totalGoals: "總進球",
  cornerKick: "角",
  pleaseSelect: "請選擇",
  confirm: "確認",
  cancel: "取消",
  FH: "半",
  FT: "全場",
  pinnedPrompt: "已置頂至「廣場」",
};

const FollowedStreamers = {
  live: "直播中",
  follow: "關註",
  following: "已關註",
  chat: "聊天",
  noDataPrompt: "暫無數據",
  unfollowPrompt: "是否確認取消關註?",
  yes: "是",
  no: "否",
  InternetErrorPrompt: "網絡錯誤",
};

const FootballCornerIndex = {
  details: "詳情",
  over: "完",
  FH: "半",
  cornerKick: "角",
  unSubscribePrompt: "是否確認取消訂閱?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
};

const FootballScoreIndex = {
  details: "詳情",
  over: "完",
  FH: "半",
  cornerKick: "角",
  live: "直播中",
  unSubscribePrompt: "是否確認取消訂閱?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
};

const FootballScoreItem = {
  details: "詳情",
  over: "完",
  FH: "半",
  cornerKick: "角",
  live: "直播中",
  unSubscribePrompt: "是否確認取消訂閱?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
};

const GoalToast = {
  goal: "進球",
};

const LiveRecommend = {
  noDataPrompt: "暫無數據",
  recoverLosses: "回血",
  winningBetSlip: "紅單",
};

const LiveRecommendList = {
  victoryOrDefeat: "勝負",
};

const MatchCardList = {
  details: "詳情",
};

const MatchStatus = {
  noDataPrompt: "暫無數據",
};

const MoreStreams = {
  switchLines: "切換線路",
  cancel: "取消",
  currentStreamer: "當前主播",
  live: "直播中",
  offLine: "未開播",
  followersCount: "關註",
  otherStreamersPrompt: "當前比賽還有其他主播正在直播中",
  watch: "去觀看",
};

const NewMatchStatus = {
  team: "球隊",
  halfTimeScore: "半場比分",
  shotOnTarget: "射正球門",
  missedShot: "射偏球門",
  attack: "進攻",
  dangerousAttack: "危險進攻",
  number3PointGoals: "3分球進球數",
  number2PointGoals: "2分球進球數",
  penaltyKickGoals: "罰球進球數",
  freeThrowPercentage: "罰球命中率%",
  numberOfPausesRemaining: "剩余暫停數",
  numberOfFouls: "犯規數",
  totalNumberOfPauses: "總暫停數",
  threePointShot: "3分球",
  twoPointShot: "2分球",
  shoot: "投籃",
  penalty: "罰球",
  firstHalf: "上半場",
  secondHalf: "下半場",
  redCard: "紅牌",
  yellowCard: "黃牌",
};

const NoData = {
  noDataPrompt: "暫無數據",
};

const NoticeMessage = {
  addedFriendPrompt: "已添加為好友",
  withdrawMessage: "撤回了一條消息",
  withdrawMessageByAdmin: "被管理員撤回了一條消息",
  joinGroupPrompt: "加入了群組",
  leftGroupPrompt: "退出了群組",
  removeGroupPrompt: "被管理員移出了群組",
  bannedPrompt: "被禁言",
  unBanPrompt: "被解除禁言了",
};

const OddsStatisticsList = {
  initial: "初始",
  ongoing: "即時",
  preMatch: "賽前",
  HW: "主勝",
  Dr: "平局",
  AW: "客勝",
  max: "最高值",
  min: "最低值",
  average: "平均值",
};

const RecommendCom = {
  live: "直播中",
  noDataPrompt: "暫無數據",
};

const RecommendCompetitions = {
  today: "今天",
  winsInARow: "連中",
  noLivePrompt: "暫無直播",
};

const RecommendStreamerList = {
  noDataPrompt: "暫無數據",
};

const RecommendStreamerListItem = {
  hitOfTheWeek: "周命中",
  victoryOrDefeat: "勝負",
  check: "查看",
  chat: "聊天",
  quarterlyProfit: "季度獲利",
};

const RecommendStreamers = {
  recommendedByExperts: "達人推薦",
  winsInARow: "連中",
  changeBatch: "換一批",
  follow: "關註",
  following: "已關註",
  unFollowing: "取關",
  noDataPrompt: "暫無數據",
  logAndSign: "立即登錄/註冊",
  yes: "是",
  no: "否",
  InternetErrorPrompt: "網絡錯誤",
  unfollowPrompt: "是否確認取消關註?",
};

const RecordChart = {
  record: "戰績",
  allWaysToPlay: "全部玩法",
  fromFarToNear: "由遠及近",
  lastTenGames: "近10場:",
  longestConsecutiveHit: "最長連中",
  hitRate: "命中率",
  currentPropmt: "場最近比賽",
};

const RegionalLanguageSelection = {
  countryAndLanguagePrompt:
    "請謹慎選擇您的國家/地區和語言，該選擇將影響到後續功能的使用，且後續無法修改",
  getUserInfoErrorPrompt: "獲取用戶信息失敗",
  getAreaAndLanguageErrorPrompt: "獲取地區語言失敗",
};

const ScheduleCom = {
  startTime: "開始時間",
};

const SchemeCard = {
  planList: "方案列表",
  release: "發布",
  draw: "和",
  win: "中",
  halfWin: "半",
  lost: "未",
  halfLost: "半",
  all: "全部",
  guaranteedCompensation: "包賠",
  free: "免費",
  toll: "收費",
  victoryOrDefeat: "勝負",
};

const SquareCom = {
  watchPrompt: "人正在觀看",
  startedPrompt: "分鐘前開播",
  chat: "聊天",
  sendMessageNeedLoginPrompt: "請登錄後發送消息",
  assistant: "管理員",
  audience: "用戶",
  newMessage: "新消息",
  confirmSend: "確認發送",
  cancelSend: "取消",
};

const StatisticalChart = {
  hitRate: "命中率",
};

const SwitchLines = {
  switchLines: "切換線路",
  cancel: "取消",
  currentStreamer: "當前主播",
  live: "直播中",
  offLine: "未開播",
  followersCount: "關註",
  otherStreamersPrompt: "當前比賽還有其他主播正在直播中",
  watch: "去觀看",
  LINE: "線路",
  VIP: "VIP",
  OFFICIAL: "官方",
};

const VideoCom = {
  videoErrorPrompt: "訊號不太好噢，請稍後重試",
  clickRetryPrompt: "點擊重試",
};
const WelcomeCard = {
  join: "加入群組",
};

//utils
const tools = {
  yesterday: "昨天",
  addedFriendPrompt: "已添加為好友",
  withdrawMessage: "撤回了一條消息",
  joinGroupPrompt: "加入了群組",
  leftGroupPrompt: "退出了群組",
  removeGroupPrompt: "被管理員移出了群組",
  bannedPrompt: "被禁言",
  unBanPrompt: "被解除禁言了",
};

//頁面
const AddressBook = {
  search: "查找",
  addFriend: "添加好友",
  myGroupChat: "我的群聊",
  friends: "好友",
  delete: "刪除",
  noFriendsPrompt: "暫無好友",
  nameAndIdSearch: "輸入名稱、ID",
  searchResults: "查找結果",
  notFoundPrompt: "查找不到用戶",
  add: "添加",
  deleteFriendPrompt: "是否確認刪除好友?",
  yes: "是",
  no: "否",
  addFriendSuccessPrompt: "添加好友成功",
  deleteFriendSuccessPrompt: "刪除好友成功",
  InputSearchPrompt: "請輸入內容",
};

const GroupChat = {
  groupChat: "群聊",
  groupSearch: "輸入群聊名稱",
  noGroupPrompt: "暫無群聊",
  chat: "聊天",
  InputSearchPrompt: "請輸入內容",
};

const GroupAdministrator = {
  administrator: "管理員",
  groupOwner: "群主",
};

const GroupAnnouncement = {
  groupAnnouncement: "群公告",
  groupOwner: "群主",
  administrator: "管理員",
};

const GroupDetail = {
  groupDetail: "群詳情",
  groupMembers: "群成員",
  groupName: "群名稱",
  groupDescription: "群描述",
  groupOwner: "群主",
  administrator: "管理員",
  administratorList: "管理員列表",
  groupAnnouncement: "群公告",
  groupFiles: "群文件",
  exitGroup: "退出群",
  exitGroupPrompt: "是否確認退出群?",
  yes: "是",
  no: "否",
  leftGroupSuccessPrompt: "退出群組成功",
};

const GroupFile = {
  groupFiles: "群文件",
  fileSearch: "輸入文件名",
  today: "今天",
  multimedia: "多媒體",
  file: "文件",
  noFilesPrompt: "暫無文件",
};

const GroupMembers = {
  groupMembers: "群成員",
  nameAndIdSearch: "輸入名稱、ID",
};

const Chat = {
  chat: "聊天",
  nameAndIdSearch: "輸入名稱、ID",
  contacts: "通訊錄",
  InputSearchPrompt: "請輸入內容",
  deleteContactPrompt: "是否確認删除聊天?",
};

const Competition = {
  all: "全部",
  ongoing: "即時",
  popular: "熱門",
  liveCompetition: "直播",
  schedule: "賽程",
  results: "賽果",
  subscribed: "已訂閱",
  goal: "進球",
  today: "今天",
  Monday: "星期一",
  Tuesday: "星期二",
  Wednesday: "星期三",
  Thursday: "星期四",
  Friday: "星期五",
  Saturday: "星期六",
  Sunday: "星期日",
};

const CompetitionDetails = {
  subscribe: "訂閱",
  subscribed: "已訂閱",
  matchStreamerPrompt: "當場比賽主播",
  live: "直播中",
  noDataPrompt: "暫無數據",
  noStreamersPrompt: "該比賽暫無主播開播",
  alreadyStreamerPrompt: "當場比賽已有主播開播 是否前往觀看?",
  yes: "是",
  no: "否",
  liveStream: "直播",
  matchStatus: "賽況",
  odds: "指數",
  unSubscribePrompt: "是否確認取消訂閱?",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
};

const OddsDetails = {
  company: "公司",
  handicap: "讓球",
  WinDrawLose: "勝平負",
  totalGoals: "總進球",
  cornerKick: "角球",
  HT: "主隊",
  Trd: "走勢",
  AT: "客隊",
  Tm: "時間",
  Sc: "比分",
  HW: "主勝",
  Dr: "平局",
  AW: "客勝",
  RR: "返還率",
  Ov: "多",
  Un: "少",
};

const Home = {
  logIn: "登錄",
  signUp: "註冊",
  popularMatches: "熱門比賽",
  more: "更多",
  noDataPrompt: "暫無數據",
  currentlyLive: "正在直播",
  popularStreamers: "熱門主播",
  thinkYouLike: "猜你喜歡",
  loadMore: "加載更多",
  noMoreDataPrompt: "暫無更多數據",
  addDeskPrompt: "添加到桌面",
  clikToolPrompt: "點擊下麵的工具列",
  andSeletcPrompt: "並選擇",
  addToScreenPrompt: "我們希望添加到您的主螢幕，以提供更好的服務",
  addToScreen: "添加到主荧幕",
};

const MoreLives = {
  noDataPrompt: "暫無數據",
};

const MoreStreamers = {
  streamer: "主播",
  streamerSearch: "輸入主播名稱、ID",
  all: "全部",
  notFollowing: "未關註",
  following: "已關註",
  live: "直播中",
  offLine: "未開播",
  followersCount: "關註",
  unfollowPrompt: "是否確認取消關註?",
  yes: "是",
  no: "否",
  logAndSign: "立即登錄/註冊",
  InternetErrorPrompt: "網絡錯誤",
  InputSearchPrompt: "請輸入內容",
  dataRequestErrorPrompt: "获取数据失败",
};

const LiveRoom = {
  switchLines: "切換線路",
  streamChat: "廣場",
  streamerPrivateChat: "主播私聊",
  chat: "聊天",
  odds: "指數",
  streamerInformation: "主播信息",
  matchStatus: "賽況",
};

const AnchorIntroduction = {
  streamerInformation: "主播信息",
  photosWall: "照片墻",
  seeMore: "查看更多",
  streamerPlan: "主播方案",
};

const AnchorPhoto = {
  photosWall: "照片墻",
  noPhotosPrompt: "暫無更多照片",
};

const LogInRegister = {
  logIn: "登錄",
  signUp: "註冊",
  getAreaAndLanguageErrorPrompt: "獲取地區語言失敗",
  quickRegisterFrequentPrompt: "註冊過於頻繁，請稍後再試",
};

const LoginFn = {
  phone: "手機號",
  email: "郵箱",
  logInPassword: "登錄密碼",
  rememberPassword: "記住密碼",
  forgotPassword: "忘記密碼",
  logIn: "登錄",
  logInAgreement: "登錄即表示同意",
  userAgreement: "用戶協議",
  and: "及",
  privacyPolicy: "隱私政策",
  passwordRulePrompt: "密碼必須由大小寫字母和數字組成且大於8位",
  passwordEmptyPrompt: "密碼不能為空",
  emailRulePrompt: "請輸入正確格式的郵箱",
  emailEmptyPrompt: "郵箱不能為空",
  phoneRulePrompt: "請輸入正確格式的手機號",
  phoneEmptyPrompt: "手機號不能為空",
  phoneNum8: "請輸入8位數字手機號碼",
  phoneNum9To10: "請輸入9至10位數字手機號碼",
  phoneNum9: "請輸入9位數字手機號碼",
  enterErrorPrompt: "輸入錯誤或網絡異常",
  loginNoAgreePrompt: "您需要同意用戶協議和隱私政策才能登錄",
  accountAndPassword: "賬號",
  account: "賬號",
  accountEmptyPrompt: "賬號不能為空",
  accountOrPasswordError: "賬號或密碼錯誤",
};

const Register = {
  phone: "手機號",
  email: "郵箱",
  code: "驗證碼",
  getCode: "獲取驗證碼",
  logInPassword: "登錄密碼",
  CS: "客服",
  signUp: "註冊",
  signUpAgreement: "註冊即表示同意",
  userAgreement: "用戶協議",
  and: "及",
  privacyPolicy: "隱私政策",
  contactCSPrompt: "註冊遇到問題了嗎？聯系客服為您解決",
  contactCS: "聯系客服",
  passwordRulePrompt: "密碼必須由大小寫字母和數字組成且大於8位",
  passwordEmptyPrompt: "密碼不能為空",
  emailRulePrompt: "請輸入正確格式的郵箱",
  emailEmptyPrompt: "郵箱不能為空",
  phoneRulePrompt: "請輸入正確格式的手機號",
  phoneEmptyPrompt: "手機號不能為空",
  codeRulePrompt: "驗證碼為6位數",
  codeEmptyPrompt: "驗證碼不能為空",
  phoneNum8: "請輸入8位數字手機號碼",
  phoneNum9To10: "請輸入9至10位數字手機號碼",
  phoneNum9: "請輸入9位數字手機號碼",
  phoneCodeSendSuccessPrompt: "驗證碼發送成功，請查看手機短信",
  emailCodeSendSuccessPrompt: "驗證碼發送成功，請查看郵箱",
  phoneCodeSendErrorPrompt: "驗證碼發送失敗,請查看手機號是否可用",
  emailCodeSendErrorPrompt: "驗證碼發送失敗,請查看郵箱是否可用",
  codeSendToMuchPrompt: "同一時間發送太多次，請稍後再試",
  phoneAlreadySignupPrompt: "該手機號已註冊，請直接登錄",
  emailAlreadySignupPrompt: "該郵箱已註冊，請直接登錄",
  signUpErrorPrompt: "註冊失敗，請檢查信息是否正確",
  enterErrorPrompt: "輸入錯誤或網絡異常",
  noAgreePrompt: "您需要同意用戶協議和隱私政策才能註冊",
  regionAndLanguagePrompt: "請選擇您真實的地區語言，否則將影響功能的使用",
  accountAndPassword: "賬號",
  quickSignup: "一键注册",
  accountEmptyPrompt: "賬號不能為空",
  accountRulePrompt: "賬號必須由8-16位的字母和數字組成",
  accountAlreadySignupPrompt: "該賬號已註冊，請直接登錄",
  tryAgain: "重發",
};

const PersonalCenter = {
  logAndSign: "立即登錄/註冊",
  logAndSignPrompt: "立即解鎖專屬功能和獨家福利",
  logOut: "退出登錄",
  myFollow: "我的關註",
  mySubscription: "我的訂閱",
  accountSecurity: "賬號安全",
  feedback: "意見反饋",
  deviceInformation: "設備",
  aboutUs: "關於我們",
  coin: "金幣",
  moreServices: "更多服務",
  following: "已關註",
  subscribed: "已訂閱",
  browser: "瀏覽器",
  user: "用戶",
  profile: "個人資料",
  nickname: "昵稱",
  save: "保存",
  change: "更改",
  nicknamePrompt: "請輸入昵稱",
  avatar: "頭像",
  changeSuccessPrompt: "更改成功",
  ballAnimation: "進球動畫",
  ballVoice: "進球音效",
  setting: "設置",
};

const IntegralSystem = {
  Coin: "金幣",
  goldCoinTask: "金幣任務",
  PointsDetails: "金幣詳情",
  myCoins: "我的金幣",
  income: "收入",
  expenditure: "支出",
  signInPrompt: "連續簽到，獎勵送不停",
  toComplete: "去完成",
  toShare: "去分享",
  shareTasks: "分享任務",
  exclusiveLink: "專屬連結",
  copyLink: "複製連結",
  highestWin: "最高贏",
  day1: "第1天",
  day2: "第2天",
  day3: "第3天",
  day4: "第4天",
  day5: "第5天",
  day6: "第6天",
  day7: "第7天",
  invitePrompt: "驚喜！現在註冊並下載我們的APP，尊享新用戶專屬福利！免費賽事直播、實時數據分析、專業主播預測，盡在指尖。別再等待，點擊鏈接加入吧！"
};

const Money = {
  activeCenter: "活動中心",
  missionCenter: "任務中心",
};

const ActivityCenter = {
  toParticipate: "去參與",
  sportActivity: "體育活動",
};

const ActivityDetails = {
  eventDetails: "活動詳情",
  dailyBetting: "每日競猜",
  matchTime: "賽事時間",
  popularForEvent: "本場活動 熱門方案",
  clickToParticipate: "點擊立即參與",
  activityRules: "活動規則",
  clickToContactParticipate: "點擊聯絡客服參與",
  activityObj: "活動對象",
  activityPlatform: "活動平台",
  activityDuration: "活動時長",
  activityDemand: "活動要求",
  activityTime: "活動時間",
};

const AboutUs = {
  aboutUs: "關於我們",
  platformIntroduction: "平臺簡介",
  privacyPolicy: "隱私政策",
  userAgreement: "用戶協議",
};

const DeviceInfo = {
  deviceInformation: "設備",
  currentTime: "當前時間",
  port: "登錄端口",
  ip: "登錄IP",
  version: "當前版本",
  ipAndPort: "登錄訊息",
};

const FeedBack = {
  feedback: "意見反饋",
  feedbackType: "反饋類型",
  productSuggestion: "產品建議",
  functionalFault: "功能故障",
  interfaceSuggestion: "界面建議",
  accountIssue: "賬號問題",
  operationExperience: "操作體驗",
  otherFeedback: "其他反饋",
  problemDescription: "問題說明",
  submit: "提交",
  addPhoto: "添加照片",
  uploadPrompt: "抱歉，僅支持上傳3張照片",
  uploadRulePrompt: "請上傳jpg、jpeg、png格式圖片",
  uploadSizePrompt: "圖片大小不能超過10M",
  chooseFeedback: "請選擇反饋類型",
  inputQuestionPrompt: "請輸入問題",
  submitSuccessPrompt: "提交成功",
};

const MySubscription = {
  myFollow: "我的關註",
  mySubscription: "我的訂閱",
  streamer: "主播",
  matches: "比賽",
};

const PrivacyAgreement = {
  privacyPolicy: "隱私政策",
};

const TermsofService = {
  termsOfService: "服務條款",
};

const UserAgreement = {
  userAgreement: "用戶協議",
};

const AccountSecurity = {
  accountSecurity: "賬號安全",
  phone: "手機號",
  email: "郵箱",
  bind: "綁定",
  notBound: "未綁定",
  change: "更改",
  logInPassword: "登錄密碼",
  accountChangePassword: "請先綁定您的手機/郵箱",
};

const BindEmail = {
  bindEmail: "綁定郵箱",
  email: "郵箱",
  code: "驗證碼",
  getCode: "獲取驗證碼",
  confirm: "確認",
  emailRulePrompt: "請輸入正確格式的郵箱",
  emailEmptyPrompt: "郵箱不能為空",
  codeRulePrompt: "驗證碼為6位數",
  codeEmptyPrompt: "驗證碼不能為空",
  changeSuccessPrompt: "更改成功",
  changeErrorPrompt: "更改失敗",
  bindSuccessPrompt: "綁定成功",
  bindErrorPrompt: "綁定失敗",
  emailCodeSendSuccessPrompt: "驗證碼發送成功，請查看郵箱",
  emailCodeSendErrorPrompt: "驗證碼發送失敗,請查看郵箱是否可用",
  codeSendToMuchPrompt: "同一時間發送太多次，請稍後再試",
  emailAlreadySignupPrompt: "該郵箱已註冊，請直接登錄",
  tryAgain: "重發",
};

const BindPhone = {
  bindPhone: "綁定手機",
  phone: "手機號",
  code: "驗證碼",
  getCode: "獲取驗證碼",
  confirm: "確認",
  cancel: "取消",
  phoneRulePrompt: "請輸入正確格式的手機號",
  phoneEmptyPrompt: "手機號不能為空",
  codeRulePrompt: "驗證碼為6位數",
  codeEmptyPrompt: "驗證碼不能為空",
  changeSuccessPrompt: "更改成功",
  changeErrorPrompt: "更改失敗",
  bindSuccessPrompt: "綁定成功",
  bindErrorPrompt: "綁定失敗",
  phoneNum8: "請輸入8位數字手機號碼",
  phoneNum9To10: "請輸入9至10位數字手機號碼",
  phoneNum9: "請輸入9位數字手機號碼",
  phoneCodeSendSuccessPrompt: "驗證碼發送成功，請查看手機短信",
  phoneCodeSendErrorPrompt: "驗證碼發送失敗,請查看手機號是否可用",
  codeSendToMuchPrompt: "同一時間發送太多次，請稍後再試",
  phoneAlreadySignupPrompt: "該手機號已註冊，請直接登錄",
  checkSignup: "請檢查號碼是否已註冊",
  tryAgain: "重發",
};

const ChangePassword = {
  resetPassword: "重置密碼",
  phone: "手機號",
  email: "郵箱",
  code: "驗證碼",
  getCode: "獲取驗證碼",
  logInPassword: "登錄密碼",
  confirm: "確認",
  cancel: "取消",
  passwordRulePrompt: "密碼必須由大小寫字母和數字組成且大於8位",
  passwordEmptyPrompt: "密碼不能為空",
  emailRulePrompt: "請輸入正確格式的郵箱",
  emailEmptyPrompt: "郵箱不能為空",
  phoneRulePrompt: "請輸入正確格式的手機號",
  phoneEmptyPrompt: "手機號不能為空",
  codeRulePrompt: "驗證碼為6位數",
  codeEmptyPrompt: "驗證碼不能為空",
  phoneNum8: "請輸入8位數字手機號碼",
  phoneNum9To10: "請輸入9至10位數字手機號碼",
  phoneNum9: "請輸入9位數字手機號碼",
  resetSuccess: "密碼重置成功",
  resetError: "密碼重置失敗，請檢查信息是否正確",
  checkSignup: "請檢查號碼是否已註冊",
  phoneCodeSendSuccessPrompt: "驗證碼發送成功，請查看手機短信",
  codeSendToMuchPrompt: "同一時間發送太多次，請稍後再試",
  phoneAlreadySignupPrompt: "該手機號已註冊，請直接登錄",
  phoneCodeSendErrorPrompt: "驗證碼發送失敗,請查看手機號是否可用",
  emailCodeSendSuccessPrompt: "驗證碼發送成功，請查看郵箱",
  emailCodeSendErrorPrompt: "驗證碼發送失敗,請查看郵箱是否可用",
  emailAlreadySignupPrompt: "該郵箱已註冊，請直接登錄",
  bindPhoneOrEmail: "請先綁定手機號或郵箱",
  tryAgain: "重發",
};

const QuickRegister = {
  downloadScreenshot: "下載截圖",
  saveAccountAndPassword: "請保管好您的賬號和密碼",
  suggestSave: "建議您截圖保存或復製存至備忘錄保存",
  account: "賬號",
  password: "密碼",
  copyAccountAndPassword: "復製賬號密碼",
  prompt: "提示",
  sureSave: "您是否已經保存好賬號密碼?",
  confirm: "確認",
  cancel: "取消",
  copySuccess: "復製成功",
  copyFailure: "復製失敗",
  manualRegister: "注册次數已達上限，請手動註冊",
};

const Recommend = {
  recommend: "推薦",
  InternetErrorPrompt: "網絡錯誤",
  hitOfTheWeek: "周命中",
  victoryOrDefeat: "勝負",
  check: "查看",
  lastTenGames: "近10場:",
  recommendedByExperts: "達人推薦",
  changeBatch: "換一批",
  follow: "關註",
  following: "已關註",
  unFollowing: "取關",
  all: "全部",
  guaranteedCompensation: "包賠",
  free: "免費",
  toll: "收費",
  HOT: "熱賣",
  HIT: "命中",
  STREAK: "連中",
  WEEK_HIT: "周命中",
  MONTH_HIT: "月命中",
  QUARTER_HIT: "季命中",
  RECENT_10_HIT: "近十命中",
};

const RecommendDetail = {
  homePage: "主頁",
  chat: "聊天",
  follow: "關註",
  following: "已關註",
  unFollowing: "取關",
  followersCount: "關註",
  planCount: "方案",
  viewCount: "觀看",
  statistics: "統計",
  plan: "方案",
};

const SchemeDetails = {
  planDetails: "方案詳情",
  release: "發布",
  chat: "聊天",
  follow: "關註",
  following: "已關註",
  unFollowing: "取關",
  details: "詳情",
  analyze: "分析",
  title: "標題",
  privateChatStreamerJoinsGroup: "私聊主播進群",
  upcoming: "未開始",
  othersPlan: "其他方案",
};
const MatchInfo = {
  homeTag: "主",
};

const LeagueSelection = {
  matchSorting: "比賽排序",
  byTime: "按時間",
  byLeague: "按聯賽",
  confirm: "確認",
  cancel: "取消",
};

const RecommendPublish = {
  enterTeam: "輸入隊伍",
  planRelease: "方案發布",
  predictionTitle: "標題（所有用戶可見，請勿透露預測結果）",
  titleEmptyPrompt: "分析的標題和內容不能為空",
  pleaseSelectAtLeastOneGameToPredictResults: "請至少選擇一種玩法進行結果預測",
}

export default {
  ...AgreementArea,
  ...AnchorAvatarAndName,
  ...AnchorInfoCard,
  ...AnchorInformation,
  ...BasketballScoreIndex,
  ...BasketballSmallBig,
  ...BindPhoneDialog,
  ...BottomNavigationBar,
  ...ChatInterface,
  ...ChatList,
  ...ChatListItem,
  ...CompetitionList,
  ...CompetitionListItem,
  ...CompetitionMode,
  ...EmojiCom,
  ...ExponentCom,
  ...FollowedStreamers,
  ...FootballCornerIndex,
  ...FootballScoreIndex,
  ...FootballScoreItem,
  ...GoalToast,
  ...LiveRecommend,
  ...LiveRecommendList,
  ...MatchCardList,
  ...MatchStatus,
  ...MoreStreams,
  ...NewMatchStatus,
  ...NoData,
  ...NoticeMessage,
  ...OddsStatisticsList,
  ...RecommendCom,
  ...RecommendCompetitions,
  ...RecommendStreamerList,
  ...RecommendStreamerListItem,
  ...RecommendStreamers,
  ...RecordChart,
  ...RegionalLanguageSelection,
  ...ScheduleCom,
  ...SchemeCard,
  ...SquareCom,
  ...StatisticalChart,
  ...SwitchLines,
  ...VideoCom,
  ...WelcomeCard,
  ...tools,
  ...AddressBook,
  ...GroupChat,
  ...GroupAdministrator,
  ...GroupAnnouncement,
  ...GroupDetail,
  ...GroupFile,
  ...GroupMembers,
  ...Chat,
  ...Competition,
  ...CompetitionDetails,
  ...OddsDetails,
  ...Home,
  ...MoreLives,
  ...MoreStreamers,
  ...LiveRoom,
  ...AnchorIntroduction,
  ...AnchorPhoto,
  ...LogInRegister,
  ...LoginFn,
  ...Register,
  ...PersonalCenter,
  ...AboutUs,
  ...DeviceInfo,
  ...FeedBack,
  ...MySubscription,
  ...PrivacyAgreement,
  ...TermsofService,
  ...UserAgreement,
  ...AccountSecurity,
  ...BindEmail,
  ...BindPhone,
  ...ChangePassword,
  ...QuickRegister,
  ...Recommend,
  ...RecommendDetail,
  ...SchemeDetails,
  ...MatchInfo,
  ...LeagueSelection,
  ...IntegralSystem,
  ...RecommendPublish,
  ...Money,
  ...ActivityCenter,
  ...ActivityDetails,

  //暫時未使用
  football: "足球",
  basketball: "籃球",
  mixed: "綜合",
  setLogInPassword: "設置登錄密碼",
  OCS: "在線客服",
  OCSPrompt: "為您服務",
  phoneInCorrectPrompt: "手機號輸入錯誤",
  codeInCorrectPrompt: "驗證碼輸入錯誤",
  emailInCorrectPrompt: "郵箱輸入錯誤",
  passwordPrompt: "請輸入8-16位英文和數字的組合",
  newPassword: "輸入新密碼",
  resetPasswordPrompt: "是否確認重置密碼?",
  startChatPrompt: "現在可以開始聊天了",
  send: "發送",
  noFollowPrompt: "暫無關註",
  feedbackPrompt: "請留下您寶貴的意見，我們將努力改進！",
  bindPhoneByOtherPrompt: "該手機號已被其他用戶綁定",
  bindEmailByOtherPrompt: "該郵箱已被其他用戶綁定",
  super_manager: "超級管理員",
  score: "比分",
  scoreIndex: "比分-指數",
  cornerKickIndex: "角球-指數",
  close: "封",
  midfield: "中",
  overtime: "加時",
  penaltyKick: "點球",
  endOfFirstQuarter: "第一節完",
  endOfSecondQuarter: "第二節完",
  endOfThirdQuarter: "第三節完",
  endOfFourthQuarter: "第四節完",
  pullUpToLoadMore: "上拉加載更多",
  pullDownToRefresh: "下拉可以刷新",
  releaseToRefreshImmediately: "松開立即刷新",
  refreshPrompt: "正在刷新中...",
  download: "下載",
  appDownload: "APP下載",
  brandName: "UFOScore",
  saveToDesktop: "添加到主屏幕",
  downloadTitle: "APP下載",
  normalDownload: "普通下載",
  scanningPrompt: "掃碼安裝，領取專屬大額優惠，更有其他豪華福利等您來領！",
  downloadFailToWeb: "若無法下載APP,點擊此處使用網頁版",
  scanQrCode: "手機掃描二維碼",
  usingMobileDevices: "使用移動端",
  installTutorial: "點擊查看安裝教程",
  whyNameDifferent: "安裝APP與下載APP名稱不一致,請放心下載安裝使用!",
  clickDownload: "點擊下載",
  installTutorialPrompt: "安裝教學指南",
  brandNativeName: "UFOScore原生APP",
  uploadingPrompt: "上傳中...",
  bHandicap: "讓分",
  different: "差",
  total: "總",
  off: "未開始",
  video: "視訊",
  animation: "動畫",
  install: "安裝",
  startDownloading: "開始下載",
  cancelGoal: "進球取消",
  appUpdatePrompt: "檢測到新版本，请更新",
};
