<template>
    <div class="noticeMessage">
        <div class="name">{{ name }}</div>
        <div class="messageText">{{ $t(renderText) }}</div>
    </div>
</template>
<script>

export default {
    props: {
        message: Object,
    },
    data() {
        return {
            type: '',
            data: {},
            name: '',
        };
    },
    mounted() {
        const { data, type } = this.message || {};
        this.type = type;
        this.data = data;
        this.getName();
    },
    computed: {
        renderText: function () {
            let text = '';
            switch (this.type) {
                case 'friend_add':
                    text = this.$t("addedFriendPrompt");
                    break;
                case 'friend_recall':
                    text = this.$t('withdrawMessage');
                    break;
                case 'group_recall':
                    text = this.data.user_id === this.data.operator_id ? this.$t('withdrawMessage') : this.$t('withdrawMessageByAdmin');
                    break;
                case 'group_increase':
                    text = this.$t('joinGroupPrompt');
                    break;
                case 'group_decrease':
                    text = this.data.user_id === this.data.operator_id ? this.$t('leftGroupPrompt') : this.$t('removeGroupPrompt');
                    break;
                case 'group_ban':
                    text =
                        this.data?.sub_type === 'ban'
                            ? this.$t('bannedPrompt') 
                            : this.$t('unBanPrompt') ;
                    break;
                default:
                    break;
            }
            return text;
        }
    },
    methods: {
        getName() {
            if (this.type === 'friend_add') {
                this.name = this.data.target_name;
            } else {
                this.name = this.data.user_name;
            }
            // switch (this.type) {
            //     case 'friend_add':
            //         this.name = this.data?.target_name;
            //         break;
            //     case 'friend_recall':
            //         this.name = this.data?.user_name;
            //         break;
            //     case 'group_recall':
            //         this.name = this.data?.user_name;
            //         break;
            //     case 'group_increase':
            //         this.name = this.data?.user_name;
            //         break;
            //     case 'group_decrease':
            //         this.name = this.data?.user_name;
            //         break;
            //     case 'group_ban':
            //         this.name = this.data?.user_name;
            //         break;
            //     default:
            //         break;
            // }
        }
    },
}
</script>
  
<style lang="scss" scoped>
@import "./Index.scss";
</style>