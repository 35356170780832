var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liveRoomVideo"},[_c('div',{ref:"video",staticClass:"video-container"},[_c('div',{staticClass:"video-containers",on:{"touchend":_vm.clickFormpParent}},[_c('video',{ref:"videoElement",staticClass:"video-js",attrs:{"id":"myVideo","playsinline":""}}),(_vm.showRefreshButton)?_c('div',{staticClass:"refresh-button"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("videoErrorPrompt")))]),_c('div',{staticClass:"button"},[_c('van-icon',{staticClass:"icon",attrs:{"name":"replay","color":"rgba(255, 255, 255, 0.6)"}}),_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.clickRefresh.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("clickRetryPrompt")))])],1),_c('div',{staticClass:"button"},[_c('van-icon',{staticClass:"icon",attrs:{"name":"bar-chart-o","color":"rgba(255, 255, 255, 0.6)"}}),_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.clickMoreLine.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("switchLines")))])],1)]):_vm._e(),(_vm.showLoading)?_c('div',{staticClass:"loading-button"},[_c('van-loading',{staticClass:"icon",attrs:{"color":"rgba(255, 255, 255, 0.6)"}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"control"},[_c('div',{staticClass:"controlButton"},[_c('div',{staticClass:"icon videoPlayStatus",style:({
              backgroundImage:
                'url(' +
                (_vm.isPlaying ? _vm.iconVideoPlaying : _vm.iconVideoPause) +
                ')',
            }),on:{"click":function($event){$event.stopPropagation();return _vm.togglePlay.apply(null, arguments)}}}),_c('div',{staticClass:"controlRight"},[_c('div',{staticClass:"volume-control"},[_c('div',{ref:"voice",staticClass:"icon voice",style:({
                  backgroundImage:
                    'url(' +
                    (_vm.isMuted ? _vm.iconVideoMute : _vm.iconVideoVolume) +
                    ')',
                }),on:{"click":function($event){$event.stopPropagation();return _vm.toggleVolumeSlider.apply(null, arguments)}}})])])])])]),(_vm.showOptions)?_c('div',{staticClass:"quality-options"},_vm._l((_vm.lineUrls),function(item,key){return _c('div',{key:key,staticClass:"option",on:{"click":function($event){$event.stopPropagation();return _vm.changeQuality(item)}}},[_c('div',[_vm._v(_vm._s(item.clarity))])])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }