<template>
  <van-swipe-cell>
    <div class="ChatListItem" @click="handleClick">
      <!-- 头像 -->
      <div class="chat-avatar">
        <!-- Badge 徽标 未读消息 -->
        <van-badge
          :content="
            item.unread_message_count > 0 ? item.unread_message_count : ''
          "
          max="99"
        >
          <!-- <div
            class="chat-avatar-img"
            :style="{
              backgroundImage: 'url(' + placeholderAvatar + ')',
            }"
          ></div> -->
          <van-image width="50" height="50" :src="placeholderAvatar" fit="cover" round />
        </van-badge>
      </div>
      <!-- 文本信息 -->
      <div class="chat-text">
        <div class="chat-text-up">
          <!-- 昵称 rgb(255, 178, 77) -->
          <div class="chat-sender">
            <span v-if="item.contact.category === 'group'" class="groupTag"
              >[{{ $t("group") }}]</span
            >
            <span class="noTag" :class="{ groupTag: item.contact.role === '官方客服' }">{{
              item.contact.name
            }}</span>
          </div>
          <!-- 消息时间 -->
          <div class="chat-time">
            <div>
              {{
                $t(formatDate(item.latest_message?.send_at)) +
                " " +
                formatTime(item.latest_message?.send_at)
              }}
            </div>
          </div>
        </div>
        <div class="chat-text-down">
          <!-- 最近的消息 -->
          <div class="chat-message">
            {{
              $t(
                formatLastestMessageText(
                  item.latest_message,
                  item.contact.category
                )
              )
            }}
          </div>
          <!-- @图标 -->
          <div class="isAt" v-if="item && item.at">
            <img width="21" height="21" src="@/assets/icons/IM/at.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <template #right>
      <div class="friend-btn" @click.stop="clickDelete">
        <div class="friend-btn-text">
          {{ $t("delete") }}
        </div>
      </div>
    </template>
  </van-swipe-cell>
</template>

<script>
import { formatDateText, formatTimeText } from "@/utils/tools";
export default {
  name: "ChatListItem",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      placeholderAvatar: require("@/assets/client-logos/nodata.webp"),
      imageBaseUrl: "", // 图片基础路径
    };
  },
  computed: {
    avatarUrl() {
      return this.item && this.item.contact ? this.item.contact.avatar : null;
    },
  },
  watch: {
    avatarUrl(newVal) {
      this.placeholderAvatar = "@/assets/client-logos/nodata.webp";
      this.replaceAvatar(newVal);
    },
  },
  created() {
    const basePathStr = localStorage.getItem("basePathObj");
    const basePathObj = JSON.parse(basePathStr) || {};
    this.imageBaseUrl = basePathObj?.fileServer || "";
    this.replaceAvatar(this.avatarUrl);
  },
  methods: {
    handleClick() {
      // 处理点击事件
      this.$emit("click");
    },
    clickDelete() {
      this.$dialog
        .confirm({
          title: this.$t("deleteContactPrompt"),
          theme: "round-button",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          confirmButtonColor: "#2A283D",
          cancelButtonColor: "#6418C3",
          className: "confirmLeft", // 确定按钮在左边
          closeOnClickOverlay: true,
          getContainer: "#app",
        })
        .then(async () => {
          this.$emit("hideContact");
        })
        .catch(() => {
          // on cancel
        });
    },
    // 格式化时间
    formatDate(time) {
      return formatDateText(time);
    },

    formatTime(time) {
      return formatTimeText(time);
    },
    formatLastestMessageText(lastestMessage, messageType) {
      let text = "";
      const { content, sender } = lastestMessage || {};
      if (messageType == "group" && sender) {
        text += sender?.name + ": ";
      }
      content?.map((item) => {
        const { type, data } = item;
        switch (type) {
          case "text":
            text += data?.text;
            break;
          case "image":
            text += `[${this.$t("picture")}]`;
            break;
          case "face":
            text += `[${this.$t("emoji")}]`;
            break;
          case "document":
            text += `[${this.$t("file")}]`;
            break;
          case "link":
            text += `[${this.$t("link")}]`;
            break;
          case "friend_recall":
            text += data.user_name + `[${this.$t("withdrawMessage")}]`;
            break;
          case "group_recall":
            text += data.user_name + `[${this.$t("withdrawMessage")}]`;
            break;
          case "group_increase":
            text += data.user_name + `[${this.$t("joinGroupPrompt")}]`;
            break;
          case "group_decrease":
            text += data.user_name + `[${this.$t("leftGroupPrompt")}]`;
            break;
          case "group_ban":
            text +=
              data.user_name + data.sub_type === "ban"
                ? this.$t("bannedPrompt")
                : this.$t("unBanPrompt");
            break;
          default:
            break;
        }
      });
      return text;
    },

    /**
     * 动态替换头像
     */
    replaceAvatar(url) {
      // 预加载完整图像
      const img = new Image();
      img.src =this.imageBaseUrl +  url;
      img.onload = () => {
        
        // 图像加载完成后，更新背景图像
        this.placeholderAvatar =this.imageBaseUrl +  url;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .friend-btn {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d5282d;
    .friend-btn-icon {
      width: 20px;
      height: 20px;
      background-image: url("@/assets/icons/IM/icon-chatbubble-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 10px;
    }

    .friend-btn-text {
      height: 40px;
      line-height: 40px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
  }
}

.ChatListItem {
  height: 80px;
  background-color: var(--theme-box-1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  // 第一个元素

  .chat-avatar {
    flex: 0 0 50px;
    height: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;

    .chat-avatar-img {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50px;
    }
  }
  .chat-text {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding: 18px 0 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-line);

    .chat-text-up {
      height: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chat-sender {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: var(--theme-main-word);
        // 超出省略
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
        .noTag {
          color: var(--theme-main-word);
        }
        .groupTag {
          color: rgb(255, 178, 77);
          margin-right: 5px;
        }
      }

      .chat-time {
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: right;
        color: var(--theme-main-word);
      }
    }

    .chat-text-down {
      height: 17px;
      width: 262px;

      .chat-message {
        color: var(--theme-main-word);
        opacity: 0.5;
        line-height: 17px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        // 文本溢出省略
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
