import { getLives } from "@/services/api/Home/index";
import { getLiveLine } from "@/services/api/Live";

const state = {
  livesData: [], // 直播间列表数据
  currentPage: 1, // 当前页码
  currentLiveLineKey: 0,  // 当前直播线路
  currentLiveLines: [], // 当前直播间的线路列表
  currentLiveId: null, // 当前直播间的id
};

const mutations = {
  /**
   * 设置直播间列表数据
   * @param {Array} payload.data - 直播间列表数据
   */
  SET_CURRENT_LIVE_ID(state, { id }) {
    console.log(id);
    state.currentLiveId = id;
  },
  
  /**
   * 设置直播间列表数据
   * @param {Array} payload.data - 直播间列表数据
   */
  SET_LIVES_DATA(state, { data }) {
    state.livesData = data;
  },

  /**
   * 设置直播间列表当前页码
   * @param {number} payload.page - 当前显示的页码。
   */
  SET_CURRENT_PAGE(state, { page }) {
    state.currentPage = page;
  },

  /**
   * 设置当前直播间的当前线路
   * @param {number} payload.key 
   */
  SET_CURRENT_LIVE_LINE_KEY(state, { key }) {
    state.currentLiveLineKey = key;
  },

  /**
   * 设置当前直播间的线路列表
   * @param {Array} payload.liveLines 
   */
  SET_CURRENT_LIVE_LINES(state, { liveLines }) {
    state.currentLiveLines = liveLines;
  }
};

const actions = {
  /**
   * 异步获取指直播间列表数据，并提交到 state。
   * @param {Object} payload.params - 传递给 API 的参数对象。
   */
  async fetchLivesData({ commit, state }, { params }) {
    try {
      // 调用请求API获取服务端数据
      const response = await getLives({
        ...params,
        page: state.currentPage,
      });
      if (response.statusCode === 200 && response.data) {
        // 提交数据和页码到 state
        commit("SET_LIVES_DATA", { data: response.data.items });
        commit("SET_CURRENT_PAGE", { page: response.data.page });
        return true;
      }
    } catch (error) {
      console.error(`请求直播间列表数据出错:`, error);
    }
  },

  /**
   * 请求当前直播间的直播线路数据
   * @param {*} live_id 
   */
  async fetchVideoLine({ commit }, live_id){
    try{
      const res = await getLiveLine(live_id);
      const {data, statusCode} = res;
      if(statusCode === 200){
        commit("SET_CURRENT_LIVE_LINES", { liveLines: data.items });
      }
    }catch(e){
      console.error("fetchVideoLine:error", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
