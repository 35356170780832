/**
 * @file commonIcons
 * @description template模板中常用的图标
 */

export const subscribedIcon = {
  starRedIcon: require("@/assets/icons/Competition/icon-star-red.svg"), // 已订阅图标
  starGreyIcon: require("@/assets/icons/Competition/icon-star-grey.svg"), // 未订阅图标
};

export const followedIcon = {
  loveRedIcon: require("@/assets/icons/Competition/icon-love-red.svg"), // 已关注图标
  loveWhiteIcon: require("@/assets/icons/Competition/icon-love-white.svg"), // 未关注图标
};

export const categoryIcon = {
  // key 即为sport_type_id
  1: require("@/assets/icons/CompetitonType/icon-football.svg"), // 足球
  2: require("@/assets/icons/CompetitonType/icon-basketball.svg"), // 篮球
  3: require("@/assets/icons/CompetitonType/icon-mixed.svg"), // 综合
  //   Esports: require("@/assets/icons/CompetitonType/icon-sword.svg"), // 电竞
  //   Tennis: require("@/assets/icons/CompetitonType/icon-tennis.svg"), // 网球
  //   Baseball: require("@/assets/icons/CompetitonType/icon-baseball.svg"), // 棒球
  //   Volleyball: require("@/assets/icons/CompetitonType/icon-volleyball.svg"), // 排球
  //   Boxing: require("@/assets/icons/CompetitonType/icon-boxing.svg"), // 拳击
  //   Cricket: require("@/assets/icons/CompetitonType/icon-cricket.svg"), // 板球
  //   Handball: require("@/assets/icons/CompetitonType/icon-handball.svg"), // 手球
  //   Hockey: require("@/assets/icons/CompetitonType/icon-hockey.svg"), // 曲棍球
};
