<template>
  <div
    class="ChatList"
    :class="{
      liveRoom: mode === 'liveRoom',
    }"
  >
    <div
      class="chat-content"
      ref="chatListWrapper"
      :class="{
        liveRoom: mode === 'liveRoom',
        noDataContent: !recentMessages?.length && role === 'user',
      }"
      @scroll="handleScroll"
      @touchmove="handleScroll"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
    >
      <el-skeleton
        class="chatListSkeleton"
        :loading="skeletonShow && role === 'user'"
        animated
        :count="6"
      >
        <template slot="template">
          <ChatListItemSkeleton />
        </template>
        <template>
          <div
            class="chat-content-list"
            v-if="recentMessages?.length && role === 'user'"
          >
            <ChatListItem
              v-for="(item, index) in recentMessages"
              :key="index"
              :item="item"
              @click="MenterChat(item)"
              @hideContact="hideContact(item)"
            ></ChatListItem>
          </div>

          <div
            class="noData"
            v-else-if="!recentMessages?.length && role === 'user'"
          >
            <NoData v-if="!dataLoading">{{ $t("noNewPrompt") }}</NoData>
          </div>

          <!-- 用于适配直播间聊天tab未登录情况  -->
          <div class="noLogin" v-else>
            <div class="icon-noLogin"></div>
            <div class="text-noLogin">
              {{ $t("logInPrompt") }}
            </div>
            <div class="btn-noLogin" @click="toLoginRouter">
              {{ $t("logIn") }}
            </div>
          </div>
        </template>
      </el-skeleton>
      <!--  -->
      <!-- <BackToTop
        :topDistance="mode === 'liveRoom' ? 280 : 60"
      ></BackToTop> -->
    </div>
  </div>
</template>

<script>
import NoData from "@/components/NoData/Index.vue";
import ChatListItem from "@/components/ChatListItem/Index.vue";
import ChatListItemSkeleton from "@/components/ChatListItem/ChatListItemSkeleton.vue";
import { mapState, mapActions } from "vuex";
import { clearUnread, hideRecentContact } from "@/services/api/IM";
import { debounce } from "lodash";
import { checkClientTokenRole } from "@/utils/authCookie";
export default {
  props: {
    /**
     * chats 表示在聊天界面
     * liveRoom 表示在直播间界面
     */
    mode: {
      type: String,
    },
    TMoptionActivity: {
      type: String,
    },

    // 由推荐tab的聊天跳转而来的主播username
    // 如果匹配到该主播 则打开聊天页面
    targetFromRecommend: {
      type: Object,
    },
  },
  components: {
    NoData,
    ChatListItem,
    ChatListItemSkeleton,
    // BackToTop,
  },
  data() {
    return {
      loading: true,
      input2: "",
      role: "", // token身份
      // showLoading: false, // 是否展示加载中
      dataLoading: true, //toast是否在加载状态
      skeletonShow: false, // 骨架屏是否显示
      isTop: false,
      logoUrl: require("@/assets/client-logos/com-logo@3x.webp"),
      startY: 0,
      startScrollTop: 0,
      y: 0,
    };
  },
  watch: {
    targetFromRecommend(newVal) {
      if (newVal) {
        this.findInRecentMessages(newVal);
      }
    },
  },
  computed: {
    ...mapState({
      recentMessages: (state) => state.chatIM.recentMessages,
    }),
  },
  async mounted() {
    this.role = checkClientTokenRole();
    if (this.role === "user") {
      await this.getRecent("", this.mode === "liveRoom");
      if (this.targetFromRecommend) {
        this.findInRecentMessages(this.targetFromRecommend);
      }
    }
  },
  methods: {
    //公共-------------------------------------------
    ...mapActions("chatIM", ["getRecentMessages", "changeTotalUnreadCount"]),

    // 滚动事件
    handleScroll: debounce(function () {
      if (!this.$refs.chatListWrapper) {
        return;
      }
      // 判断手势是上拉还是下拉
      if (this.$refs.chatListWrapper.scrollTop === 0) {
        this.isTop = true;
        return;
      }
    }, 200),

    handleTouchStart(event) {
      if (!this.$refs.chatListWrapper) {
        return;
      }
      this.startY = event.touches[0].clientY;
      this.startScrollTop = this.$refs.chatListWrapper.scrollTop;
      if (this.startScrollTop === 0) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    },
    async handleTouchEnd(event) {
      const endY = event.changedTouches[0].clientY;
      if (this.isTop && endY > this.startY) {
        this.isTop = false;
        this.getRecent();
      }
    },
    // 跳转登录页
    toLoginRouter() {
      sessionStorage.setItem("preLoginRoute", this.$route.fullPath);
      if (this.mode === "liveRoom") {
        // 如果本组件在直播间页面 保存直播间的tab选中状态
        sessionStorage.setItem("liveRoomTabActivity", "chat");
      }
      this.$router.push("/LogInRegister");
    },

    // 获取个人最近消息
    async getRecent(param, isFirstRefresh = false) {
      // 展示加载toast
      if (!isFirstRefresh) {
        this.dataLoading = true;
        this.$emit("changeLoading", true);
      }
      const name = param?.trim();
      this.skeletonShow = true;
      await this.getRecentMessages({
        name,
        category: "all",
      });
      this.skeletonShow = false;
      if (!isFirstRefresh) {
        setTimeout(() => {
          this.dataLoading = false;
          this.$emit("changeLoading", false);
        }, 300);
      } else {
        this.dataLoading = false;
      }
    },

    onInputChange() {
      this.getRecent(this.input2);
    },

    // 个人聊天
    MenterChat(item) {
      const { category, target_id } = item.contact;
      clearUnread({
        category,
        target_id,
      })
        .then((res) => {
          const { statusCode, data } = res || {};
          if (statusCode === 200 || statusCode === 204) {
            this.$emit("enterChat", item.contact);
            const { total_unread_message_count } = data || {};
            this.changeTotalUnreadCount(total_unread_message_count);
          }
        })
        .catch((e) => console.log(e));
    },
    // 隐藏联系人
    async hideContact(item) {
      const { category, target_id } = item.contact;
      try {
        const res = await hideRecentContact({
          category,
          target_id,
        });
        const { statusCode } = res;
        if (statusCode === 200) {
          await this.getRecent();
        } else {
          // 接口发生错误
          // this.$vantToast({
          //   type: "fail",
          //   message: this.$t("InternetErrorPrompt"),
          // });
          console.log("fail:网络错误！");
        }
      } catch (e) {
        // 接口发生错误
        // this.$vantToast({
        //   type: "fail",
        //   message: this.$t("InternetErrorPrompt"),
        // });
        console.log("fail:网络错误！");
      }
    },
    // 匹配由推荐tab的聊天跳转而来的主播username是否在聊天消息列表中
    findInRecentMessages(target) {
      const findObj = this.recentMessages.find(
        (obj) => obj.contact.target_id === target.target_id
      );
      if (findObj) {
        this.$emit("enterChat", findObj.contact);
      } else {
        this.$emit("enterChat", target);
      }
      console.log("findInRecentMessages", this.recentMessages);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./Index.scss";
</style>
