const state = {
  TASK_LiveRoom_liveMode: "competition", // 直播间：视频直播状态\动画直播状态\赛事信息
  TASK_Competition_mode: "", // 赛事：比分模式\比分-指数模式\角球-指数模式
};

const mutations = {
  SET_TASKBEACON_VALUE(state, { key, value }) {
    state[key] = value;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
